import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FilesService } from '../../../../components/advertisement/files.service';
import { HttpService } from '../../../../core/providers/http/http.service';
import { IGeneralVenueInfo } from '../../../../core/interfaces/general.interface';
import { ILanguage } from '../../../../common/interfaces/language.interface';
import { LanguageService } from '../../../../core/providers/language/language.service';
import { LayoutService } from '../../../../core/providers/layout/layout.service';
import { Subject, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-closed-page',
  templateUrl: './closed-page.component.html',
  styleUrls: ['./closed-page.component.scss'],
})
export class ClosedPageComponent implements OnInit, OnDestroy {
  isImageLoaded = false;
  advUrl = '';
  readonly venueInfo: IGeneralVenueInfo = this.activatedRoute.snapshot.data.venue;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private filesService: FilesService,
    private titleService: Title,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.venueInfo) {
      this.setLayoutCustomSettings();
      this.setAvailableLanguages();
      this.setDefaultLanguage();
      this.setTabName();
      this.setAdvertisement();
      this.layoutService.activeAdvertisementImageURL$.pipe(takeUntil(this.onDestroy$)).subscribe((url: string) => {
        this.advUrl = url;
        this.changeDetectionRef.detectChanges();
      });
    }

    this.layoutService.loadedLayoutData$.next(true);
  }

  goTotAdvertisementUrl(): void {
    if (this.getUrl()) {
      window.location.href = HttpService.addHttp(this.getUrl());
    }
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }

  onImageError(): void {
    this.advUrl = this.layoutService.getDefaultAdvertisementUrl();
  }

  getUrl(): string {
    return this.layoutService.getAdvertisementUrl();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setDefaultLanguage(): void {
    const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(this.venueInfo.languages);

    if (defaultLanguage) {
      this.languageService.setAppLanguage(defaultLanguage.code);
    }
  }

  private setAvailableLanguages(): void {
    this.languageService.setAvailableLanguages(this.venueInfo.languages.map(language => language.code));
  }

  private setLayoutCustomSettings(): void {
    this.setAppColour();
    this.setVenueLogo();
    this.layoutService.loadedLayoutData$.next(true);
  }

  private setAppColour(): void {
    // eslint-disable-next-line
    if (this.venueInfo.branding) {
      this.layoutService.setLayoutDefaultColor(this.venueInfo.branding.colorTint);
    }
  }

  private setVenueLogo(): void {
    // eslint-disable-next-line
    if (this.venueInfo.branding.logoFileUuid) {
      const url: string = this.filesService.getUrl(this.venueInfo.branding.logoFileUuid);

      this.layoutService.setLayoutLogoUrl(url);
    } else {
      this.layoutService.setLayoutDefaultLogoUrl();
    }
  }

  private setTabName(): void {
    const brandName: string = this.venueInfo.venueName;
    const tabTitle: string = brandName ? `${brandName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }

  private setAdvertisement(): void {
    this.layoutService.setAdvertisementURL(this.venueInfo.advertisement.advertisementFileUuid, this.venueInfo.advertisement.url);
  }
}
