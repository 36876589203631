import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CAMPAIGN } from '../../campaign/campaign.constants';
import { CONSTANTS } from '../../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../../common/constants/routing/routing.constants';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { HttpService } from '../../../core/providers/http/http.service';
import { ILanguage } from '../../../common/interfaces/language.interface';
import { IQrLandingOption, IQrLandingSettingsItem, QrLandingOptionsInternalIds } from '../qr-landing.interface';
import { LanguageService } from '../../../core/providers/language/language.service';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { MatIconRegistry } from '@angular/material/icon';
import { QR_LANDING_CONSTANTS } from '../qr-landing.constants';
import { QrLandingService } from '../qr-landing.service';
import { Subject, of, throwError } from 'rxjs';
import { SurveyService } from '../../survey/survey.service';
import { catchError, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-qr-landing',
  templateUrl: './qr-landing.component.html',
  styleUrls: ['./qr-landing.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class QrLandingComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('spinnerRef') spinnerRef;
  mainItems: IQrLandingSettingsItem[] = [];
  optionalItems: IQrLandingSettingsItem[] = [];
  token: string;
  isLoading = false;
  isWheelEnabled: boolean;
  colour: string;
  isFeedbackBlocked: boolean;
  isVenueClosed = false;

  private isCheckedIn: boolean;
  private onDestroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private languageService: LanguageService,
    private titleService: Title,
    private layoutService: LayoutService,
    private qrLandingService: QrLandingService,
    private surveyService: SurveyService,
    private cdf: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  ngAfterViewChecked(): void {
    if (this.spinnerRef) {
      this.spinnerRef._elementRef.nativeElement.children[0].children[0].style.stroke = this.colour;
    }
  }

  ngOnInit(): void {
    this.loadIcons();
    this.setLayoutCustomSettings();
    this.setAvailableLanguages();
    this.setDefaultLanguage();
    this.setTabName();
    this.isCheckedIn = this.qrLandingService.isCheckedInAlready(this.qrLandingService.getQrLandingSettings().venueUuid);
    this.setFeedbackBg();
    this.setOptionsData();
    this.activatedRoute.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.token = params.id;
      localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.PORTAL_TOKEN, this.token);
    });
    this.isWheelEnabled = this.qrLandingService.getQrLandingSettings().isWheelEnabled;
    this.isFeedbackBlocked =
      !this.surveyService.checkLocalStorage(this.qrLandingService.getQrLandingSettings().venueUuid) ||
      !this.qrLandingService.getQrLandingSettings().isAllowedToFillSurvey;
    this.isVenueClosed = !this.qrLandingService.getQrLandingSettings().isOpen;
  }

  goToPage(item: IQrLandingSettingsItem) {
    if (item.internalId !== QrLandingOptionsInternalIds.checkinInternal) {
      document.location.href = HttpService.addHttp(item.value);
    } else {
      this.router.navigate([`${QR_LANDING_CONSTANTS.ROUTES.MAIN}/${this.token}/${QR_LANDING_CONSTANTS.ROUTES.FORMS.CHECK_IN}`]);
    }
  }

  goToSurvey() {
    if (this.isFeedbackBlocked) {
      return;
    }

    this.isFeedbackBlocked = true;
    this.isLoading = true;

    if (this.isVenueClosed) {
      this.zone.run(() =>
        this.router.navigate([CONSTANTS_ROUTING.ROUTES.CLOSED.replace(':id', this.qrLandingService.getQrLandingSettings().venueUuid)])
      );
    } else {
      this.qrLandingService
        .fetchSurveyToken$(this.token)
        .pipe(first(), takeUntil(this.onDestroy$))
        .subscribe((value: { token: string }) => {
          if (value.token) {
            const path = CONSTANTS_ROUTING.ROUTES.SURVEY.replace(':id', value.token);

            this.zone.run(() => this.router.navigate([path]));
          } else {
            this.gotToCampaign();
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this.setAppBg('#F7F7F7');
  }

  ngOnDestroy(): void {
    this.setAppBg('unset');
    this.isLoading = false;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private gotToCampaign(): void {
    this.zone.run(() => this.router.navigate([`${CAMPAIGN.ROUTES.MAIN}/${this.qrLandingService.getQrLandingSettings().venueUuid}`]));
  }

  private setAppBg(color: string) {
    const element = document.getElementsByClassName('AppComponentContentWrapper')[0] as HTMLElement;

    if (element) {
      element.style.background = color;
    }
  }

  private loadIcons() {
    this.matIconRegistry.addSvgIcon('foodback', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/foodback.svg'));
    this.matIconRegistry.addSvgIcon('fork', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/restaurant.svg'));
    this.matIconRegistry.addSvgIcon('location_on', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/location_on.svg'));
    this.matIconRegistry.addSvgIcon('check', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/check.svg'));
    this.matIconRegistry.addSvgIcon('virus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/virus.svg'));
    this.matIconRegistry.addSvgIcon('taxi', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/taxi.svg'));
    this.matIconRegistry.addSvgIcon(
      'keyboard_arrow_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/svg/keyboard_arrow_right.svg')
    );
  }

  private setOptionsData() {
    const maxMainItems = 2;

    this.mainItems = this.getMenuItems(0, maxMainItems);
    this.optionalItems = this.getMenuItems(maxMainItems + 1, this.qrLandingService.getQrLandingSettings().rows.length);
  }

  private getMenuItems(startIndex: number, stopIndex: number) {
    const items = [];
    const rows = this.qrLandingService.getQrLandingSettings().rows;
    const options = this.qrLandingService.getQrLandingSettings().options;

    for (let i = startIndex; i < stopIndex; i++) {
      const option: IQrLandingOption = options.find(value => value.uuid === rows[i].optionUuid);

      // eslint-disable-next-line
      if (option) {
        const translationKeys = this.getTranslationsKeys(option.internalId);

        if (rows[i].isActive) {
          items.push({
            isActive: rows[i].isActive,
            value: HttpService.addHttp(rows[i].value),
            icon: this.getIcon(option.internalId),
            name: translationKeys.name,
            desc: translationKeys.desc,
            internalId: option.internalId,
          });
        }
      }
    }

    return items;
  }

  private getTranslationsKeys(internalId: QrLandingOptionsInternalIds): {
    name: string;
    desc: string;
  } {
    switch (internalId) {
      case QrLandingOptionsInternalIds.checkinInternal:
        return this.getCheckInItemNameAndDesc();
      case QrLandingOptionsInternalIds.checkinExternal:
        return this.getCheckInItemNameAndDesc();
      case QrLandingOptionsInternalIds.order:
        return {
          name: 'QR_LANDING.ORDER',
          desc: 'QR_LANDING.ORDER_DESC',
        };
      case QrLandingOptionsInternalIds.menu:
        return {
          name: 'QR_LANDING.MENU',
          desc: 'QR_LANDING.MENU_DESC',
        };
      case QrLandingOptionsInternalIds.feedback:
        return {
          name: 'QR_LANDING.FEEDBACK',
          desc: null,
        };
      case QrLandingOptionsInternalIds.covid:
        return {
          name: 'QR_LANDING.COVID_INFO',
          desc: null,
        };
      case QrLandingOptionsInternalIds.taxi:
        return {
          name: 'QR_LANDING.ORDER_TAXI',
          desc: null,
        };
      default:
        return {
          name: '',
          desc: '',
        };
    }
  }

  private getCheckInItemNameAndDesc() {
    if (this.isCheckedIn) {
      return {
        name: 'QR_LANDING.CHECKED_IN',
        desc: 'QR_LANDING.ADD_ANOTHER',
      };
    }

    return {
      name: 'QR_LANDING.CHECK_IN',
      desc: 'QR_LANDING.CHECK_IN_DESC',
    };
  }

  private getIcon(internalId: QrLandingOptionsInternalIds): string {
    switch (internalId) {
      case QrLandingOptionsInternalIds.checkinInternal:
        return this.isCheckedIn ? 'check' : 'location_on';
      case QrLandingOptionsInternalIds.checkinExternal:
        return this.isCheckedIn ? 'check' : 'location_on';
      case QrLandingOptionsInternalIds.order:
        return 'fork';
      case QrLandingOptionsInternalIds.menu:
        return 'fork';
      case QrLandingOptionsInternalIds.feedback:
        return this.isFeedbackBlocked ? 'check' : 'foodback';
      case QrLandingOptionsInternalIds.covid:
        return 'virus';
      case QrLandingOptionsInternalIds.taxi:
        return 'taxi';
      default:
        return null;
    }
  }

  private setFeedbackBg() {
    this.colour = this.layoutService.getDefaultColor();
  }

  private setAvailableLanguages() {
    const branding = this.qrLandingService.getQrLandingSettings();

    if (branding && branding.languages) {
      this.languageService.setAvailableLanguages(this.qrLandingService.getQrLandingSettings().languages.map(language => language.code));
    }
  }

  private setDefaultLanguage() {
    const branding = this.qrLandingService.getQrLandingSettings();

    if (branding && branding.languages) {
      const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(branding.languages);

      if (defaultLanguage) {
        this.languageService.setAppLanguage(defaultLanguage.code);
      }
    }
  }

  private setLayoutCustomSettings() {
    this.setAppColour();
    this.setVenueLogo();
    this.layoutService.loadedLayoutData$.next(true);
  }

  private setAppColour() {
    // eslint-disable-next-line
    if (this.qrLandingService.getQrLandingSettings().branding) {
      this.layoutService.setLayoutDefaultColor(this.qrLandingService.getQrLandingSettings().branding.colorTint);
    }
  }

  private setVenueLogo() {
    this.layoutService.setVenueLogo(this.qrLandingService.getQrLandingSettings().branding.logoFileUuid);
  }

  private setTabName() {
    const brandName: string = this.qrLandingService.getQrLandingSettings().venueName;
    const tabTitle = brandName ? `${brandName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }
}
