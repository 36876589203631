import { AdBackAlreadyComponent, AdLooserPageComponent, AdThankYouPageComponent } from './ad-pages/components';
import { CAMPAIGN_ROUTES } from './campaign/campaign.routes';
import { CONSTANTS_ROUTING } from '../common/constants/routing/routing.constants';
import { CONTACT_GUEST_ROUTES } from './contact-guest/contact-guest.routes';
import { ClosedPageComponent, EmptyPageComponent } from '../shared/components/pages';
import { ClosedPageResolver } from '../shared/components/pages/closed-page/closed-page.resolver';
import { DEMOGRAPHICS_ROUTES } from './demographics/demograhics.routes';
import { ErrorPageComponent, NotFoundPageComponent } from './error-pages/components';
import { NgModule } from '@angular/core';
import { ONE_MORE_QUESTION_ROUTES } from './one-more-question/one-more-question.routes';
import { QR_LANDING_ROUTES } from './qr-landing/qr-landing.routes';
import { ROOM_ROUTES } from './room/room.routes';
import { RouterModule, Routes } from '@angular/router';
import { SurveyComponent } from './survey/_survey/survey.component';
import { SurveyResolver } from './survey/survey.resolver';
import { VOUCHER_ROUTES } from './voucher/voucher.routes';
import { VenuesComponent } from './venues/_venues/venues.component';
import { VenuesResolver } from './venues/venues.resolver';
import { WHEEL_OF_FORTUNE_ROUTES } from './wheel-of-fortune/wheel-of-fortune.routes';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: CONSTANTS_ROUTING.ROUTES.SURVEY,
        component: SurveyComponent,
        loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule),
        resolve: {
          survey: SurveyResolver,
        },
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.VENUES,
        component: VenuesComponent,
        loadChildren: () => import('./venues/venues.module').then(m => m.VenuesModule),
        resolve: {
          venue: VenuesResolver,
        },
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.CLOSED,
        component: ClosedPageComponent,
        resolve: {
          venue: ClosedPageResolver,
        },
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.NOT_FOUND,
        component: NotFoundPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.GHOST,
        component: NotFoundPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.ERROR,
        component: ErrorPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU,
        component: AdThankYouPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('./ad-pages/ad-pages.module').then(m => m.AdPagesModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.ADD.BACK,
        component: AdBackAlreadyComponent,
        pathMatch: 'full',
        loadChildren: () => import('./ad-pages/ad-pages.module').then(m => m.AdPagesModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.ADD.LOOSER,
        component: AdLooserPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('./ad-pages/ad-pages.module').then(m => m.AdPagesModule),
      },
      ...WHEEL_OF_FORTUNE_ROUTES,
      ...VOUCHER_ROUTES,
      ...ROOM_ROUTES,
      ...CONTACT_GUEST_ROUTES,
      ...DEMOGRAPHICS_ROUTES,
      ...QR_LANDING_ROUTES,
      ...ONE_MORE_QUESTION_ROUTES,
      ...CAMPAIGN_ROUTES,
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
