import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOneMoreQuestionFromBackend } from '../interfaces/one-more-question.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-one-more-question',
  templateUrl: './one-more-question.component.html',
  styleUrls: ['./one-more-question.component.scss'],
})
export class OneMoreQuestionComponent implements OnInit, OnDestroy {
  headerText = 'MORE_QUESTION.HEADER';
  question: IOneMoreQuestionFromBackend = this.activatedRoute.snapshot.data.question;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
