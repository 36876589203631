<div [style.border-color]="getLayoutColor()" class="Header">
  <div class="HeaderLogo">
    <div class="HeaderLogo-container">
      <svg viewBox="0 0 160 50">
        <rect width="160" height="50" style="fill: rgb(255, 255, 255)" />
      </svg>
      <img
        [src]="logoUrl"
        (error)="onImageError()"
        [ngClass]="{ 'fade-in': isImageLoaded }"
        (load)="onImageLoad()"
        *ngIf="logoUrl !== ''" />
    </div>
  </div>
  <app-language-selector class="Header-languageSelector"></app-language-selector>
</div>
