import { Directive, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-dialog-title',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-dialog-title' },
})
export class DialogTitleDirective {}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-dialog-content',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-dialog-content' },
})
export class DialogContentDirective {
  constructor(public el: ElementRef) {}
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-dialog-actions',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-dialog-actions' },
})
export class DialogActionsDirective {}
