import { CONSTANTS_COLORS } from './colors/layout-colors';
import { CONSTANTS_ERRORS } from './errors/errors.constants';
import { CONSTANTS_LOCALSTORAGE_KEYS } from './localstorage-keys/localstorage-keys.constants';
import { CONSTANTS_PHONE_CODES_BY_LANG } from './languages/languages.contansts';
import { CONSTANTS_ROUTING } from './routing/routing.constants';
import { CONSTANTS_TIME } from './time/time.constants';
import { CONSTANT_IMAGES } from './images/images';

export const CONSTANTS = {
  COLORS: CONSTANTS_COLORS,
  ROUTING: CONSTANTS_ROUTING,
  ERRORS: CONSTANTS_ERRORS,
  TIME: CONSTANTS_TIME,
  LOCALSTORAGE_KEYS: CONSTANTS_LOCALSTORAGE_KEYS,
  PHONE_CODE: CONSTANTS_PHONE_CODES_BY_LANG,
  IMAGES: CONSTANT_IMAGES,
};
