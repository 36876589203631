import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CONSTANTS } from '../../../common/constants/constants';
import { DialogService } from '../../../shared/components/dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ICountryCodeSelectInterface } from './country-code-select.interface';
import { LanguageService } from '../../../core/providers/language/language.service';
import { Languages } from '../../../common/enums/languages.enum';
import { Observable, Subject } from 'rxjs';
import { QR_LANDING_CONSTANTS } from '../qr-landing.constants';
import { QrLandingService } from '../qr-landing.service';
import { catchError, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-qr-landing-check-in',
  templateUrl: './qr-landing-check-in.component.html',
  styleUrls: ['./qr-landing-check-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrLandingCheckInComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @ViewChild('countryCodeListRef')
  countryCodeListRef: ElementRef;
  rememberMe: FormControl = new FormControl(false);
  isCountryCodesListVisible = false;
  checkInForm: FormGroup;
  countryPhoneCodes: ICountryCodeSelectInterface[] = [];
  selectedCountryCode: ICountryCodeSelectInterface = {
    dialCode: '+47',
    icon: 'no',
  };
  children: string[] = [];

  private onDestroy$: Subject<void> = new Subject<void>();
  private token: string;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private qrLandingService: QrLandingService,
    private dialogService: DialogService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.prepareForm();
    this.getCountriesPhoneCodes$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((values: ICountryCodeSelectInterface[]) => {
        this.countryPhoneCodes = values;
      });
    this.activatedRoute.params.pipe(takeUntil(this.onDestroy$)).subscribe((params: Params) => {
      this.token = params.id;
    });
    this.languageService
      .getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((lang: Languages) => this.setPhoneByLanguage(lang));
    this.qrLandingService.setBasicSettings();
    this.checkAndSetSavedData();
    this.checkInForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.cdRef.detectChanges();
    });
    this.rememberMe.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  goToPortal(): void {
    this.ngZone.run(() => this.router.navigate([`${QR_LANDING_CONSTANTS.ROUTES.MAIN}/${this.token}`])).then();
  }

  submit(value: any): void {
    this.dialogService
      .checkInDialog({
        payload: {
          fullName: value.name,
          numberOfChildren: value.children,
          phone: {
            prefix: this.selectedCountryCode.dialCode,
            number: value.mobile,
          },
          tableNumber: value.table && value.table !== '' ? value.table : null,
          venueUuid: this.qrLandingService.getQrLandingSettings().venueUuid,
        },
        rememberMe: this.rememberMe.value,
        selectedCountryCode: this.selectedCountryCode,
        confirmBtnText: `SYSTEM.YES_CHECK_IN`,
        cancelBtnText: 'BUTTON.NO_GO_BACK',
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val: any) => {
        if (val) {
          this.goToPortal();
        }
      });
  }

  selectCountryCode(value: ICountryCodeSelectInterface): void {
    this.selectedCountryCode = value;
  }

  closeSelect(): void {
    if (this.isCountryCodesListVisible) {
      this.isCountryCodesListVisible = false;
    }

    this.cdRef.detectChanges();
  }

  trackByCountryCode(index: number, countryCodeSelected: ICountryCodeSelectInterface): string {
    return countryCodeSelected.dialCode;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.setAppBg('unset');
  }

  ngAfterViewChecked(): void {
    if (this.countryCodeListRef) {
      this.countryCodeListRef.nativeElement.scrollTop = (
        document.getElementsByClassName('QrLandingCheckIn-countryCodeSelected')[0] as HTMLElement
      ).offsetTop;
    }
  }

  ngAfterViewInit(): void {
    this.setAppBg('#F7F7F7');
  }

  onListClick(): void {
    this.isCountryCodesListVisible = !this.isCountryCodesListVisible;
    this.cdRef.detectChanges();
  }

  private setAppBg(color: string): void {
    (document.getElementsByClassName('AppComponentContentWrapper')[0] as HTMLElement).style.background = color;
  }

  private prepareForm(): void {
    const maxChildren = 9;
    const minNameLength = 5;
    const minPhoneNumberLength = 6;

    this.checkInForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(minNameLength)]],
      children: ['0', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(minPhoneNumberLength), Validators.pattern('^[0-9-s]+$')]],
      table: [''],
    });

    for (let i = 0; i < maxChildren + 1; i++) {
      this.children.push(i.toString());
    }

    this.children.push('10+');
  }

  private getCountriesPhoneCodes$(): Observable<ICountryCodeSelectInterface[]> {
    return this.http.get('./assets/countryCodes.json').pipe(
      map((res: any) => {
        const array: any = res.map((value: any) => ({
          dialCode: value.dial_code,
          icon: value.code.toLowerCase(),
        }));

        return array.sort((a: any, b: any) => +a.dialCode.split('+')[1] - +b.dialCode.split('+')[1]);
      }),
      catchError((error: any) => error)
    );
  }

  private setPhoneByLanguage(lang: Languages): void {
    const langKey: string = Object.keys(Languages).find((key: string) => Languages[key] === lang);

    if (langKey) {
      this.selectedCountryCode = CONSTANTS.PHONE_CODE[langKey];
    }
  }

  private checkAndSetSavedData(): void {
    const data: any = JSON.parse(localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.CHECK_IN_DATA));

    // eslint-disable-next-line
    if (data && data.fullName && data.numberOfChildren && data.phone && data.phone.number && data.selectedCountryCode) {
      this.checkInForm.patchValue({
        name: data.fullName,
        children: data.numberOfChildren,
        mobile: data.phone.number,
        table: data.tableNumber,
      });
      this.selectedCountryCode = data.selectedCountryCode;
      this.cdRef.detectChanges();
      this.rememberMe.setValue(true);
    }
  }
}
