export const VOUCHER_ROUTING_CONSTANTS = {
  ROUTES: {
    VOUCHER: 'voucher',
    MAIN: 'voucher/:id',
    STATUSES: {
      ACTIVE: 'active',
      EXPIRED: 'expired',
      USED: 'used',
    },
  },
};
