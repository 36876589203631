import { Component, OnInit } from '@angular/core';
import { FingerprintService } from '../../../core/providers/fingerprint/fingerprint.service';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { ROOM_ROUTING_CONSTANTS } from '../room-routing.constants';
import { Router } from '@angular/router';
import { SurveyService } from '../../survey/survey.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-room-ask',
  templateUrl: './room-ask.component.html',
  styleUrls: ['./room-ask.component.scss'],
})
export class RoomAskComponent implements OnInit {
  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private surveyService: SurveyService,
    private fingerprintService: FingerprintService
  ) {}

  ngOnInit() {
    this.layoutService.loadedLayoutData$.next(true);
  }

  cancel() {
    this.fingerprintService
      .getFingerprint$()
      .pipe(take(1))
      .subscribe(fingerprint => this.surveyService.saveSurveyAndRedirect(fingerprint.visitorId));
  }

  submit() {
    this.router.navigate([`${ROOM_ROUTING_CONSTANTS.ROUTES.MAIN}/${ROOM_ROUTING_CONSTANTS.ROUTES.STATUSES.ROOM_NUMBER}`]);
  }
}
