import { Component, Input } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.scss'],
})
export class BasicPageComponent {
  @Input() headerText: string;
  @Input() message: string;

  constructor(public layoutService: LayoutService) {}
}
