import { CustomValidators } from '../../../shared/custom-validators';
import { FormArray, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import {
  IQuestion,
  IQuestionOption,
  IQuestionPayload,
  IQuestionPayloadAnswer,
  IQuestionTranslation,
  QuestionFormGroup,
  QuestionOptionFormControl,
  QuestionType,
} from '../../interfaces/general.interface';
import { ISurveyStatement } from '../../../components/survey/interfaces/survey-statement.interface';
import { ISurveyTranslation } from '../../../components/survey/interfaces/survey-translation.interface';
import { Injectable } from '@angular/core';
import { LanguageService } from '../language/language.service';
import { Languages } from '../../../common/enums/languages.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SurveyFormService {
  constructor(private formBuilder: FormBuilder, private languageService: LanguageService, private translateService: TranslateService) {}

  getQuestionForm(question: IQuestion, shouldAddValidator: boolean): QuestionFormGroup {
    let questionFormGroup: QuestionFormGroup;

    switch (question.type) {
      case QuestionType.RADIO_BUTTON:
      case QuestionType.CHECKBOX:
        questionFormGroup = this.getDynamicFormatForm(question.options, true, shouldAddValidator ? CustomValidators.optionsSelected : null);
        break;
      case QuestionType.YES_NO:
      case QuestionType.SCALE:
        questionFormGroup = this.getDynamicFormatForm(
          question.options,
          false,
          shouldAddValidator ? CustomValidators.optionsSelected : null
        );
        break;
      case QuestionType.FREE_TEXT:
        questionFormGroup = this.formBuilder.group({
          answer: ['', shouldAddValidator ? Validators.required : null],
        }) as QuestionFormGroup;
        break;
      default:
        questionFormGroup = null;
        break;
    }
    if (questionFormGroup) {
      questionFormGroup.questionUuid = question.uuid;
      questionFormGroup.type = question.type;
    }

    return questionFormGroup;
  }

  getStatement(questionUuid: string, translations: ISurveyTranslation[], categoryName: string): ISurveyStatement {
    return {
      translations,
      uuid: questionUuid,
      category: {
        uuid: questionUuid,
        name: categoryName,
        orderPriority: 1,
        overallExperience: false,
      },
    };
  }

  getScaleAnswer(index: number): boolean[] {
    const maxOptions = 6;
    const answers: boolean[] = [];

    for (let i = 0; i < maxOptions; i++) {
      answers.push(i === index);
    }

    return answers;
  }

  getQuestionTranslation(language: Languages, question: IQuestion, isCampaign = false): IQuestionTranslation {
    let optionsTexts: string[] = [];

    if (question.type !== QuestionType.FREE_TEXT && question.type !== QuestionType.SCALE) {
      optionsTexts =
        question.type === QuestionType.YES_NO
          ? [this.translateService.instant('BUTTON.YES'), this.translateService.instant('BUTTON.NO')]
          : question.options.map((option: IQuestionOption) => {
              if (option.isOther) {
                return 'other';
              }

              return this.languageService.findTranslatedPhraseInLanguage(
                language,
                isCampaign ? option.translations.option : option.translations
              );
            });
    }

    return {
      optionsTexts,
      questionText: this.languageService.findTranslatedPhraseInLanguage(
        language,
        isCampaign ? question.translations.campaignQuestion : question.translations
      ),
    };
  }

  getPayload(questionForm: QuestionFormGroup, token?: string): IQuestionPayload {
    const payload: IQuestionPayload = {
      questionUuid: questionForm.questionUuid,
      answer: this.getPayloadAnswers(questionForm),
    };

    return token ? { ...payload, token } : payload;
  }

  private getPayloadAnswers(questionForm: QuestionFormGroup): string | IQuestionPayloadAnswer[] {
    switch (questionForm.type) {
      case QuestionType.RADIO_BUTTON:
      case QuestionType.CHECKBOX:
      case QuestionType.YES_NO:
      case QuestionType.SCALE:
        return (questionForm.controls.options as FormArray).controls
          .filter((optionControl: QuestionOptionFormControl) => optionControl.value)
          .map((optionControl: QuestionOptionFormControl) => {
            const payload: IQuestionPayloadAnswer = {
              optionUuid: optionControl.getOptionUuid(),
            };

            return optionControl.isOtherOption()
              ? {
                  ...payload,
                  text: questionForm.value.text,
                }
              : payload;
          });
      case QuestionType.FREE_TEXT:
        return questionForm.value.answer;
      default:
        return null;
    }
  }

  private getDynamicFormatForm(options: IQuestionOption[], addText: boolean, validator?: ValidatorFn): QuestionFormGroup {
    return addText
      ? (this.formBuilder.group({
          options: this.getFormOptionArray(options, validator),
          text: [''],
        }) as QuestionFormGroup)
      : (this.formBuilder.group({
          options: this.getFormOptionArray(options, validator),
        }) as QuestionFormGroup);
  }

  private getFormOptionArray(options: IQuestionOption[], validator?: ValidatorFn): FormArray {
    const formArray: FormArray = this.formBuilder.array([], validator);

    for (let i = 0; i < options.length; i++) {
      formArray.push(new QuestionOptionFormControl(options[i].uuid, options[i].isOther, false, []));
    }

    return formArray;
  }
}
