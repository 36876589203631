import { Component, Inject } from '@angular/core';
import { IConfirmDialog } from './confirm.dialog.interface';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export const CONFIRM_DIALOG_CONFIG: MatDialogConfig = {
  maxWidth: '90vw',
  disableClose: false,
};
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    private mdDialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IConfirmDialog
  ) {}

  hideExportModal() {
    this.mdDialogRef.close(false);
  }

  exportData() {
    this.mdDialogRef.close(true);
  }
}
