import { CONSTANTS_ROUTING } from '../../../../common/constants/routing/routing.constants';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WheelOfFortuneService } from '../../wheel-of-fortune.service';

@Component({
  selector: 'app-wheel-of-fortune-winner-summary',
  templateUrl: './wheel-of-fortune-winner-summary.component.html',
  styleUrls: ['./wheel-of-fortune-winner-summary.component.scss'],
})
export class WheelOfFortuneWinnerSummaryComponent implements OnInit {
  constructor(private router: Router, private wheelOfFortuneService: WheelOfFortuneService) {}

  ngOnInit(): void {
    if (!this.wheelOfFortuneService.isWheelEnabled) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
    }
  }

  viewTheVoucher() {
    const voucherToken: string = this.wheelOfFortuneService.getWheelBody().voucher.token;

    this.router.navigate([`voucher/${voucherToken}`]);
  }
}
