import { CONSTANTS_ROUTING } from '../../../common/constants/routing/routing.constants';
import { Component, OnInit } from '@angular/core';
import { IVoucher } from '../voucher.interface';
import { Router } from '@angular/router';
import { VoucherService } from '../voucher.service';

@Component({
  selector: 'app-vou-voucher-used',
  templateUrl: './voucher-used.component.html',
  styleUrls: ['./voucher-used.component.scss'],
})
export class VoucherUsedComponent implements OnInit {
  voucher: IVoucher;

  constructor(private voucherService: VoucherService, private router: Router) {}

  ngOnInit() {
    this.voucher = this.voucherService.getVoucher();

    if (!this.voucher) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
    }
  }
}
