import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GdprPrivacyService {
  private isGdprAccepted = false;

  acceptGdpr() {
    this.isGdprAccepted = true;
  }

  getGdprStatus() {
    return this.isGdprAccepted;
  }
}
