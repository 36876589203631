import { CommonModule } from '@angular/common';
import { FilesService } from './files.service';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [FilesService],
})
export class FilesModule {}
