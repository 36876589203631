export const CONSTANTS_LOCALSTORAGE_KEYS = {
  PORTAL_TOKEN: 'portalToken',
  REF: 'ref',
  SURVEY_TOKEN: 'surv_t',
  HP_BLK: 'hpw_blk',
  VENUE_ID: 'venueId',
  EXPIRES: 'expires',
  CHECK_IN_EXPIRES: 'check_in_exp',
  CHECKED_VEN: 'chck_ven',
  LANG: 'lang',
  PHONE_PREFIX: 'phone_pref',
  SUR: 'sur_exp',
  CHECK_IN_DATA: 'c_in_d',
};
