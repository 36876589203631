import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_VOUCHER } from './voucher.contstants';
import { HttpService } from '../../core/providers/http/http.service';
import { IVoucher } from './voucher.interface';
import { IVoucherBody } from './interfaces/voucher.interface';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VOUCHER_ROUTING_CONSTANTS } from './voucher-routing.constant';
import { VoucherStates } from './voucher.states.enum';
import { catchError, filter } from 'rxjs/operators';

@Injectable()
export class VoucherService {
  voucherState: VoucherStates;

  private voucher: IVoucher;
  private voucherState$: BehaviorSubject<VoucherStates> = new BehaviorSubject(VoucherStates.valid);

  constructor(private httpService: HttpService, private router: Router) {}

  fetchVoucher$(id: string): Observable<IVoucherBody> {
    return this.httpService.get$(`${CONSTANTS_VOUCHER.ENDPOINTS.FETCH_VOUCHER}/${id}`).pipe(
      catchError(error => {
        if (error.status === CONSTANTS_ERRORS.HTTP_404) {
          this.setVoucherState(VoucherStates.notFound);
        }

        return throwError(error);
      })
    );
  }

  getVoucherState$(): Observable<VoucherStates> {
    return this.voucherState$.pipe(filter(state => state !== null));
  }

  setVoucherState(state: VoucherStates) {
    this.voucherState = state;
    this.voucherState$.next(state);
  }

  useVoucher$(id: string): Observable<any> {
    return this.httpService
      .post$(`${CONSTANTS_VOUCHER.ENDPOINTS.FETCH_VOUCHER}/${id}/${CONSTANTS_VOUCHER.ENDPOINTS.USE_VOUCHER}`, null)
      .pipe(
        catchError(error => {
          this.setVoucherState(VoucherStates.error);

          return throwError(error);
        })
      );
  }

  setVoucher(voucher: IVoucher) {
    this.voucher = voucher;
  }

  getVoucher() {
    return this.voucher;
  }

  setVoucherStateAccordingToFlag() {
    if (this.voucher.isExpired) {
      this.setVoucherState(VoucherStates.expired);
    }
    if (this.voucher.isUsed) {
      this.setVoucherState(VoucherStates.used);
    }
    if (!this.voucher.isExpired && !this.voucher.isUsed) {
      this.setVoucherState(VoucherStates.valid);
    }
  }

  redirectAccordingToState() {
    const voucherId = this.voucher.token;
    const mainPath: string = VOUCHER_ROUTING_CONSTANTS.ROUTES.MAIN.replace(':id', voucherId);

    if (this.voucher.isExpired) {
      this.setVoucherState(VoucherStates.expired);
      this.router.navigate([`${mainPath}/${VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.EXPIRED}`]);
    }
    if (this.voucher.isUsed) {
      this.setVoucherState(VoucherStates.used);
      this.router.navigate([`${mainPath}/${VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.USED}`]);
    }
    if (!this.voucher.isExpired && !this.voucher.isUsed) {
      this.router.navigate([`${mainPath}/${VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.ACTIVE}`]);
    }
  }
}
