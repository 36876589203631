<form class="CampaignForm" [formGroup]="questionForm" *ngIf="question">
  <div class="CampaignForm-formGroup mb-40">
    <ng-container
      *ngTemplateOutlet="
        questionTmpl;
        context: {
          questionText: questionText,
          formatType: question.type,
          questionFormGroup: questionForm,
          statement: question.statement,
          scaleControl: scaleStatementControl
        }
      "></ng-container>
  </div>
  <app-form-buttons
    [submitTranslationKey]="
      isEndOfCampaign
        ? 'WHEEL_OF_FORTUNE.PAGES.SPIN.BUTTONS.FINISH'
        : 'BUTTON.NEXT'
    "
    cancelTranslationKey="BUTTON.BACK"
    [isDisabled]="!questionForm.valid"
    [isCancelBtnDisabled]="isBackBtnDisabled"
    [isColumnOrientation]="false"
    [isNavigation]="true"
    (onSubmit)="next()"
    (onCancel)="back()"></app-form-buttons>
</form>

<ng-template
  #questionTmpl
  let-questionText="questionText"
  let-formatType="formatType"
  let-questionFormGroup="questionFormGroup"
  let-scaleControl="scaleControl"
  let-statement="statement">
  <h3 class="CampaignForm-question">{{ questionText }}</h3>
  <div class="CampaignForm-answerContainer mt-40" [ngSwitch]="formatType">
    <ng-container *ngSwitchCase="questionType.YES_NO">
      <ng-template
        *ngTemplateOutlet="
          radioButtonFormat;
          context: { questionFormGroup: questionFormGroup }
        "></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="questionType.SCALE">
      <app-sur-survey-statement [control]="scaleControl" [statement]="statement" [isSkippingMode]="true"></app-sur-survey-statement>
    </ng-container>
    <ng-container *ngSwitchCase="questionType.RADIO_BUTTON">
      <ng-template
        *ngTemplateOutlet="
          radioButtonFormat;
          context: { questionFormGroup: questionFormGroup }
        "></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="questionType.CHECKBOX">
      <ng-template
        *ngTemplateOutlet="
          checkboxFormat;
          context: { questionFormGroup: questionFormGroup }
        "></ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="CampaignForm-answer" [formGroup]="questionFormGroup">
        <textarea formControlName="answer" ngDefaultControl [placeholder]="'MORE_QUESTION.PLEASE_STATE_ANSWER' | translate"></textarea>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #radioButtonFormat let-questionFormGroup="questionFormGroup" let-isNumeric="isNumeric">
  <div [formGroup]="questionFormGroup">
    <mat-radio-group formArrayName="options">
      <mat-chip-list aria-label="age" selectable="true">
        <mat-chip
          *ngFor="
            let option of questionFormGroup.controls.options.controls;
            let i = index
          "
          selectable="true"
          [selected]="option.value"
          (click)="onSelectRadioOption(i, questionFormGroup.controls.options)">
          <mat-radio-button [checked]="option.value" [value]="i">
            <span>{{ question.options[i].isOther ? ("CAMPAIGNS.OTHER" | translate) : questionOptionsTexts[i] }}</span>
          </mat-radio-button>
        </mat-chip>
      </mat-chip-list>
    </mat-radio-group>
    <div class="CampaignForm-answer mt-20" *ngIf="isOtherSelected">
      <textarea formControlName="text" ngDefaultControl [placeholder]="'MORE_QUESTION.PLEASE_STATE_ANSWER' | translate"></textarea>
    </div>
  </div>
</ng-template>
<ng-template #checkboxFormat let-questionFormGroup="questionFormGroup">
  <div [formGroup]="questionFormGroup">
    <mat-chip-list aria-label="age" selectable="true" multiple formArrayName="options">
      <mat-chip
        *ngFor="
          let option of questionFormGroup.controls.options.controls;
          let i = index
        "
        selectable="true"
        [selected]="option.value"
        (click)="onSelectCheckboxOption(i, questionFormGroup.controls.options)">
        <div>
          <mat-icon>close</mat-icon>
        </div>
        <span>{{ question.options[i].isOther ? ("CAMPAIGNS.OTHER" | translate) : questionOptionsTexts[i] }}</span>
      </mat-chip>
    </mat-chip-list>
    <div class="CampaignForm-answer mt-20" *ngIf="isOtherSelected">
      <textarea formControlName="text" ngDefaultControl [placeholder]="'MORE_QUESTION.PLEASE_STATE_ANSWER' | translate"></textarea>
    </div>
  </div>
</ng-template>
