import { CONSTANTS } from '../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { FingerprintService } from '../../core/providers/fingerprint/fingerprint.service';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../../core/providers/http/http.service';
import { ICheckInPayload, IQrLandingSettings } from './qr-landing.interface';
import { ICountryCodeSelectInterface } from './qr-landing-check-in/country-code-select.interface';
import { ILanguage } from '../../common/interfaces/language.interface';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { LanguageService } from '../../core/providers/language/language.service';
import { LayoutService } from '../../core/providers/layout/layout.service';
import { Observable, Subject, of, throwError } from 'rxjs';
import { QR_LANDING_CONSTANTS } from './qr-landing.constants';
import { Router } from '@angular/router';
import { SurveyService } from '../survey/survey.service';
import { Title } from '@angular/platform-browser';
import { UtilsService } from '../../core/providers/util/utils.service';
import { catchError, first, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QrLandingService implements OnDestroy {
  private qrLandingSettings: IQrLandingSettings;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private httpService: HttpService,
    private surveyService: SurveyService,
    private fingerprintService: FingerprintService,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private titleService: Title,
    private router: Router,
    private zone: NgZone,
    private utilsService: UtilsService
  ) {}

  fetchSettings$(id: string): Observable<IQrLandingSettings> {
    // const params = new HttpParams().set('f', 'fingerprint.visitorId');

    // return this.httpService.get$(`${QR_LANDING_CONSTANTS.ENDPOINTS.FETCH_SETTINGS}/${id}`, {params});
    return this.getFingerPrint$().pipe(
      first(),
      mergeMap((fingerprint: any) => {
        const params: any = new HttpParams().set('f', fingerprint.visitorId);

        return this.httpService.get$(`${QR_LANDING_CONSTANTS.ENDPOINTS.FETCH_SETTINGS}/${id}`, { params });
      })
    );
  }

  fetchSurveyToken$(qrToken: string): Observable<{ token: string }> {
    return this.getFingerPrint$().pipe(
      first(),
      mergeMap((fingerprint: any) =>
        this.httpService.post$(`${QR_LANDING_CONSTANTS.ENDPOINTS.FETCH_TOKEN}/${qrToken}?f=${fingerprint.visitorId}`, {})
      ),
      catchError(error => {
        switch (error.status) {
          case CONSTANTS_ERRORS.HTTP_404: {
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.NOT_FOUND]);

            return of(null);
          }
          case CONSTANTS_ERRORS.HTTP_400:
          case CONSTANTS_ERRORS.HTTP_403: {
            this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.BACK]));

            return of(null);
          }
          default:
            break;
        }

        return throwError(error);
      })
    );
  }

  saveCheckIn$(data: ICheckInPayload, rememberMe: boolean, selectedCountryCode: ICountryCodeSelectInterface): Observable<{ uuid: string }> {
    const surveyToken: string = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.SURVEY_TOKEN);

    if (surveyToken) {
      localStorage.removeItem(CONSTANTS.LOCALSTORAGE_KEYS.SURVEY_TOKEN);
    }

    return this.httpService.post$(QR_LANDING_CONSTANTS.ENDPOINTS.CHECK_IN, data).pipe(
      tap((val: any) => {
        this.setCheckInDataInLocalStorage(data.venueUuid);
        this.sendFingerprintData(val.uuid, surveyToken);

        if (rememberMe) {
          this.saveCheckInDataInLocalstorage(data, selectedCountryCode);
        } else {
          localStorage.removeItem(CONSTANTS.LOCALSTORAGE_KEYS.CHECK_IN_DATA);
        }
      })
    );
  }

  setQrLandingSettings(settings: IQrLandingSettings): void {
    this.qrLandingSettings = settings;
  }

  getQrLandingSettings(): IQrLandingSettings {
    return this.qrLandingSettings;
  }

  isCheckedInAlready(venueId: string): boolean {
    const key = `${CONSTANTS.LOCALSTORAGE_KEYS.CHECKED_VEN}${venueId}`;
    const expirationTime: any = JSON.parse(localStorage.getItem(key));

    if (expirationTime && expirationTime.dateTime) {
      const actualDate: Date = new Date();

      return actualDate.getTime() < new Date(expirationTime.dateTime).getTime();
    }

    return false;
  }

  setBasicSettings(): void {
    this.setLayoutCustomSettings();
    this.setAvailableLanguages();
    this.setDefaultLanguage();
    this.setTabName();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private sendFingerprintData(ref: string, surveyToken: string): void {
    const isBlocked: boolean = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.HP_BLK) === 'true';

    this.getFingerPrint$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((fingerprint: ExtendedGetResult) => {
        this.surveyService
          .sendFingerprintDataAnalytics(surveyToken, fingerprint, isBlocked, ref)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe();
      });
  }

  private getFingerPrint$(): Observable<ExtendedGetResult> {
    return this.fingerprintService.getFingerprint$();
  }

  private setCheckInDataInLocalStorage(venueId: string): void {
    const key = `${CONSTANTS.LOCALSTORAGE_KEYS.CHECKED_VEN}${venueId}`;
    const fourHour = 4;
    const data: any = {
      dateTime: this.utilsService.getNewDateAfterHours(fourHour),
      id: venueId,
    };

    localStorage.setItem(key, JSON.stringify(data));
  }

  private saveCheckInDataInLocalstorage(data: ICheckInPayload, selectedCountryCode: ICountryCodeSelectInterface): void {
    const payload: any = {
      selectedCountryCode,
      ...data,
    };

    delete payload.tableNumber;
    localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.CHECK_IN_DATA, JSON.stringify(payload));
  }

  private setAvailableLanguages(): void {
    this.languageService.setAvailableLanguages(this.getQrLandingSettings().languages.map((language: ILanguage) => language.code));
  }

  private setDefaultLanguage(): void {
    const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(this.getQrLandingSettings().languages);

    if (defaultLanguage) {
      this.languageService.setAppLanguage(defaultLanguage.code);
    }
  }

  private setLayoutCustomSettings(): void {
    this.setAppColour();
    this.setVenueLogo();
    this.layoutService.loadedLayoutData$.next(true);
  }

  private setAppColour(): void {
    // eslint-disable-next-line
    if (this.getQrLandingSettings().branding) {
      this.layoutService.setLayoutDefaultColor(this.getQrLandingSettings().branding.colorTint);
    }
  }

  private setVenueLogo(): void {
    this.layoutService.setVenueLogo(this.getQrLandingSettings().branding.logoFileUuid);
  }

  private setTabName(): void {
    const brandName: string = this.getQrLandingSettings().venueName;
    const tabTitle: string = brandName ? `${brandName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }
}
