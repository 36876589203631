import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { FingerprintService } from '../../core/providers/fingerprint/fingerprint.service';
import { FormArray, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../../core/providers/http/http.service';
import { IOneMoreQuestionFromBackend, IOneMoreQuestionOption } from './interfaces/one-more-question.interface';
import { IQuestionPayload, QuestionFormGroup, QuestionOptionFormControl, QuestionType } from '../../core/interfaces/general.interface';
import { Injectable } from '@angular/core';
import { ONE_MORE_QUESTION_CONTANTS } from './one-more-question.constants';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OneMoreQuestionService {
  private oneMoreQuestionResponse: IOneMoreQuestionFromBackend;

  constructor(private formBuilder: FormBuilder, private fingerprintService: FingerprintService, private httpService: HttpService) {}

  setOneMoreQuestionResponse(response: IOneMoreQuestionFromBackend): void {
    this.oneMoreQuestionResponse = response;
  }

  getOneMoreQuestionResponse(): IOneMoreQuestionFromBackend {
    return this.oneMoreQuestionResponse;
  }

  fetchQuestion$(venueUuid: string, visitorId: string): Observable<IOneMoreQuestionFromBackend> {
    const params: HttpParams = new HttpParams().set('f', visitorId);

    return this.httpService.get$(`${ONE_MORE_QUESTION_CONTANTS.ENDPOINTS.FETCH}/${venueUuid}`, { params });
  }

  sendQuestion(payload: IQuestionPayload): Observable<boolean> {
    return this.getFingerPrint$().pipe(
      first(),
      mergeMap((fingerprint: any) => {
        payload.visitorId = fingerprint.visitorId;

        return this.httpService.post$(ONE_MORE_QUESTION_CONTANTS.ENDPOINTS.SEND, payload);
      })
    );
  }

  isFollowQuestionActive(selectedOptions: boolean[], options: IOneMoreQuestionOption[]): boolean {
    const questionOptions: boolean[] = this.getTriggerValues(options);

    if (selectedOptions.length !== questionOptions.length) {
      return false;
    }
    for (let i = 0; i < questionOptions.length; i++) {
      if (questionOptions[i] && selectedOptions[i]) {
        return true;
      }
    }

    return false;
  }

  isTriggeredAlways(options: IOneMoreQuestionOption[]): boolean {
    return this.getTriggerValues(options).every((v: boolean) => v === true);
  }

  getTriggerValues(options: IOneMoreQuestionOption[]): boolean[] {
    return options.map((option: IOneMoreQuestionOption) => option.isTrigger);
  }

  getTriggerStaticValues(options: IOneMoreQuestionOption[]): number {
    return options.findIndex((option: IOneMoreQuestionOption) => option.isTrigger);
  }

  private getFingerPrint$(): Observable<ExtendedGetResult> {
    return this.fingerprintService.getFingerprint$();
  }
}
