<app-basic-page headerText="WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.HEADER" message="WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.MESSAGE">
  <div [ngStyle]="styles" class="WheelOfFortunePrize mt-20 mb-20">
    <h3>
      {{ getPrizeText() }}
      <!--{{'WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.PRIZE.HEADER' | translate: {'0': wheelOfFortune.prize.value, '1': wheelOfFortune.prize.currency} }}-->
    </h3>
    <span> {{ "WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.PRIZE.MESSAGE" | translate: { "0": getValidDay() } }} </span>
    <br />
    <p class="WheelOfFortunePrize-email-info mt-20">{{ "WHEEL_OF_FORTUNE.PAGES.SPIN.EMAIL_INFO.TEXT_BEFORE" | translate }}</p>
    <div class="WheelOfFortunePrize-email">
      <input
        [formControl]="emailFormControl"
        [placeholder]="
          'WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.PRIZE.EMAIL_PLACEHOLDER'
            | translate
        "
        type="email"
        class="inp" />
    </div>
    <div class="mt-10">
      <p
        class="gdpr-info"
        [innerHTML]="
          'WHEEL_OF_FORTUNE.PAGES.SPIN.EMAIL_INFO.TEXT_AFTER' | translate
        "></p>
    </div>
    <div>
      <app-button
        (click)="submitPrize()"
        [disabled]="emailFormControl.invalid && !emailFormControl.disabled"
        classes="btn-strong mt-20"
        text="WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.PRIZE.BUTTON"></app-button>
    </div>
  </div>
  <div #buttonRef>
    <app-button-outline (click)="onCancel()" text="BUTTON.NO_THANKS" classes="mb-10"></app-button-outline>
  </div>
</app-basic-page>
