import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { CONSTANTS_SURVEY } from '../survey/survey.constants';
import { HttpService } from '../../core/providers/http/http.service';
import { IDemographicsConfig } from './interfaces/demographics.interface';
import { IDemographicsDto } from './interfaces/demographics-dto.interface';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SurveyService } from '../survey/survey.service';
import { SurveyStates } from '../survey/survey-states.enum';
import { WheelOfFortuneService } from '../wheel-of-fortune/wheel-of-fortune.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DemographicsService {
  isCampaignMode = false;
  private demographicsConfig: IDemographicsConfig;

  constructor(private httpService: HttpService) {}

  setDemographicsConfig(demographics: IDemographicsConfig): void {
    this.demographicsConfig = demographics;
  }

  getDemographicsConfig(): IDemographicsConfig {
    return this.demographicsConfig;
  }

  fetchAgeGroups$(): Observable<string[]> {
    return this.httpService.get$(CONSTANTS_SURVEY.ENDPOINTS.FETCH_AGE_GROUP).pipe(
      catchError(error => {
        if (error.status === CONSTANTS_ERRORS.HTTP_404) {
          console.error('Not found');
        }

        return throwError(error);
      })
    );
  }
}
