<button
  [ngClass]="classes"
  [ngStyle]="{
    'border-color': layoutService.useLayoutColor(),
    color: layoutService.useLayoutColor()
  }"
  [disabled]="disabled"
  class="btn btn-outline">
  {{ text | translate }}
</button>
