import { EmptyPageComponent } from '../../shared/components';
import { ONE_MORE_QUESTION_CONTANTS } from './one-more-question.constants';
import { OneMoreQuestionComponent } from './one-more-question/one-more-question.component';
import { OneMoreQuestionGuard } from './one-more-question.guard';
import { OneMoreQuestionResolver } from './one-more-question.resolver';
import { Routes } from '@angular/router';

export const ONE_MORE_QUESTION_ROUTES: Routes = [
  {
    path: ONE_MORE_QUESTION_CONTANTS.ROUTES.FORM,
    canActivate: [OneMoreQuestionGuard],
    children: [
      {
        path: '',
        component: OneMoreQuestionComponent,
        pathMatch: 'prefix',
        loadChildren: () => import('./one-more-question.module').then(m => m.OneMoreQuestionModule),
        resolve: {
          question: OneMoreQuestionResolver,
        },
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
