import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-ad-thank-you-page',
  templateUrl: './ad-thank-you-page.component.html',
})
export class AdThankYouPageComponent implements OnInit {
  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    this.layoutService.loadedLayoutData$.next(true);
  }
}
