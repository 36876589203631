import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Languages } from '../../../common/enums/languages.enum';

@Component({
  selector: 'app-language-single',
  templateUrl: './language-single.component.html',
  styleUrls: ['./language-single.component.scss'],
})
export class LanguageSingleComponent {
  @Input() language: Languages;
  @Input() languageIcon: string;
  @Input() active: boolean;
  @Output()
  readonly onClick: EventEmitter<Languages> = new EventEmitter();

  getLanguageTitle(): string {
    return {
      [Languages.britishEnglish]: 'English (GB)',
      [Languages.americanEnglish]: 'English (US)',
      [Languages.australianEnglish]: 'English (AU)',
      [Languages.canadianEnglish]: 'English (CA)',
      [Languages.norwegian]: 'Norsk',
      [Languages.danish]: 'Dansk',
      [Languages.german]: 'Deutsch',
      [Languages.spanish]: 'Español',
      [Languages.finnish]: 'Suomi',
      [Languages.french]: 'Français',
      [Languages.frenchQuebec]: 'Français (Canada)',
      [Languages.portuguese]: 'Português',
      [Languages.portugueseBrazilian]: 'Português (Brasileira)',
      [Languages.swedish]: 'Svenska',
      [Languages.chinese]: '中文',
      [Languages.italian]: 'Italiano',
      [Languages.dutch]: 'Nederlands',
    }[this.language];
  }
}
