export const QR_LANDING_CONSTANTS = {
  ENDPOINTS: {
    FETCH_SETTINGS: 'surveys/takeaway/qr-landing/configuration/venue',
    FETCH_TOKEN: 'surveys/takeaway/qr-landing/create-survey/venue',
    CHECK_IN: 'surveys/takeaway/check-in',
  },
  ROUTES: {
    MAIN: 'foodback-portal',
    FORMS: {
      CHECK_IN: 'check-in',
    },
  },
};
