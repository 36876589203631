import { AfterViewChecked, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CONSTANTS } from '../../../../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../../../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../../../../common/constants/routing/routing.constants';
import { ICheckInDialogInterface } from './check-in.dialog.interface';
import { LayoutService } from '../../../../../core/providers/layout/layout.service';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { QrLandingService } from '../../../../../components/qr-landing/qr-landing.service';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

export const CHECKIN_DIALOG_CONFIG: MatDialogConfig = {
  maxWidth: '90vw',
  disableClose: false,
};
@Component({
  selector: 'app-check-in-dialog',
  templateUrl: './check-in-dialog.component.html',
  styleUrls: ['./check-in-dialog.component.scss'],
})
export class CheckInDialogComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('spinnerRef') spinnerRef;
  isLoading = false;
  isLoaded = false;
  colour: string;

  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<CheckInDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ICheckInDialogInterface,
    private layoutService: LayoutService,
    private qrLandingService: QrLandingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.colour = this.layoutService.getDefaultColor();
  }

  ngAfterViewChecked(): void {
    if (this.spinnerRef) {
      this.spinnerRef._elementRef.nativeElement.children[0].children[0].style.stroke = this.colour;
    }
  }

  hideExportModal() {
    this.mdDialogRef.close(false);
  }

  exportData() {
    this.isLoading = true;
    this.qrLandingService
      .saveCheckIn$(this.data.payload, this.data.rememberMe, this.data.selectedCountryCode)
      .pipe(
        catchError(error => {
          if (error.status === CONSTANTS_ERRORS.HTTP_400 || error.status === CONSTANTS_ERRORS.HTTP_500) {
            this.mdDialogRef.close(false);
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
          }

          return throwError(error);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(value => {
        setTimeout(() => {
          this.isLoaded = true;
          this.showSuccessfulMessage(value);
        }, CONSTANTS.TIME.TIME_1000);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private showSuccessfulMessage(value: { uuid: string }) {
    setTimeout(() => {
      this.mdDialogRef.close(value);
    }, CONSTANTS.TIME.TIME_3000);
  }
}
