import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
  constructor(private translateService: TranslateService) {}

  error(message = 'NOTIFICATION.ERROR.MESSAGE', title = 'NOTIFICATION.ERROR.TITLE') {
    console.error(this.translateService.instant(title), this.translateService.instant(message));
  }
}
