import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appOutsideClick]',
})
export class OutsideClickDirective {
  @Output()
  readonly outsideClick: EventEmitter<null> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.outsideClick.emit(null);
    }
  }
}
