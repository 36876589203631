import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FingerprintService } from '../core/providers/fingerprint/fingerprint.service';
import { GoogleAnalyticsService } from '../core/providers/google-analytics/google-analytics.service';
import { LayoutService } from '../core/providers/layout/layout.service';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { TokenService } from '../core/providers/token/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked, OnInit, OnDestroy {
  @ViewChild('spinnerRef') spinnerRef: any;
  isLoaded = false;
  isLogoLoaded = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private layoutService: LayoutService,
    private cdr: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private locationStrategy: LocationStrategy,
    private fingerprintService: FingerprintService
  ) {}

  ngAfterViewChecked(): void {
    if (this.spinnerRef && this.spinnerRef._elementRef && this.spinnerRef._elementRef.nativeElement) {
      this.spinnerRef._elementRef.nativeElement.children[0].children[0].style.stroke = this.layoutService.getDefaultColor();
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.layoutService.loadedLayoutData$.subscribe(isLoaded => {
        this.isLoaded = isLoaded;
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      this.layoutService.loadedLogo$.subscribe(isLoaded => {
        this.isLogoLoaded = isLoaded;
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      this.tokenService.getToken$().subscribe(token => {
        if (token) {
          this.googleAnalyticsService.injectGAScript(token);
        }
      })
    );
    this.fingerprintService.setFingerPrint();
    this.preventBackButton();
  }

  preventBackButton(): void {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      window.history.go(-1);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
