import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { CampaignService } from './campaign.service';
import { FingerprintService } from '../../core/providers/fingerprint/fingerprint.service';
import { IAdvertisement } from '../../common/interfaces/advertisement.interface';
import { IBranding } from '../../common/interfaces/branding.interface';
import { ICampaignOptions, ICampaignQuestion, ICampaignResponse } from './interfaces/campaign.interface';
import { IGeneralVenueInfo } from '../../core/interfaces/general.interface';
import { IWheelOfFortuneConfig } from '../wheel-of-fortune/interfaces/wheel-of-fortune.interface';
import { Injectable, NgZone } from '@angular/core';
import { LayoutService } from '../../core/providers/layout/layout.service';
import { Observable, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { WheelOfFortuneService } from '../wheel-of-fortune/wheel-of-fortune.service';
import { first, map, mergeMap, take } from 'rxjs/operators';

@Injectable()
export class CampaignResolver implements Resolve<ICampaignResponse> {
  constructor(
    private campaignService: CampaignService,
    private layoutService: LayoutService,
    private titleService: Title,
    private wheelOfFortuneService: WheelOfFortuneService,
    private router: Router,
    private zone: NgZone,
    private fingerprintService: FingerprintService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICampaignResponse> {
    return this.fingerprintService.getFingerprint$().pipe(
      first(),
      mergeMap((fingerprint: any) => this.getCampaign(route.params.venueId, fingerprint.visitorId))
    );
  }

  private getCampaign(venueUuid: string, visitorId: string): Observable<ICampaignResponse> {
    return this.campaignService.fetchCampaign$(venueUuid, visitorId).pipe(
      map((response: ICampaignResponse) => {
        if (response.info) {
          this.setLayoutCustomSettings(response.info);
        }
        if (response.info && response.info.wheel) {
          this.setStatusWheelOfFortune(response.info.wheel);
          this.wheelOfFortuneService.isWheelBlocked = false;
          this.wheelOfFortuneService.venueUuid = venueUuid;
        }

        return this.getAndResetCampaignValues(response);
      })
    );
  }

  private getAndResetCampaignValues(reposnse: ICampaignResponse | { token: string }): ICampaignResponse {
    if ((reposnse as { token: string }).token) {
      this.redirectToSurvey((reposnse as { token: string }).token);
    }

    (reposnse as ICampaignResponse).campaign.questions.forEach((question: ICampaignQuestion) => {
      if (question.options && question.options.length > 0) {
        question.options = question.options.map((option: ICampaignOptions) => ({
          ...option,
          value: null,
        }));
      }
    });

    return reposnse as ICampaignResponse;
  }

  private redirectToSurvey(token: string): void {
    const path: string = CONSTANTS_ROUTING.ROUTES.SURVEY.replace(':id', token);

    this.zone.run(() => this.router.navigate([path]));
  }

  private setLayoutCustomSettings(info: IGeneralVenueInfo): void {
    this.setAppColour(info.branding);
    this.setVenueLogo(info.branding);
    this.setTabName(info.venueName);
    this.setAdvertisement(info.advertisement);
  }

  private setAppColour(branding: IBranding): void {
    // eslint-disable-next-line
    if (branding) {
      this.layoutService.setLayoutDefaultColor(branding.colorTint);
    }
  }

  private setVenueLogo(branding: IBranding): void {
    this.layoutService.setVenueLogo(branding.logoFileUuid);
  }

  private setTabName(venueName: string): void {
    const tabTitle: string = venueName ? `${venueName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }

  private setAdvertisement(advertisement: IAdvertisement): void {
    if (advertisement && advertisement.advertisementFileUuid) {
      this.layoutService.setAdvertisementURL(advertisement.advertisementFileUuid, advertisement.url);
    }
  }

  private setStatusWheelOfFortune(wheel: IWheelOfFortuneConfig): void {
    this.wheelOfFortuneService.setWheelOfFortuneEnable(wheel.isEnabled);
    this.wheelOfFortuneService.prizeText = wheel.prizeText;
  }
}
