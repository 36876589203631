<ng-container [ngSwitch]="getSurveyState$() | async">
  <app-sur-survey-fill *ngSwitchCase="surveyStates.inProgress"></app-sur-survey-fill>

  <app-forbidden-page
    *ngSwitchCase="surveyStates.answered"
    headerText="SURVEY.PAGES.ANSWERED_PAGE.HEADER"
    message="SURVEY.PAGES.ANSWERED_PAGE.MESSAGE"
    iconMaterial="check_circle_outline"></app-forbidden-page>

  <app-forbidden-page
    *ngSwitchCase="surveyStates.expired"
    headerText="SURVEY.PAGES.EXPIRED_PAGE.HEADER"
    message="SURVEY.PAGES.EXPIRED_PAGE.MESSAGE"
    iconMaterial="schedule"></app-forbidden-page>
</ng-container>
