export enum Languages {
  britishEnglish = 'en-GB',
  americanEnglish = 'en-US',
  australianEnglish = 'en-AU',
  canadianEnglish = 'en-CA',
  norwegian = 'nb-NO',
  danish = 'da-DK',
  german = 'de-DE',
  spanish = 'es-ES',
  finnish = 'fi-FI',
  french = 'fr-FR',
  frenchQuebec = 'fr-CA',
  portuguese = 'pt-PT',
  portugueseBrazilian = 'pt-BR',
  swedish = 'sv-SE',
  chinese = 'zh-Hans',
  italian = 'it-IT',
  dutch = 'nl-NL',
}
