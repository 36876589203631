import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS_ERRORS } from '../../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../../common/constants/routing/routing.constants';
import { DialogService } from '../../../shared/components/dialog/dialog.service';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { FingerprintService } from '../../../core/providers/fingerprint/fingerprint.service';
import { IWheelOfFortune } from '../interfaces/wheel-of-fortune.interface';
import { IWheelOfFortuneSlice } from './wheel-of-fortune-slice.interface';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { SurveyService } from '../../survey/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from '../wheel-of-fortune-routing.constants';
import { WheelOfFortuneService } from '../wheel-of-fortune.service';
import { WheelOfFortuneSlices } from './wheen-of-fortune-slices.enum';
import { WheelOfFortuneStates } from '../wheel-of-fortune.states';
import { take, takeUntil } from 'rxjs/operators';

declare let $: any;
const AMOUNT_OF_SLICES = 12;
const AMOUNT_OF_WINNER_SLICES = 4;

@Component({
  selector: 'app-wheel-of-fortune-spin',
  templateUrl: './wheel-of-fortune-spin.component.html',
  styleUrls: ['./wheel-of-fortune-spin.component.scss'],
})
export class WheelOfFortuneSpinComponent implements AfterViewInit, OnInit, OnDestroy {
  wheelSpinning: boolean;
  easyWheelReference: any;
  private subscriptions: Subscription = new Subscription();
  private initialState = null;
  private isWheelTouched = false;
  private winnerTextSlices: string[] = [];
  private looserTextSlices: string[] = [];
  private onDestroy$ = new Subject<void>();
  private dialogMargin: number;

  constructor(
    private wheelOfFortuneService: WheelOfFortuneService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private surveyService: SurveyService,
    private dialogService: DialogService,
    private router: Router,
    private fingerprintService: FingerprintService
  ) {}

  getPrizeText(): string {
    return this.wheelOfFortuneService.prizeText;
  }

  getPrizeBgColor(): string {
    const alpha = 0.1;

    return this.layoutService.useLayoutColor(alpha);
  }

  getPrizeBorderColor(): string {
    return this.layoutService.useLayoutColor();
  }

  spin() {
    this.fingerprintService
      .getFingerprint$()
      .pipe(take(1))
      .subscribe(fingerprint => this.fetchWheel(fingerprint));
  }

  spinByTouch() {
    // eslint-disable-next-line
    if (!this.isWheelTouched) {
      this.isWheelTouched = true;
      this.disableScrolling();
      this.spin();
    }
  }

  cancelWheelOfFortune() {
    this.dialogService
      .confirmDialog({
        headerText: this.translateService.instant('VALIDATION_MSG.ARE_YOU_SURE'),
        confirmBtnText: this.translateService.instant('BUTTON.YES'),
        cancelBtnText: this.translateService.instant('BUTTON.NO_GO_BACK'),
        topPosition: `${this.dialogMargin}px`,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
          this.cancel();
        }
      });
  }

  ngOnInit() {
    if (!this.wheelOfFortuneService.isWheelEnabled) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
    } else if (this.wheelOfFortuneService.isWheelBlocked) {
      this.wheelOfFortuneService.setWheelOfFortuneState(WheelOfFortuneStates.blocked);
      this.router.navigate([
        // eslint-disable-next-line
        `${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN}/${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.BLOCKED}`,
      ]);
    }
  }

  ngAfterViewInit(): void {
    // eslint-disable-next-line
    if (!this.wheelOfFortuneService.isWheelBlocked) {
      this.setSlicesText();
      this.initialState = $('.WheelOfFortuneSpin__wheel').clone();
      this.setWheel();
      this.subscriptions.add(this.translateService.onLangChange.subscribe(() => this.setWheel()));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private cancel() {
    this.wheelOfFortuneService.setWheelOfFortuneState(WheelOfFortuneStates.cancelled);
    this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU]);
  }

  private fetchWheel(fingerprint: ExtendedGetResult) {
    // const token: string = this.wheelOfFortuneService.campaignId ? this.wheelOfFortuneService.campaignId : this.surveyService.getSurveyId();

    if (this.wheelOfFortuneService.venueUuid) {
      this.wheelOfFortuneService
        .fetchWheelCampaign$(this.wheelOfFortuneService.venueUuid, this.wheelOfFortuneService.campaignResponseUuid)
        .subscribe((wheel: IWheelOfFortune) => {
          this.handleWheelSpinResponse(wheel);
          this.wheelOfFortuneService.venueUuid = null;
        });
    } else {
      this.wheelOfFortuneService.fetchWheel$(this.surveyService.getSurveyId(), fingerprint).subscribe((wheel: IWheelOfFortune) => {
        this.handleWheelSpinResponse(wheel);
      });
    }
  }

  private handleWheelSpinResponse(wheel: IWheelOfFortune): void {
    this.wheelOfFortuneService.setWheelBody(wheel);
    $('.WheelOfFortuneSpin__wheel').replaceWith(this.initialState);
    this.setWheel(wheel.win, true);
    this.easyWheelReference.start();
  }

  private disableScrolling() {
    $('html, body').css({
      overflow: 'hidden',
      height: '100%',
    });
  }

  private enableScrolling() {
    $('html, body').css({
      overflow: 'auto',
      height: 'auto',
    });
  }

  private setSlicesText() {
    for (let i = 0; i < AMOUNT_OF_SLICES; i++) {
      const winner = i % AMOUNT_OF_WINNER_SLICES === 0;

      if (winner) {
        this.winnerTextSlices.push(`${WheelOfFortuneSlices.winner}-${i}`);
      } else {
        this.looserTextSlices.push(`${WheelOfFortuneSlices.looser}-${i}`);
      }
    }
  }

  private getRandomWinnerSliceText(): string {
    const winnerTextIndex = Math.floor(Math.random() * this.winnerTextSlices.length);

    return this.winnerTextSlices[winnerTextIndex];
  }

  private getRandomLooserSliceText(): string {
    const looserTextIndex = Math.floor(Math.random() * this.looserTextSlices.length);

    return this.looserTextSlices[looserTextIndex];
  }

  private onWheelStop(isWin: boolean, isInitial: boolean) {
    // eslint-disable-next-line
    if (isInitial) {
      setTimeout(() => {
        this.wheelSpinning = false;
        this.enableScrolling();
        this.wheelOfFortuneService.setWheelOfFortuneState(isWin ? WheelOfFortuneStates.win : WheelOfFortuneStates.loose);
        // eslint-disable-next-line
        if (isWin) {
          this.router.navigate([
            // eslint-disable-next-line
            `${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN}/${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.WINNER}`,
          ]);
        } else {
          this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.LOOSER]);
        }
      }, CONSTANTS_ERRORS.HTTP_500);
    }
  }

  private setWheel(isWin = false, isInitial = false) {
    const layoutColor = this.layoutService.useLayoutColor();

    this.easyWheelReference = $('.WheelOfFortuneSpin__wheel').easyWheel({
      selector: 'status',
      selected: [isWin ? this.getRandomWinnerSliceText() : this.getRandomLooserSliceText()],
      items: this.initWheelSlices(),
      button: isInitial ? '.spin-now' : '.ss',
      duration: 6000,
      rotates: 5,
      frame: 1,
      easing: 'easeInOutCirc',
      rotateCenter: false,
      type: 'spin',
      markerAnimation: true,
      centerClass: 0,
      width: 400,
      fontSize: 13,
      textOffset: 8,
      letterSpacing: 0,
      textLine: 'v',
      textArc: false,
      shadowOpacity: 2,
      sliceLineWidth: 4,
      outerLineWidth: 5,
      centerWidth: 40,
      centerLineWidth: 4,
      centerImageWidth: 25,
      textColor: '#fff',
      markerColor: layoutColor,
      centerLineColor: 'rgb(66, 66, 66)',
      centerBackground: 'rgb(51, 51, 51)',
      sliceLineColor: 'rgb(66, 66, 66)',
      outerLineColor: 'rgb(66, 66, 66)',
      centerImage: 'assets/svg/foodback-happy.svg',
      shadow: 'rgb(0, 0, 0)',
      selectedSliceColor: '#333',
      onStart: () => (this.wheelSpinning = true),
      onComplete: () => this.onWheelStop(isWin, isInitial),
    })[0].easyWheel;
  }

  private initWheelSlices(): IWheelOfFortuneSlice[] {
    const layoutColor = this.layoutService.useLayoutColor();
    const slices: IWheelOfFortuneSlice[] = [];

    for (let i = 0; i < AMOUNT_OF_SLICES; i++) {
      const winner = i % AMOUNT_OF_WINNER_SLICES === 0;

      slices.push({
        name: this.translateService.instant(
          winner ? 'WHEEL_OF_FORTUNE.PAGES.SPIN.WHEEL.WINNER' : 'WHEEL_OF_FORTUNE.PAGES.SPIN.WHEEL.LOOSER'
        ),
        color: winner ? layoutColor : 'rgb(127, 140, 141)',
        status: winner ? `${WheelOfFortuneSlices.winner}-${i}` : `${WheelOfFortuneSlices.looser}-${i}`,
      });
    }

    return slices;
  }
}
