export const ONE_MORE_QUESTION_CONTANTS: any = {
  ENDPOINTS: {
    FETCH: 'surveys/takeaway/one-more-question/draw',
    SEND: 'surveys/takeaway/one-more-question/response',
  },
  ROUTES: {
    MAIN: 'one-more-question',
    FORM: 'one-more-question/:venueId',
  },
};
