import { HttpClient } from '@angular/common/http';
import { IServerEnvironmentConfig } from '../../interfaces/server-environment.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, tap } from 'rxjs/operators';

const CONFIG_URL = 'surveys/takeaway/googleAnalyticsData';

@Injectable({
  providedIn: 'root',
})
export class ServerEnvironmentService {
  private readonly baseURL = environment.backendURL;
  private serverEnvironment: IServerEnvironmentConfig;

  constructor(private http: HttpClient) {}

  getServerEnvironmentConfigObject(): IServerEnvironmentConfig {
    return this.serverEnvironment;
  }

  getServerEnvironmentConfig$(token: string): Observable<Readonly<IServerEnvironmentConfig>> {
    if (this.serverEnvironment) {
      return of(this.serverEnvironment);
    }

    return this.getConfigFromServer$(token).pipe(
      tap(serverEnvironmentConfig => {
        this.serverEnvironment = serverEnvironmentConfig;
      })
    );
  }

  private getConfigFromServer$(token: string): Observable<IServerEnvironmentConfig> {
    return this.http.get(`${this.baseURL}/${CONFIG_URL}/${token}`, {}).pipe(map((response: any) => response));
  }
}
