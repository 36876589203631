import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IOneMoreQuestionFromBackend } from './interfaces/one-more-question.interface';
import { Injectable } from '@angular/core';
import { OneMoreQuestionService } from './one-more-question.service';

@Injectable()
export class OneMoreQuestionResolver implements Resolve<IOneMoreQuestionFromBackend> {
  constructor(private oneMoreQuestionService: OneMoreQuestionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IOneMoreQuestionFromBackend {
    return this.oneMoreQuestionService.getOneMoreQuestionResponse();
  }
}
