import { CampaignService } from '../campaign.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICampaignOptions, ICampaignQuestion } from '../interfaces/campaign.interface';
import {
  IQuestionFreeTextRawValue,
  IQuestionOptionsRawValue,
  IQuestionTranslation,
  QuestionFormGroup,
  QuestionType,
} from '../../../core/interfaces/general.interface';
import { LanguageService } from '../../../core/providers/language/language.service';
import { Languages } from '../../../common/enums/languages.enum';
import { Subject } from 'rxjs';
import { SurveyFormService } from '../../../core/providers/survey-form/survey-form.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-question',
  templateUrl: './campaign-question.component.html',
  styleUrls: ['./campaign-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CampaignQuestionComponent),
      multi: true,
    },
  ],
})
export class CampaignQuestionComponent implements OnDestroy, ControlValueAccessor {
  @Input() isBackBtnDisabled: boolean;
  @Output()
  readonly onFinishBtnClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  readonly onSaveFormData: EventEmitter<void> = new EventEmitter<void>();

  questionForm: QuestionFormGroup;
  question: ICampaignQuestion;
  scaleStatementControl: FormControl = new FormControl();
  questionOptionsTexts: string[];
  questionText: string;
  isEndOfCampaign = false;
  isOtherSelected = false;
  readonly questionType: typeof QuestionType = QuestionType;
  private onDestroy$: Subject<void> = new Subject<void>();
  private propagateChange: Function = Function.prototype;

  constructor(
    private formBuilder: FormBuilder,
    private surveyFormService: SurveyFormService,
    private languageService: LanguageService,
    private campaignService: CampaignService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSelectRadioOption(selectedIndex: number, options: FormArray): void {
    options.setValue(
      Array.from(Array(options.controls.length).keys()).map(() => false),
      { emitEvent: false }
    );
    options.controls[selectedIndex].setValue(true);
    this.checkIfIsOtherSelected();
  }

  onSelectCheckboxOption(selectedIndex: number, options: FormArray): void {
    options.controls[selectedIndex].setValue(!options.controls[selectedIndex].value);
    this.checkIfIsOtherSelected();
    this.cdf.detectChanges();
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(question: ICampaignQuestion): void {
    this.question = question;
    this.handleLanguageChanges();

    if (this.question.type === QuestionType.SCALE) {
      this.setStatement();
    }

    this.createForm();
    this.handleFormChanges();
    this.setFormData();
    this.handleFormValueSaveChanges();
  }

  back(): void {
    this.campaignService.backToPreviousQuestion();
  }

  next(): void {
    this.prepareDateAndPropagate();

    if (this.isEndOfCampaign) {
      this.onFinishBtnClicked.emit();
    } else {
      this.campaignService.goToNextStep(this.question, this.questionForm.value);
    }
  }

  private handleLanguageChanges(): void {
    this.languageService
      .getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((language: Languages) => this.setTranslation(language));
  }

  private prepareDateAndPropagate(): void {
    if (this.question.type === QuestionType.FREE_TEXT) {
      this.question.value = this.questionForm.value.answer;
    } else {
      this.question.options.forEach((option: ICampaignOptions, index: number) => {
        option.value = this.questionForm.value.options[index];

        if (option.isOther) {
          option.text = this.questionForm.value.text;
        }
      });
    }

    this.question.payload = this.surveyFormService.getPayload(this.questionForm);
    this.propagateChange(this.question);
  }

  private setTranslation(language: Languages): void {
    const questionTranslations: IQuestionTranslation = this.surveyFormService.getQuestionTranslation(language, this.question, true);

    this.questionText = questionTranslations.questionText;
    this.questionOptionsTexts = questionTranslations.optionsTexts;

    if (this.questionForm) {
      this.cdf.detectChanges();
    }
  }

  private setStatement(): void {
    this.question.statement = this.surveyFormService.getStatement(this.question.uuid, this.question.translations.campaignQuestion, 'cmp');
    this.scaleStatementControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value: number) =>
      this.questionForm.setValue({
        options: this.surveyFormService.getScaleAnswer(value - 1),
      })
    );
  }

  private createForm(): void {
    this.questionForm = this.surveyFormService.getQuestionForm(this.question, this.question.type !== QuestionType.FREE_TEXT);
  }

  private setFormData(): void {
    if (this.question.type === QuestionType.FREE_TEXT) {
      this.questionForm.controls.answer.setValue(this.question.value);
    } else if (this.question.type === QuestionType.SCALE) {
      this.scaleStatementControl.setValue(this.question.options.findIndex((option: ICampaignOptions) => option.value) + 1);
    } else {
      const options: FormArray = this.questionForm.controls.options as FormArray;

      this.question.options.forEach((option: ICampaignOptions, index: number) => {
        options.controls[index].setValue(option.value);

        if (option.isOther) {
          this.questionForm.controls.text.setValue(option.text);
        }
      });
      this.checkIfIsOtherSelected();
    }
  }

  private handleFormChanges(): void {
    this.questionForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: IQuestionOptionsRawValue | IQuestionFreeTextRawValue) => this.handleFormValueChanges(value));
  }

  private handleFormValueChanges(value: IQuestionOptionsRawValue | IQuestionFreeTextRawValue): void {
    this.checkIfFinish(value);
  }

  private checkIfFinish(value: IQuestionOptionsRawValue | IQuestionFreeTextRawValue): void {
    if (this.question && this.questionForm.valid) {
      const nextStep: number = this.campaignService.getNextStep(this.question, value);

      this.isEndOfCampaign = !nextStep;
    }
  }

  private checkIfIsOtherSelected(): void {
    const options: FormArray = this.questionForm.controls.options as FormArray;

    this.isOtherSelected = false;
    this.question.options.forEach((option: ICampaignOptions, index: number) => {
      if (option.isOther && options.controls[index].value) {
        this.isOtherSelected = true;
      }
    });
  }

  private handleFormValueSaveChanges(): void {
    this.questionForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.prepareDateAndPropagate();
      this.onSaveFormData.emit();
    });
  }
}
