<div [style.background-color]="getLayoutColor()" class="VoucherBody mt-20 mb-20">
  <div *ngIf="voucherState !== voucherStates.valid" class="Voucher--invalid">
    <span>{{ (voucherState === voucherStates.expired ? "VOUCHER.BODY.EXPIRED" : "VOUCHER.BODY.USED" ) | translate }}</span>
  </div>

  <div class="VoucherBodyTop"></div>
  <div class="VoucherBody__title">{{ "VOUCHER.BODY.VOUCHER_TITLE" | translate }}</div>
  <div class="VoucherBodyContent" [ngClass]="{ 'mb-20': !voucher.barcode }">
    <div class="VoucherBodyContent__row">
      <span>{{ "VOUCHER.BODY.VOUCHER_CONTENT" | translate }}</span>
      <strong>{{ voucher.prizeText }}</strong>
    </div>
    <div class="VoucherBodyContent__row mt-20">
      <span>{{ "VOUCHER.BODY.VOUCHER_VALID_UNTIL" | translate }}</span>
      <strong>{{ getDate() }}</strong>
    </div>
    <div class="VoucherBodyContent__barCode" *ngIf="voucher.barcode">
      <svg id="barcode"></svg>
    </div>
  </div>

  <div class="VoucherBodyFooter">
    <strong>{{ "VOUCHER.BODY.VOUCHER_ID" | translate }}:</strong>
    <span>{{ voucher.token }}</span>
  </div>
  <div class="VoucherBodyBottom"></div>
</div>
