import { CONTACT_GUEST_CONSTANTS } from './contact-guest.constants';
import { ContactAskComponent } from './contact-ask/contact-ask.component';
import { ContactGuestGuard } from './contact-guest.guard';
import { EmptyPageComponent } from '../../shared/components/pages';
import { ErrorPageComponent } from '../error-pages/components';
import { Routes } from '@angular/router';

export const CONTACT_GUEST_ROUTES: Routes = [
  {
    path: CONTACT_GUEST_CONSTANTS.ROUTES.MAIN,
    children: [
      {
        path: '',
        component: ErrorPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('../error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: CONTACT_GUEST_CONSTANTS.ROUTES.STATUSES.ASK,
        component: ContactAskComponent,
        canActivate: [ContactGuestGuard],
        pathMatch: 'full',
        loadChildren: () => import('./contact-guest.module').then(m => m.ContactGuestModule),
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
