import { Component, OnDestroy, OnInit } from '@angular/core';
import { DemographicsService } from '../demographics.service';
import { LanguageService } from '../../../core/providers/language/language.service';
import { Languages } from '../../../common/enums/languages.enum';
import { Observable, Subject } from 'rxjs';
import { SurveyService } from '../../survey/survey.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
})
export class DemographicsComponent implements OnInit, OnDestroy {
  shouldShowFullMessageText = true;
  headerText = 'DEMOGRAPHICS.MAIN.HEADER';
  messageText;

  private onDestroy$ = new Subject<void>();

  constructor(private languageService: LanguageService, private demographicsService: DemographicsService) {
    const shouldShowFullMessageText: boolean =
      this.demographicsService.getDemographicsConfig().shouldAskForAgeGroup &&
      this.demographicsService.getDemographicsConfig().shouldAskForGender;

    this.messageText = shouldShowFullMessageText ? 'DEMOGRAPHICS.MAIN.MESSAGE' : 'DEMOGRAPHICS.MAIN.MESSAGE_ONE_QUESTION';
  }

  ngOnInit(): void {
    // TODO For now we do not use this solution because of FBI-6333
    // this.checkActiveLanguage();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private checkActiveLanguage() {
    this.getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(language => {
        this.headerText = this.demographicsService.getDemographicsConfig().headerTranslations.find(item => item.lang === language).value;
        this.messageText = this.demographicsService.getDemographicsConfig().textTranslations.find(item => item.lang === language).value;
      });
  }

  private getActiveLanguage$(): Observable<Languages> {
    return this.languageService.getActiveLanguage$();
  }
}
