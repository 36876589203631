import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private token$: BehaviorSubject<string> = new BehaviorSubject('');

  getToken$(): Observable<string> {
    return this.token$;
  }

  setToken(value: string) {
    this.token$.next(value);
  }
}
