import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { IQrLandingSettings } from './qr-landing.interface';
import { Injectable, NgZone } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { QrLandingService } from './qr-landing.service';
import { catchError, first, map } from 'rxjs/operators';

@Injectable()
export class QrLandingResolver implements Resolve<IQrLandingSettings> {
  constructor(private qrLandingService: QrLandingService, private router: Router, private zone: NgZone) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IQrLandingSettings> {
    return this.qrLandingService.fetchSettings$(route.params.id).pipe(
      first(),
      catchError(error => {
        switch (error.status) {
          case CONSTANTS_ERRORS.HTTP_404: {
            this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]));

            return of(null);
          }
          default:
            break;
        }

        return throwError(error);
      }),
      map((response: IQrLandingSettings) => {
        this.qrLandingService.setQrLandingSettings(response);

        if (response.isPortalClosedWhenVenueClosed && !response.isOpen) {
          this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.CLOSED.replace(':id', response.venueUuid)]));
        }

        return response;
      })
    );
  }
}
