import { DEMOGRAPHICS_CONSTANTS } from './demographics.constants';
import { DemographicsComponent } from './demographics/demographics.component';
import { DemographicsGuard } from './demographics.guard';
import { DemographicsWheelComponent } from './demographics-wheel/demographics-wheel.component';
import { EmptyPageComponent } from '../../shared/components/pages';
import { Routes } from '@angular/router';

export const DEMOGRAPHICS_ROUTES: Routes = [
  {
    path: DEMOGRAPHICS_CONSTANTS.ROUTES.MAIN,
    canActivate: [DemographicsGuard],
    children: [
      {
        path: '',
        component: DemographicsComponent,
        pathMatch: 'full',
        loadChildren: () => import('./demographics.module').then(m => m.DemographicsModule),
      },
      {
        path: DEMOGRAPHICS_CONSTANTS.ROUTES.FORMS.WHEEL_OF_FORTUNE,
        component: DemographicsWheelComponent,
        pathMatch: 'full',
        loadChildren: () => import('./demographics.module').then(m => m.DemographicsModule),
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
