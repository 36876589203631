import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS_ERRORS } from '../../../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../../../common/constants/routing/routing.constants';
import { IGeneralVenueInfo } from '../../../../core/interfaces/general.interface';
import { Injectable } from '@angular/core';
import { NavigationPaths } from '../../../../core/providers/navigation/navigation-paths.enum';
import { NavigationService } from '../../../../core/providers/navigation/navigation.service';
import { Observable, of, throwError } from 'rxjs';
import { VenuesService } from '../../../../components/venues/venues.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ClosedPageResolver implements Resolve<IGeneralVenueInfo> {
  constructor(private venuesService: VenuesService, private navigationService: NavigationService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IGeneralVenueInfo> {
    return this.venuesService.fetchVenueWhenClosed$(route.params.id).pipe(
      catchError((error: any) => {
        switch (error.status) {
          case CONSTANTS_ERRORS.HTTP_404: {
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.NOT_FOUND]);

            return of(null);
          }
          case CONSTANTS_ERRORS.HTTP_500: {
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);

            return of(null);
          }
          default:
            break;
        }

        this.navigationService.navigateTo$(NavigationPaths.home).subscribe();

        return throwError(error);
      })
    );
  }
}
