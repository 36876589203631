import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { ISurvey } from '../survey/interfaces/survey.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveyService } from '../survey/survey.service';

@Injectable({
  providedIn: 'root',
})
export class OneMoreQuestionGuard implements CanActivate {
  constructor(private surveyService: SurveyService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const survey: ISurvey = this.surveyService.getSurvey();

    if (survey && this.surveyService.getAskOneMoreQuestion()) {
      return true;
    }

    this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);

    return false;
  }
}
