import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent implements OnInit {
  @Input() headerText = 'PAGES.EMPTY_PAGE.HEADER';
  @Input() message = 'PAGES.EMPTY_PAGE.MESSAGE';

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {
    this.layoutService.loadedLayoutData$.next(true);
  }

  getLayoutColor(): string {
    return this.layoutService.useLayoutColor();
  }
}
