<form class="DemographicsForm" [formGroup]="demographicsForm">
  <div class="DemographicsForm-formGroup mb-20" *ngIf="shouldAskForDemographicsGender">
    <span>{{ "FORM.GENDER" | translate }}</span>
    <div class="radioContainer">
      <mat-radio-group>
        <mat-chip-list aria-label="gender" selectable="true">
          <mat-chip
            *ngFor="let genderType of genderTypes"
            selectable="true"
            [selected]="
              genderType === demographicsForm.controls.gender.value
                ? 'true'
                : 'false'
            "
            (click)="onSelectGender(genderType)">
            <mat-radio-button [checked]="demographicsForm.controls.gender.value === genderType" [value]="genderType"
              >{{ "FORM." + (genderType | uppercase) | translate }}</mat-radio-button
            >
          </mat-chip>
        </mat-chip-list>
      </mat-radio-group>
    </div>
  </div>
  <div class="DemographicsForm-formGroup mb-20" *ngIf="shouldAskForDemographicsAgeGroup">
    <span>{{ "FORM.AGE+GROUP" | translate }}</span>
    <div class="DemographicsForm-ageGroupContainer radioContainer">
      <mat-radio-group>
        <mat-chip-list aria-label="age" selectable="true">
          <mat-chip
            *ngFor="let ageGroup of ageGroups; let i = index"
            selectable="true"
            [selected]="
              ageGroup.value === demographicsForm.controls.ageGroup.value
                ? 'true'
                : 'false'
            "
            (click)="onSelectAgeGroup(ageGroup.value)">
            <mat-radio-button
              [checked]="
                demographicsForm.controls.ageGroup.value === ageGroup.value
              "
              [value]="ageGroup">
              <div
                class="DemographicsForm-ageValueContainer"
                [ngClass]="{
                  'DemographicsForm-ageValueContainer--selected':
                    ageGroup.value === demographicsForm.controls.ageGroup.value
                }">
                <mat-icon *ngIf="i === 0" aria-hidden="false" aria-label="less">arrow_back</mat-icon>
                <span>{{ ageGroup.label }}</span>
                <mat-icon *ngIf="i === ageGroups.length - 1" aria-hidden="false" aria-label="less">arrow_forward</mat-icon>
              </div>
            </mat-radio-button>
          </mat-chip>
        </mat-chip-list>
      </mat-radio-group>
    </div>
  </div>
  <app-form-buttons
    [submitTranslationKey]="submitText"
    cancelTranslationKey="BUTTON.NO_THANKS"
    [isDisabled]="invalid$ | async"
    (onSubmit)="submit()"
    (onCancel)="cancel()"></app-form-buttons>
</form>
