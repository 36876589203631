import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FingerprintService } from '../../../../core/providers/fingerprint/fingerprint.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ISurvey } from '../../interfaces/survey.interface';
import { ISurveyDTO } from '../../interfaces/survey-dto.interface';
import { ISurveyStatement } from '../../interfaces/survey-statement.interface';
import { LanguageService } from '../../../../core/providers/language/language.service';
import { Languages } from '../../../../common/enums/languages.enum';
import { LayoutService } from '../../../../core/providers/layout/layout.service';
import { Observable, Subject } from 'rxjs';
import { ROOM_ROUTING_CONSTANTS } from '../../../room/room-routing.constants';
import { SurveyService } from '../../survey.service';
import { WheelOfFortuneService } from '../../../wheel-of-fortune/wheel-of-fortune.service';
import { take, takeUntil } from 'rxjs/operators';

const OPCIATY = 0.5;

@Component({
  selector: 'app-sur-survey-fill',
  templateUrl: './survey-fill.component.html',
  styleUrls: ['./survey-fill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyFillComponent implements OnInit, OnDestroy {
  surveyForm: FormGroup = new FormGroup({
    comment: new FormControl(''),
  });
  activeLanguage: Languages;
  surveyStatements: ISurveyStatement[] = [];
  readonly survey: ISurvey = this.activatedRoute.snapshot.data.survey;
  iconColour: string = this.layoutService.useLayoutColor(OPCIATY);
  private onDestroy$ = new Subject<void>();

  constructor(
    public layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private surveyService: SurveyService,
    private router: Router,
    private changeDetectionRef: ChangeDetectorRef,
    private wheelOfFortuneService: WheelOfFortuneService,
    private fingerprintService: FingerprintService
  ) {}

  getLayoutColor() {
    return this.layoutService.useLayoutColor();
  }

  onSubmit() {
    this.surveyService.setSurveyDTO(this.getSurveyDTO());
    // eslint-disable-next-line
    if (this.survey.demographics.askForRoomNumber) {
      this.router.navigate([`${ROOM_ROUTING_CONSTANTS.ROUTES.MAIN}/${ROOM_ROUTING_CONSTANTS.ROUTES.STATUSES.ASK}`]);
    } else {
      this.fingerprintService
        .getFingerprint$()
        .pipe(take(1))
        .subscribe(fingerprintCode => this.surveyService.saveSurveyAndRedirect(fingerprintCode.visitorId));
    }
  }

  ngOnInit() {
    this.checkActiveLanguage();

    if (this.survey) {
      this.setSurveyStatement();
      this.setStatusWheelOfFortune();
    }

    this.changeDetectionRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getSurveyDTO(): ISurveyDTO {
    return {
      token: this.activatedRoute.snapshot.params.id,
      comment: this.surveyForm.get('comment').value,
      lang: this.activeLanguage,
      isHotelGuest: false,
      roomNumber: null,
      answers: this.getSurveyStatements().map((statement, index) => ({
        statementUuid: statement.uuid,
        value: this.surveyForm.get(`statement${index}`).value,
      })),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  private setStatusWheelOfFortune() {
    this.wheelOfFortuneService.setWheelOfFortuneEnable(this.survey.wheel.isEnabled);
    this.wheelOfFortuneService.prizeText = this.survey.wheel.prizeText;
  }

  private checkActiveLanguage() {
    this.getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(language => {
        this.activeLanguage = language;

        if (this.survey) {
          this.setSurveyStatement();
          this.changeDetectionRef.detectChanges();
        }
      });
  }

  private setSurveyStatement() {
    this.surveyStatements = this.getSurveyStatements();
    this.setSurveyStatementsControls();
  }

  private getActiveLanguage$(): Observable<Languages> {
    return this.languageService.getActiveLanguage$();
  }

  private setSurveyStatementsControls(): void {
    const statements: ISurveyStatement[] = this.getSurveyStatements();

    if (statements.length > 0 && Object.keys(this.surveyForm.controls).length < statements.length + 1) {
      statements.forEach((statement: ISurveyStatement, index: number) => {
        const control: FormControl = index === 0 ? new FormControl(null, [Validators.required]) : new FormControl(null);

        this.surveyForm.addControl(`statement${index}`, control);
      });
    }
  }

  private getSurveyStatements(): ISurveyStatement[] {
    return this.survey.statements[this.activeLanguage].sort(
      (statementOne: ISurveyStatement, statementTwo: ISurveyStatement) =>
        statementTwo.category.orderPriority - statementOne.category.orderPriority
    );
  }
}
