import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS } from '../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { FilesService } from '../advertisement/files.service';
import { IMainVenue, IVenue } from './interfaces/venue.interface';
import { Injectable } from '@angular/core';
import { NavigationPaths } from '../../core/providers/navigation/navigation-paths.enum';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { Observable, of, throwError } from 'rxjs';
import { TokenService } from '../../core/providers/token/token.service';
import { VenuesService } from './venues.service';
import { VenuesStates } from './venues.enum';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class VenuesResolver implements Resolve<IMainVenue> {
  constructor(
    private venuesService: VenuesService,
    private navigationService: NavigationService,
    private filesService: FilesService,
    private tokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IMainVenue> {
    return this.venuesService.fetchVenues$(route.params.id).pipe(
      map((response: IMainVenue) => {
        this.tokenService.setToken(route.params.id);
        response.branding.logoUrl$ = this.getVenueLogo$(response.branding.logoFileUuid);
        response.venues = response.venues.map((venue: IVenue) => {
          const logoFileUuid = venue.branding.logoFileUuid;

          venue.branding.logoUrl$ = this.getVenueLogo$(logoFileUuid ? logoFileUuid : response.branding.logoFileUuid);

          return venue;
        });

        return response;
      }),
      catchError(error => {
        switch (error.status) {
          case CONSTANTS_ERRORS.HTTP_409: {
            // this.router.navigate([CONSTANTS_ROUTING.ROUTES.NOT_FOUND]);

            return of(null);
          }
          case CONSTANTS_ERRORS.HTTP_500: {
            this.venuesService.setVenuesState(VenuesStates.error);
            // this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);

            return of(null);
          }
          default:
            break;
        }

        this.navigationService.navigateTo$(NavigationPaths.home).subscribe();

        return throwError(error);
      })
    );
  }

  private getVenueLogo$(logoFileUuid: string): Observable<string> {
    const defaultLogoUrl = 'assets/img/foodback-logo.png';

    if (logoFileUuid) {
      const url: string = this.filesService.getUrl(logoFileUuid, null, CONSTANTS.IMAGES.LOGO_HEIGHT);

      return of(url);
    }

    return of(defaultLogoUrl);
  }
}
