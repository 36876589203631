import { Component, Input } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-button-outline',
  templateUrl: './button-outline.component.html',
  styleUrls: ['./button-outline.component.scss'],
})
export class ButtonOutlineComponent {
  @Input() classes: string;
  @Input() disabled: boolean;
  @Input() text: string;

  constructor(public layoutService: LayoutService) {}
}
