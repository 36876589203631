export const CONSTANTS_SURVEY = {
  ENDPOINTS: {
    FETCH_SURVEY: 'surveys/takeaway/statementSets',
    SAVE_SURVEY: 'surveys/takeaway/surveys',
    SAVE_FINGERPRINT_DATA: 'surveys/takeaway/survey-fingerprint',
    FETCH_AGE_GROUP: 'surveys/takeaway/age-groups',
    TYPES: {
      CONTACT_PERMISSION: 'contact-permission',
      DEMOGRAPHICS: 'demographics',
    },
  },
};
