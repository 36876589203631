import { Component, OnInit } from '@angular/core';
import { FingerprintService } from '../../../core/providers/fingerprint/fingerprint.service';
import { FormControl, Validators } from '@angular/forms';
import { ISurveyDTO } from '../../survey/interfaces/survey-dto.interface';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { SurveyService } from '../../survey/survey.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-room-number',
  templateUrl: './room-number.component.html',
  styleUrls: ['./room-number.component.scss'],
})
export class RoomNumberComponent implements OnInit {
  roomNumberControl: FormControl = new FormControl('', [Validators.required]);

  constructor(private layoutService: LayoutService, private surveyService: SurveyService, private fingerprintService: FingerprintService) {}

  ngOnInit() {
    this.layoutService.loadedLayoutData$.next(true);
  }

  cancel() {
    this.fingerprintService
      .getFingerprint$()
      .pipe(take(1))
      .subscribe(fingerprint => this.surveyService.saveSurveyAndRedirect(fingerprint.visitorId));
  }

  submit() {
    const surveyDTO: ISurveyDTO = this.surveyService.getSurveyDTO();

    // eslint-disable-next-line
    if (surveyDTO) {
      surveyDTO.isHotelGuest = true;
      surveyDTO.roomNumber = this.roomNumberControl.value;
      this.surveyService.setSurveyDTO(surveyDTO);
      this.fingerprintService
        .getFingerprint$()
        .pipe(take(1))
        .subscribe(fingerprint => this.surveyService.saveSurveyAndRedirect(fingerprint.visitorId));
    }
  }
}
