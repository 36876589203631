import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { CAMPAIGN } from './campaign.constants';
import { CONSTANTS } from '../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { DEMOGRAPHICS_CONSTANTS } from '../demographics/demographics.constants';
import { DemographicsService } from '../demographics/demographics.service';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { FingerprintService } from '../../core/providers/fingerprint/fingerprint.service';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../../core/providers/http/http.service';
import { ICampaignFormValue, ICampaignPayload, ICampaignQuestion, ICampaignResponse } from './interfaces/campaign.interface';
import { IDemographicsDto } from '../demographics/interfaces/demographics-dto.interface';
import {
  IQuestionFreeTextRawValue,
  IQuestionOptionsRawValue,
  IQuestionPayload,
  QuestionType,
} from '../../core/interfaces/general.interface';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Languages } from '../../common/enums/languages.enum';
import { QR_LANDING_CONSTANTS } from '../qr-landing/qr-landing.constants';
import { QrLandingService } from '../qr-landing/qr-landing.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../core/providers/util/utils.service';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from '../wheel-of-fortune/wheel-of-fortune-routing.constants';
import { WheelOfFortuneService } from '../wheel-of-fortune/wheel-of-fortune.service';
import { catchError, first, mergeMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CampaignService implements OnDestroy {
  currentIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  questionLimitIndex = 0;
  currentIndex = 0;
  previousQuestionIndexes: number[] = [];
  private token: string;
  private campaignResponse: ICampaignResponse;
  private onDestroy$: Subject<void> = new Subject<void>();
  private responseUuid: string;

  constructor(
    private router: Router,
    private fingerprintService: FingerprintService,
    private zone: NgZone,
    private demographicsService: DemographicsService,
    private wheelOfFortuneService: WheelOfFortuneService,
    private httpService: HttpService,
    private qrLandingService: QrLandingService,
    private utilsService: UtilsService
  ) {}

  backToPreviousQuestion(): void {
    this.currentIndex = this.previousQuestionIndexes.pop();

    if (this.currentIndex === undefined) {
      this.goToPortal();
    } else {
      this.currentIndex$.next(this.currentIndex);
    }
  }

  fetchCampaign$(venueUuid: string, visitorId: string): Observable<ICampaignResponse | { token: string }> {
    const params: HttpParams = new HttpParams().set('f', visitorId);

    return this.httpService.get$(`${CAMPAIGN.ENDPOINTS.FETCH}/${venueUuid}`, { params }).pipe(
      catchError((response: any) => {
        switch (response.status) {
          case CONSTANTS_ERRORS.HTTP_404: {
            this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]));

            return of(null);
          }
          case CONSTANTS_ERRORS.HTTP_403: {
            if (response.error.fixedUrlCode) {
              return this.qrLandingService.fetchSurveyToken$(response.error.fixedUrlCode);
            }

            this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.BACK]));

            return of(null);
          }
          default:
            break;
        }

        return throwError(response);
      })
    );
  }

  saveCampaign(payload: ICampaignPayload): Observable<{ responseUuid: string }> {
    return this.getFingerPrint$().pipe(
      first(),
      mergeMap((fingerprint: any) => {
        payload.visitorId = fingerprint.visitorId;

        return this.httpService.post$(`${CAMPAIGN.ENDPOINTS.SEND}`, payload).pipe(
          catchError((error: any) => {
            switch (error.status) {
              case CONSTANTS_ERRORS.HTTP_404: {
                this.router.navigate([CONSTANTS_ROUTING.ROUTES.NOT_FOUND]);

                return of(null);
              }
              case CONSTANTS_ERRORS.HTTP_500: {
                this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);

                return of(null);
              }
              default:
                break;
            }

            return throwError(error);
          })
        );
      })
    );
  }

  goToNextStep(question: ICampaignQuestion, formValue: IQuestionOptionsRawValue | IQuestionFreeTextRawValue): void {
    this.loadQuestion(this.getNextStep(question, formValue));
  }

  getNextStep(question: ICampaignQuestion, formValue: IQuestionOptionsRawValue | IQuestionFreeTextRawValue): number {
    if (!question.isCustomNextStep) {
      const questionIndex: number = this.campaignResponse.campaign.questions.findIndex(
        (campaignQuestion: ICampaignQuestion) => campaignQuestion.uuid === question.uuid
      );

      return questionIndex + 1 < this.campaignResponse.campaign.questions.length ? questionIndex + 2 : null;
    }

    return this.getCustomNextStep(question, formValue);
  }

  setPortalToken(token: string): void {
    this.token = token;
    localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.PORTAL_TOKEN, this.token);
  }

  setCampaign(campaign: ICampaignResponse): void {
    this.campaignResponse = campaign;
  }

  getCampaignPayload(venueUuid: string, answers: IQuestionPayload[], lang: Languages): ICampaignPayload {
    return {
      venueUuid,
      answers,
      lang,
      campaignUuid: this.campaignResponse.campaign.uuid,
    };
  }

  redirectAfterSave(responseUuid: string): void {
    this.responseUuid = responseUuid;

    if (this.campaignResponse.info.wheel && this.campaignResponse.info.wheel.isEnabled) {
      this.wheelOfFortuneService.setWheelConfig(this.campaignResponse.info.wheel);
      this.wheelOfFortuneService.campaignResponseUuid = responseUuid;
    }
    if (
      this.campaignResponse.demographics.isEnabled &&
      (this.campaignResponse.demographics.shouldAskForAgeGroup || this.campaignResponse.demographics.shouldAskForGender)
    ) {
      this.demographicsService.setDemographicsConfig(this.campaignResponse.demographics);
      this.demographicsService.isCampaignMode = true;
      this.goToDemographicsForm();
    } else {
      this.goToHappyWheelOrAdvertisement();
    }
  }

  goToFinalPage(): void {
    this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU]);
  }

  goToHappyWheelAfterDemographicsCanceling(): void {
    if (this.wheelOfFortuneService.getWheelConfig() && this.wheelOfFortuneService.getWheelConfig().isWheelDemographicsEnabled) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU]);
    } else {
      this.goToHappyWheelOrAdvertisement();
    }
  }

  saveDemographics(demographicsDto: IDemographicsDto): void {
    demographicsDto.responseUuid = this.responseUuid;
    this.saveSurveyDemographics$(demographicsDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.goToHappyWheelOrAdvertisement());
  }

  saveCampaignInStorage(formValue: ICampaignFormValue, campaignId: string): void {
    // removes the data with key as user
    localStorage.removeItem(campaignId);
    // // adds new data with key as user
    localStorage.setItem(campaignId, JSON.stringify(formValue));
    localStorage.setItem(`${campaignId}_h`, this.utilsService.getHashCode(JSON.stringify(formValue)).toString());
  }

  getCampaignDataFromStorage(campaignId: string): ICampaignFormValue {
    const data: ICampaignFormValue = JSON.parse(localStorage.getItem(campaignId));
    const hashKey: string = localStorage.getItem(`${campaignId}_h`);

    if (this.utilsService.getHashCode(JSON.stringify(data)).toString() !== hashKey) {
      return null;
    }

    return data;
  }

  removeStorage(campaignId: string): void {
    localStorage.removeItem(campaignId);
    localStorage.removeItem(`${campaignId}_h`);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private saveSurveyDemographics$(demographicsDto: IDemographicsDto): Observable<any> {
    return this.httpService.post$(`${CAMPAIGN.ENDPOINTS.DEMOGRAPHICS}`, demographicsDto).pipe(
      catchError(error => {
        this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]));

        return throwError(error);
      })
    );
  }

  private goToHappyWheelOrAdvertisement(): void {
    if (this.campaignResponse.info.wheel.isEnabled) {
      // this.wheelOfFortuneService.checkAndSetWheelOfFortuneBlockStatus();
      this.router.navigate([WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN]);
    } else {
      this.goToFinalPage();
    }
  }

  private goToDemographicsForm(): void {
    if (this.campaignResponse.info.wheel.isWheelDemographicsEnabled && this.campaignResponse.info.wheel.isEnabled) {
      this.router.navigate([`${DEMOGRAPHICS_CONSTANTS.ROUTES.MAIN}/${DEMOGRAPHICS_CONSTANTS.ROUTES.FORMS.WHEEL_OF_FORTUNE}`]);
    } else {
      this.router.navigate([DEMOGRAPHICS_CONSTANTS.ROUTES.MAIN]);
    }
  }

  private getCustomNextStep(question: ICampaignQuestion, formValue: IQuestionOptionsRawValue | IQuestionFreeTextRawValue): number {
    if (question.type === QuestionType.FREE_TEXT) {
      return this.getQuestionIndexByUuid(question.nextQuestion) + 1;
    }
    const nextStepIndex: number = (formValue as IQuestionOptionsRawValue).options.findIndex((option: boolean) => option);

    return this.getQuestionIndexByUuid(question.options[nextStepIndex].nextQuestion) + 1;
  }

  private getQuestionIndexByUuid(uuid: string): number {
    return this.campaignResponse.campaign.questions.findIndex((campaignQuestion: ICampaignQuestion) => campaignQuestion.uuid === uuid);
  }

  private loadQuestion(index: number): void {
    this.previousQuestionIndexes.push(this.currentIndex);
    this.currentIndex = index ? (index > 0 ? index - 1 : index) : null;
    this.currentIndex$.next(this.currentIndex);
  }

  private goToPortal(): void {
    this.router.navigate([`${QR_LANDING_CONSTANTS.ROUTES.MAIN}/${this.token}`]);
  }

  private getFingerPrint$(): Observable<ExtendedGetResult> {
    return this.fingerprintService.getFingerprint$();
  }
}
