import { Component } from '@angular/core';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { WheelOfFortuneService } from '../../wheel-of-fortune/wheel-of-fortune.service';

@Component({
  selector: 'app-demographics-wheel',
  templateUrl: './demographics-wheel.component.html',
  styleUrls: ['./demographics-wheel.component.scss'],
})
export class DemographicsWheelComponent {
  constructor(private layoutService: LayoutService, private wheelOfFortuneService: WheelOfFortuneService) {}

  getPrizeText(): string {
    return this.wheelOfFortuneService.prizeText;
  }

  getPrizeBgColor(): string {
    const value = 0.1;

    return this.layoutService.useLayoutColor(value);
  }

  getPrizeBorderColor(): string {
    return this.layoutService.useLayoutColor();
  }
}
