import { BehaviorSubject, Subject } from 'rxjs';
import { CampaignService } from '../../campaign/campaign.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DemographicsService } from '../demographics.service';
import { DialogService } from '../../../shared/components/dialog/dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GdprPrivacyService } from '../../../core/providers/gdpr-privacy/gdpr-privacy.service';
import { IDemographicsDto } from '../interfaces/demographics-dto.interface';
import { SurveyService } from '../../survey/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { WheelOfFortuneService } from '../../wheel-of-fortune/wheel-of-fortune.service';
import { takeUntil } from 'rxjs/operators';

export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}
@Component({
  selector: 'app-demographics-form',
  templateUrl: './demographics-form.component.html',
  styleUrls: ['./demographics-form.component.scss'],
})
export class DemographicsFormComponent implements OnInit, OnDestroy {
  @Input() submitText = 'BUTTON.SEND';
  @Input() mainRoute: string;
  demographicsForm: FormGroup;
  ageGroups: {
    label: string;
    value: string;
  }[] = [];
  shouldAskForDemographicsGender: boolean;
  shouldAskForDemographicsAgeGroup: boolean;
  invalid$: Subject<boolean> = new BehaviorSubject(false);
  genderTypes: GENDER[] = [GENDER.MALE, GENDER.FEMALE, GENDER.OTHER];

  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private gdprPrivacyService: GdprPrivacyService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private demographicsService: DemographicsService,
    private wheelOfFortuneService: WheelOfFortuneService,
    private surveyService: SurveyService,
    private campaignService: CampaignService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const uselessSignCountLimit = 2;

    this.demographicsService
      .fetchAgeGroups$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response =>
          (this.ageGroups = response.map((ageGroup, index) => ({
            label: index === 0 || index === response.length - 1 ? ageGroup.slice(uselessSignCountLimit, ageGroup.length) : ageGroup,
            value: ageGroup,
          })))
      );
    this.prepareForm();
    this.shouldAskForDemographicsGender = this.demographicsService.getDemographicsConfig().shouldAskForGender;
    this.shouldAskForDemographicsAgeGroup = this.demographicsService.getDemographicsConfig().shouldAskForAgeGroup;
    this.demographicsForm.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const isValid = this.demographicsForm.controls.gender.valid && this.demographicsForm.controls.ageGroup.value !== '';

      this.invalid$.next(
        this.wheelOfFortuneService.getWheelConfig() && this.wheelOfFortuneService.getWheelConfig().isWheelDemographicsEnabled
          ? !isValid
          : false
      );
    });
  }

  cancel() {
    this.dialogService
      .confirmDialog({
        headerText: this.translateService.instant('VALIDATION_MSG.ARE_YOU_SURE'),
        confirmBtnText: this.translateService.instant('BUTTON.YES'),
        cancelBtnText: this.translateService.instant('BUTTON.NO_GO_BACK'),
        topPosition: '380px',
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
          this.redirectAfterCancel();
        }
      });
  }

  submit() {
    const payload = this.demographicsForm.value;

    payload.ageGroup = payload.ageGroup === '' ? null : payload.ageGroup;
    payload.gender = payload.gender === '' ? null : payload.gender;
    this.redirectAfterSave(payload);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSelectGender(gender: GENDER) {
    this.demographicsForm.controls.gender.setValue(gender);
    this.cdf.detectChanges();
  }

  onSelectAgeGroup(ageGroup: string) {
    this.demographicsForm.controls.ageGroup.setValue(ageGroup);
    this.cdf.detectChanges();
  }

  private prepareForm() {
    this.demographicsForm = this.formBuilder.group({
      gender: [null],
      ageGroup: [''],
    });

    if (this.wheelOfFortuneService.getWheelConfig() && this.wheelOfFortuneService.getWheelConfig().isWheelDemographicsEnabled) {
      this.setValidators();
    }
  }

  private setValidators() {
    this.demographicsForm.controls.gender.setValidators(Validators.required);
    this.demographicsForm.controls.ageGroup.setValidators(Validators.required);
    this.demographicsForm.updateValueAndValidity();
    this.invalid$.next(true);
  }

  private redirectAfterSave(demographicsInfo: IDemographicsDto): void {
    if (this.demographicsService.isCampaignMode) {
      this.campaignService.saveDemographics(demographicsInfo);
    } else {
      this.surveyService.saveDemographics(demographicsInfo);
    }
  }

  private redirectAfterCancel(): void {
    if (this.demographicsService.isCampaignMode) {
      this.campaignService.goToHappyWheelAfterDemographicsCanceling();
    } else {
      this.surveyService.goToHappyWheelAfterDemographicsCanceling();
    }
  }
}
