import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { IAdvertisement } from '../../common/interfaces/advertisement.interface';
import { IBranding } from '../../common/interfaces/branding.interface';
import { ILanguage } from '../../common/interfaces/language.interface';
import { ISurveyStatement } from '../../components/survey/interfaces/survey-statement.interface';
import { ISurveyTranslation } from '../../components/survey/interfaces/survey-translation.interface';

export enum QuestionType {
  YES_NO = 'boolean',
  CHECKBOX = 'checkbox',
  RADIO_BUTTON = 'radio',
  SCALE = 'scale',
  FREE_TEXT = 'text',
}
export class QuestionFormGroup extends FormGroup {
  questionUuid?: string;
  type: QuestionType;
}
export interface IQuestion {
  uuid: string;
  type: QuestionType;
  options?: IQuestionOption[];
  // TODO refactor this below code to have one solution - Exception now - One more question
  translations?: ISurveyTranslation[] | any;
  statement?: ISurveyStatement;
}
export interface IQuestionOption {
  uuid: string;
  isOther?: boolean;
  translations?: ISurveyTranslation[] | any;
}
export interface IQuestionTranslation {
  questionText: string;
  optionsTexts: string[];
}
export interface IQuestionFreeTextRawValue {
  answer: string;
}
export interface IQuestionOptionsRawValue {
  options: boolean[];
}
export interface IQuestionPayload {
  questionUuid: string;
  answer: string | IQuestionPayloadAnswer[];
  token?: string;
  visitorId?: string;
}
export interface IQuestionPayloadAnswer {
  optionUuid: string;
  text?: string;
}
export interface IGeneralVenueInfo {
  advertisement: IAdvertisement;
  branding: IBranding;
  languages: ILanguage[];
  venueName: string;
  portalToken?: string;
}
export class QuestionOptionFormControl extends FormControl {
  private optionUuid: string;
  private isOther: boolean;

  constructor(
    optionUuid: string,
    isOther: boolean,
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this.optionUuid = optionUuid;
    this.isOther = isOther;
  }

  getOptionUuid(): string {
    return this.optionUuid;
  }

  isOtherOption(): boolean {
    return this.isOther;
  }
}
