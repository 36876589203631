import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '../../../core/providers/language/language.service';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { VoucherService } from '../voucher.service';

@Component({
  selector: 'app-vou-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class VoucherComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private voucherService: VoucherService,
    private languageService: LanguageService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.voucherService.redirectAccordingToState();
    this.layoutService.loadedLayoutData$.next(true);
  }
}
