import { CdkPortal } from '@angular/cdk/portal';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  @Input() reference: HTMLElement;

  @ViewChild(CdkPortal)
  contentTemplate: CdkPortal;

  showing = false;

  protected overlayRef: OverlayRef;

  constructor(protected overlay: Overlay) {}

  @HostListener('window:resize')
  onWinResize(): void {
    this.syncWidth();
  }

  show(): void {
    this.blockScrolling();
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.syncWidth();
    this.overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.hide());
    this.showing = true;
  }

  hide(): void {
    this.enableScrolling();
    this.overlayRef.detach();
    this.showing = false;
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: 'cdk-overlay-dark-backdrop',
    });
  }

  private syncWidth(): void {
    if (!this.overlayRef) {
      return;
    }
    const refRect: DOMRect = this.reference.getBoundingClientRect();

    this.overlayRef.updateSize({
      width: refRect.width,
    });
  }

  private blockScrolling(): void {
    document.body.style.overflow = 'hidden';
  }

  private enableScrolling(): void {
    document.body.style.overflow = 'unset';
  }
}
