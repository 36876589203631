import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_WHEEL_OF_FORTUNE } from './wheel-of-fortune.constants';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../../core/providers/http/http.service';
import { IWheelOfFortune, IWheelOfFortuneConfig } from './interfaces/wheel-of-fortune.interface';
import { Injectable } from '@angular/core';
import { Languages } from '../../common/enums/languages.enum';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { ServerEnvironmentService } from '../../core/providers/server-envionment/server-environment.service';
import { UtilsService } from '../../core/providers/util/utils.service';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from './wheel-of-fortune-routing.constants';
import { WheelOfFortuneStates } from './wheel-of-fortune.states';
import { catchError, filter } from 'rxjs/operators';

@Injectable()
export class WheelOfFortuneService {
  isWheelEnabled: boolean;
  prizeText: string;
  isWheelBlocked = true;
  venueUuid: string = null;
  campaignResponseUuid: string = null;
  private wheelOfFortuneState$: BehaviorSubject<WheelOfFortuneStates> = new BehaviorSubject(WheelOfFortuneStates.inProgress);
  private wheelBody: IWheelOfFortune;
  private wheelConfig: IWheelOfFortuneConfig;
  private winnerEmail: string;

  constructor(
    private httpService: HttpService,
    private locationStrategy: LocationStrategy,
    private serverEnvironmentService: ServerEnvironmentService,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.preventBackButton();
  }

  fetchWheel$(id: string, fingerprint: ExtendedGetResult): Observable<IWheelOfFortune> {
    const params = new HttpParams().set('f', fingerprint.visitorId);

    return this.httpService.get$(`${CONSTANTS_WHEEL_OF_FORTUNE.ENDPOINTS.WHEEL_CONFIGURATION}/${id}`, { params }).pipe(
      catchError(error => {
        if (error.status === CONSTANTS_ERRORS.HTTP_500) {
          this.wheelOfFortuneState$ = new BehaviorSubject(WheelOfFortuneStates.error);
        }
        if (error.status === CONSTANTS_ERRORS.HTTP_403) {
          this.wheelOfFortuneState$ = new BehaviorSubject(WheelOfFortuneStates.blocked);
          this.router.navigate([
            // eslint-disable-next-line
            `${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN}/${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.BLOCKED}`,
          ]);
        }

        return throwError(error);
      })
    );
  }

  fetchWheelCampaign$(campaignVenueId: string, responseId: string): Observable<IWheelOfFortune> {
    // const params: HttpParams = new HttpParams().set('f', fingerprint.visitorId);

    return this.httpService
      .get$(`${CONSTANTS_WHEEL_OF_FORTUNE.ENDPOINTS.WHEEL_CAMPAIGN_CONFIGURATION}/${campaignVenueId}/${responseId}/wheel-spin`)
      .pipe(
        catchError(error => {
          if (error.status === CONSTANTS_ERRORS.HTTP_500) {
            this.wheelOfFortuneState$ = new BehaviorSubject(WheelOfFortuneStates.error);
          }
          if (error.status === CONSTANTS_ERRORS.HTTP_403) {
            this.wheelOfFortuneState$ = new BehaviorSubject(WheelOfFortuneStates.blocked);
            this.router.navigate([
              // eslint-disable-next-line
              `${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN}/${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.BLOCKED}`,
            ]);
          }

          return throwError(error);
        })
      );
  }

  setWheelOfFortuneEnable(isEnable: boolean) {
    this.isWheelEnabled = isEnable;
  }

  setWheelOfFortuneBlocked(isBlocked: boolean) {
    this.isWheelBlocked = isBlocked;
  }

  setWheelBody(config: IWheelOfFortune) {
    this.wheelBody = config;
  }

  getWheelBody(): IWheelOfFortune {
    return this.wheelBody;
  }

  getWheelOfFortuneState$(): Observable<WheelOfFortuneStates> {
    return this.wheelOfFortuneState$.pipe(filter(state => state !== null));
  }

  setWheelOfFortuneState(state: WheelOfFortuneStates) {
    this.wheelOfFortuneState$.next(state);
    window.scrollTo(0, 0);
  }

  setWinnerEmail(email: string) {
    this.winnerEmail = email;
  }

  getWinnerEmail() {
    return this.winnerEmail;
  }

  submitPrize$(languageCode: Languages): Observable<any> {
    const voucherToken: string = this.getWheelBody().voucher.token;
    const path = `${CONSTANTS_WHEEL_OF_FORTUNE.ENDPOINTS.VOUCHERS}/${voucherToken}/${CONSTANTS_WHEEL_OF_FORTUNE.ENDPOINTS.ASSIGN_WINNER}`;

    return this.httpService
      .put$(path, {
        languageCode,
        email: this.winnerEmail,
      })
      .pipe(
        catchError(error => {
          this.setWheelOfFortuneState(WheelOfFortuneStates.error);

          return throwError(error);
        })
      );
  }

  setWheelConfig(config: IWheelOfFortuneConfig): void {
    this.wheelConfig = config;
  }

  getWheelConfig(): IWheelOfFortuneConfig {
    return this.wheelConfig;
  }

  // checkAndSetWheelOfFortuneBlockStatus() {
  //   let accountsList: { id: string, expires: string }[] = JSON.parse(localStorage.getItem('accounts'));
  //   const {id} = this.serverEnvironmentService.getServerEnvironmentConfigObject().account;
  //   const twelveHour = 12;
  //   const fourHour = 4;
  //
  // eslint-disable-next-line
  //   if (id) {
  //     if (accountsList) {
  //       const selectedAccount = accountsList.find((account) => account.id === id);
  //
  //       if (selectedAccount) {
  //         const expiresDate: Date = new Date(selectedAccount.expires);
  //         const actualDate: Date = new Date();
  //
  //         if (actualDate.getTime() > expiresDate.getTime()) {
  //           selectedAccount.expires = this.utilsService.getNewDateAfterHours(twelveHour);
  //           this.setWheelOfFortuneBlocked(false);
  //         } else {
  //           this.setWheelOfFortuneBlocked(true);
  //         }
  //       } else {
  //         this.setWheelOfFortuneBlocked(false);
  //         accountsList.push({id, expires: this.utilsService.getNewDateAfterHours(fourHour)});
  //       }
  //     } else {
  //       accountsList = [];
  //       this.setWheelOfFortuneBlocked(false);
  //       accountsList.push({id, expires: this.utilsService.getNewDateAfterHours(fourHour)});
  //     }
  //
  //     localStorage.setItem('accounts', JSON.stringify(accountsList));
  //   }
  // }

  private preventBackButton() {
    this.locationStrategy.onPopState(() => {
      this.setWheelOfFortuneEnable(false);
    });
  }
}
