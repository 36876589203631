<div [formGroup]="surveyForm" class="SurveyFillForm">
  <app-sur-survey-header></app-sur-survey-header>
  <app-sur-survey-statement
    *ngFor="let question of surveyStatements; let i = index"
    [control]="surveyForm.get('statement' + i)"
    [language]="activeLanguage"
    [statement]="question">
  </app-sur-survey-statement>
  <app-sur-survey-comment [control]="surveyForm.get('comment')"></app-sur-survey-comment>
  <div class="SurveyFillFooter">
    <span class="SurveyFillFooter__infoText">{{ "SURVEY.FOOTER" | translate }}</span>
    <app-svg-foodback-disable class="SurveyFillFooter__icon" fill="rgba(146, 146, 146)"></app-svg-foodback-disable>
  </div>
  <button
    (click)="onSubmit()"
    [disabled]="!surveyForm.valid"
    [style.background-color]="getLayoutColor()"
    class="SurveyFillSubmit btn mt-10 mb-20">
    {{ "BUTTON.SEND" | translate }}
  </button>
</div>
