<svg xmlns="http://www.w3.org/2000/svg" width="35pt" height="35pt" viewBox="0 0 35 35">
  <path
    [attr.fill]="fill"
    d="M 29.050781 18.550781 C 28.910156 18.480469 28.421875 18.058594 28.210938 18.128906 C 27.511719 18.199219 26.671875 18.621094 26.179688 18.410156 C 21.070312 16.660156 16.101562 14.910156 11.480469 12.601562 C 10.78125 12.25 10.988281 11.058594 10.640625 10.5 C 9.800781 9.660156 8.121094 10.148438 7.28125 10.988281 C 5.601562 12.601562 5.179688 14.769531 5.25 16.941406 C 5.320312 23.378906 10.429688 30.660156 17.851562 29.261719 C 20.71875 28.699219 22.960938 27.789062 25.339844 26.390625 C 27.089844 25.339844 28.351562 23.589844 29.050781 22.050781 C 29.679688 21.210938 30.238281 19.53125 29.050781 18.550781 M 14.910156 25.621094 C 12.25 25.410156 10.710938 22.960938 9.308594 20.789062 C 8.609375 19.671875 7.910156 17.359375 8.960938 15.960938 C 9.171875 15.609375 22.328125 21.28125 23.660156 21.910156 C 21.839844 24.921875 17.921875 25.898438 14.910156 25.621094 " />
  <path
    [attr.fill]="fill"
    d="M 17.21875 8.609375 C 16.800781 9.800781 15.46875 10.429688 14.28125 10.011719 C 13.089844 9.589844 12.460938 8.261719 12.878906 7.070312 C 13.300781 5.878906 14.628906 5.25 15.820312 5.671875 C 17.078125 6.089844 17.640625 7.421875 17.21875 8.609375 " />
  <path
    [attr.fill]="fill"
    d="M 28.070312 13.230469 C 27.648438 14.421875 26.320312 15.050781 25.128906 14.628906 C 23.941406 14.210938 23.308594 12.878906 23.730469 11.691406 C 24.148438 10.5 25.480469 9.871094 26.671875 10.289062 C 27.929688 10.710938 28.488281 12.039062 28.070312 13.230469 " />
</svg>
