export const CONSTANTS_ERRORS = {
  HTTP_400: 400,
  HTTP_403: 403,
  HTTP_404: 404,
  HTTP_409: 409,
  HTTP_410: 410,
  HTTP_412: 412,
  HTTP_429: 429,
  HTTP_500: 500,
};
