<div class="QrLanding-container">
  <h2>{{ "QR_LANDING.HEADER" | translate }}</h2>
  <div *ngIf="isLoading" class="QrLanding-loader" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner #spinnerRef></mat-spinner>
  </div>
  <div class="QrLanding-itemList mt-20" *ngIf="mainItems.length > 0">
    <ng-container *ngFor="let item of mainItems; let i = index">
      <a fxLayout="row" fxLayoutAlign="space-between center" *ngIf="item.isActive" (click)="goToPage(item)">
        <div class="QrLanding-itemMediumGray" fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
          <div class="QrLanding-itemIconContainer QrLanding-itemComponent" fxLayout="row" fxLayoutAlign="center center">
            <i class="QrLanding-itemIcon">
              <mat-icon [svgIcon]="item.icon"></mat-icon>
            </i>
          </div>
          <div
            class="QrLanding-itemDesc QrLanding-borderBottom QrLanding-itemComponent"
            fxLayout="column"
            fxLayoutAlign="center start"
            fxFlex="grow">
            <span><b>{{ item.name | translate }}</b></span>
            <span class="QrLanding-itemSubDesc" *ngIf="item.desc">{{ item.desc | translate }}</span>
          </div>
        </div>
        <div class="QrLanding-borderBottom QrLanding-itemComponent" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="QrLanding-redirectionIcon" [svgIcon]="'keyboard_arrow_right'"></mat-icon>
        </div>
      </a>
    </ng-container>
  </div>
  <div class="QrLanding-listHeader mt-30 mb-5" fxLayout="row">
    <span>{{ "QR_LANDING.20_SEC_FEEDBACK" | translate }}</span>
  </div>
  <div class="QrLanding-itemList" [ngClass]="{ 'QrLanding-blocked': isFeedbackBlocked }">
    <a fxLayout="row" fxLayoutAlign="space-between center" (click)="goToSurvey()">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
        <div class="QrLanding-itemIconContainer QrLanding-borderBottom QrLanding-itemComponent">
          <i class="QrLanding-itemIcon QrLanding-itemColorRed" [style.background-color]="colour">
            <mat-icon [svgIcon]="isFeedbackBlocked ? 'check' : 'foodback'"></mat-icon>
          </i>
        </div>
        <div
          class="QrLanding-itemDesc QrLanding-borderBottom QrLanding-itemComponent"
          fxLayout="column"
          fxLayoutAlign="center start"
          fxFlex="grow">
          <span [ngClass]="{ 'QrLanding-dimmedText': isFeedbackBlocked }"
            ><b>{{ (isFeedbackBlocked ? "QR_LANDING.FEEDBACK_GIVEN" : "QR_LANDING.FEEDBACK" ) | translate }}</b></span
          >
          <span class="QrLanding-itemSubDesc"
            >{{ (isFeedbackBlocked ? "PAGES.THANKS_PAGE.HEADER" : isWheelEnabled ? "QR_LANDING.FEEDBACK_DESC" :
            "QR_LANDING.FEEDBACK_DESC_NEUTRAL" ) | translate }}</span
          >
        </div>
      </div>
      <mat-icon
        *ngIf="!isFeedbackBlocked"
        class="QrLanding-redirectionIcon QrLanding-borderBottom QrLanding-itemComponent"
        [svgIcon]="'keyboard_arrow_right'"></mat-icon>
    </a>
  </div>
  <div class="QrLanding-listHeader mt-30 mb-5" fxLayout="row" *ngIf="optionalItems.length > 0">
    <span>{{ "QR_LANDING.OTHER_USEFULL_LINKS" | translate }}</span>
  </div>
  <div class="QrLanding-itemList" *ngIf="optionalItems.length > 0">
    <ng-container *ngFor="let item of optionalItems; let i = index">
      <a fxLayout="row" fxLayoutAlign="space-between center" *ngIf="item.isActive" (click)="goToPage(item)">
        <div class="QrLanding-itemMediumGray" fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
          <div class="QrLanding-itemIconContainer QrLanding-small pl-10 pr-10" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon [svgIcon]="item.icon"></mat-icon>
          </div>
          <div
            class="QrLanding-itemDesc QrLanding-small QrLanding-borderBottom"
            fxLayout="column"
            fxLayoutAlign="center start"
            fxFlex="grow">
            <span>{{ item.name | translate }}</span>
          </div>
        </div>
        <div class="QrLanding-small QrLanding-borderBottom" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="QrLanding-redirectionIcon QrLanding-smallRedirectionIcon" [svgIcon]="'keyboard_arrow_right'"></mat-icon>
        </div>
      </a>
    </ng-container>
  </div>
</div>
