import { EmptyPageComponent } from '../../shared/components/pages';
import { Routes } from '@angular/router';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from './wheel-of-fortune-routing.constants';
import { WheelOfFortuneSpinComponent } from './wheel-of-fortune-spin/wheel-of-fortune-spin.component';
import { WheelOfFortuneWinnerPrizeComponent, WheelOfFortuneWinnerSummaryComponent } from './wheel-of-fortune-winner';
import { WheelOfFotuneBlockedComponent } from './wheel-of-fotune-blocked/wheel-of-fotune-blocked.component';

export const WHEEL_OF_FORTUNE_ROUTES: Routes = [
  {
    path: WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN,
    children: [
      {
        path: '',
        component: WheelOfFortuneSpinComponent,
        pathMatch: 'full',
        loadChildren: () => import('./wheel-of-fortune.module').then(m => m.WheelOfFortuneModule),
      },
      {
        path: WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.WINNER,
        component: WheelOfFortuneWinnerPrizeComponent,
        pathMatch: 'full',
        loadChildren: () => import('./wheel-of-fortune.module').then(m => m.WheelOfFortuneModule),
      },
      // {
      //   path: WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.TERMS,
      //   component: WheelOfFortuneWinnerPrivacyComponent,
      //   pathMatch: 'full',
      // },
      {
        path: WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.SUMMARY,
        component: WheelOfFortuneWinnerSummaryComponent,
        pathMatch: 'full',
        loadChildren: () => import('./wheel-of-fortune.module').then(m => m.WheelOfFortuneModule),
      },
      {
        path: WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.BLOCKED,
        component: WheelOfFotuneBlockedComponent,
        pathMatch: 'full',
        loadChildren: () => import('./wheel-of-fortune.module').then(m => m.WheelOfFortuneModule),
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
