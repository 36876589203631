import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { CampaignService } from './campaign.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FilesService } from '../advertisement/files.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ICampaign, ICampaignFormValue, ICampaignQuestion, ICampaignResponse } from './interfaces/campaign.interface';
import { IGeneralVenueInfo, IQuestionPayload } from '../../core/interfaces/general.interface';
import { ILanguage } from '../../common/interfaces/language.interface';
import { IWheelOfFortuneConfig } from '../wheel-of-fortune/interfaces/wheel-of-fortune.interface';
import { LanguageService } from '../../core/providers/language/language.service';
import { Languages } from '../../common/enums/languages.enum';
import { LayoutService } from '../../core/providers/layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit, OnDestroy {
  campaignForm: FormGroup;
  currentIndex$: BehaviorSubject<number>;
  title: string;
  subTitle: string;
  showCampaign = false;
  welcomeImageSrc: string;
  isLoading = true;
  response: ICampaignResponse = this.activatedRoute.snapshot.data.response;
  campaign: ICampaign = this.activatedRoute.snapshot.data.response.campaign;
  private onDestroy$: Subject<void> = new Subject<void>();
  private venueUuid: string = this.activatedRoute.snapshot.params.venueId;
  private activeLang: Languages;
  private info: IGeneralVenueInfo = this.activatedRoute.snapshot.data.response.info;
  private wheel: IWheelOfFortuneConfig = this.activatedRoute.snapshot.data.response.info.wheel;

  constructor(
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private campaignService: CampaignService,
    private languageService: LanguageService,
    private filesService: FilesService,
    private translateService: TranslateService,
    private router: Router,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.handleLanguageChanges();
    this.createForm();
    this.setCampaignParams();
    this.handleParamsChanges();
    this.setDataFromStorage();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  createForm(): void {
    this.campaignForm = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    });
    this.campaign.questions.forEach((question: ICampaignQuestion) => {
      const questionControl: FormControl = new FormControl(question);

      (this.campaignForm.controls.questions as FormArray).push(questionControl);
    });
  }

  trackItems(item: FormGroup, index: number): number {
    return index;
  }

  onFinishBtnClicked(): void {
    this.isLoading = true;
    const selectedAnswerIndexes: number[] = [...this.campaignService.previousQuestionIndexes, this.campaignService.currentIndex];
    const answers: IQuestionPayload[] = selectedAnswerIndexes
      .map(i => this.campaignForm.value.questions[i])
      .filter((question: ICampaignQuestion, index: number) => question.payload)
      .map((question: ICampaignQuestion) => question.payload);

    this.campaignService
      .saveCampaign(this.campaignService.getCampaignPayload(this.venueUuid, answers, this.activeLang))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: { responseUuid: string }) => {
        this.campaignService.removeStorage(this.campaign.uuid);
        this.campaignService.redirectAfterSave(response.responseUuid);
      });
  }

  onImageLoad(): void {
    this.isLoading = false;
  }

  onSaveFormData(): void {
    this.campaignService.saveCampaignInStorage(this.campaignForm.value, this.campaign.uuid);
  }

  private handleLanguageChanges(): void {
    this.languageService
      .getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((language: Languages) => this.setTranslation(language));
  }

  private setCampaignParams(): void {
    this.campaignService.questionLimitIndex = this.campaign.questions.length;

    if (this.campaign.foodbackPortalToken) {
      this.campaignService.setPortalToken(this.campaign.foodbackPortalToken);
    }

    this.campaignService.setCampaign(this.response);

    if (this.campaign.logoUuid && this.campaign.logoUuid !== '') {
      this.welcomeImageSrc = this.filesService.getUrl(this.campaign.logoUuid);
    } else {
      this.isLoading = false;
    }

    this.showCampaign = this.campaign.questions[0].isInWelcomeScreen;
    // this.showCampaign = true;
    this.setDefaultLanguage(this.info.languages);
    this.setAvailableLanguages(this.info.languages);
    this.layoutService.loadedLayoutData$.next(true);
  }

  private handleParamsChanges(): void {
    this.currentIndex$ = this.campaignService.currentIndex$;
  }

  private setTranslation(language: Languages): void {
    this.activeLang = language;
    this.title = this.languageService.findTranslatedPhraseInLanguage(language, this.campaign.translations.title);
    this.subTitle =
      this.campaign.translations.subtitle && this.campaign.translations.subtitle.length > 0
        ? this.languageService.findTranslatedPhraseInLanguage(language, this.campaign.translations.subtitle)
        : null;
  }

  private setDefaultLanguage(languages: ILanguage[]): void {
    const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(languages);

    if (defaultLanguage) {
      this.languageService.setAppLanguage(defaultLanguage.code);
    }
    if (defaultLanguage) {
      this.translateService.onLangChange.pipe(take(1)).subscribe(() => {
        this.setLanguage(defaultLanguage.code);
        this.languageService.setAppLanguage(defaultLanguage.code);
      });
    }

    this.cdf.detectChanges();
  }

  private setLanguage(code: Languages): void {
    this.translateService.onLangChange.pipe(take(1)).subscribe(() => this.cdf.detectChanges());
    this.languageService.setAppLanguage(code);
  }

  private setAvailableLanguages(languages: ILanguage[]): void {
    this.languageService.setAvailableLanguages(languages.map((language: ILanguage) => language.code));
    this.cdf.detectChanges();
  }

  private setDataFromStorage(): void {
    const data: ICampaignFormValue = this.campaignService.getCampaignDataFromStorage(this.campaign.uuid);

    if (data) {
      this.campaignForm.setValue(data);
    }
  }
}
