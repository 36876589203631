import { Component, Input, OnInit } from '@angular/core';
import { IVoucher } from '../voucher.interface';

@Component({
  selector: 'app-voucher-template',
  templateUrl: './voucher-template.component.html',
  styleUrls: ['./voucher-template.component.scss'],
})
export class VoucherTemplateComponent implements OnInit {
  @Input() voucher: IVoucher;

  constructor() {}

  ngOnInit() {}
}
