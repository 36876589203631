import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { ILanguage } from '../../common/interfaces/language.interface';
import { IVoucherBody } from './interfaces/voucher.interface';
import { IVoucherBranding } from './interfaces/voucher-branding.interface';
import { IVoucherLanguage } from './interfaces/vouher-language.interface';
import { Injectable } from '@angular/core';
import { LanguageService } from '../../core/providers/language/language.service';
import { LayoutService } from '../../core/providers/layout/layout.service';
import { NavigationPaths } from '../../core/providers/navigation/navigation-paths.enum';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { Observable, of, throwError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TokenService } from '../../core/providers/token/token.service';
import { VOUCHER_ROUTING_CONSTANTS } from './voucher-routing.constant';
import { VoucherService } from './voucher.service';
import { VoucherStates } from './voucher.states.enum';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class VoucherResolver implements Resolve<IVoucherBody> {
  private voucherBody: IVoucherBody;

  constructor(
    private voucherService: VoucherService,
    private router: Router,
    private navigationService: NavigationService,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private titleService: Title,
    private tokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IVoucherBody> {
    return this.voucherService.fetchVoucher$(route.params.id).pipe(
      tap(voucherBody => {
        this.tokenService.setToken(route.params.id);
        const voucher = this.voucherService.getVoucher();

        if (!voucher || voucher.token !== route.params.id) {
          this.setVoucher(voucherBody);
          this.initialVoucher();
        }
        if (this.voucherService.voucherState !== this.getVoucherStatusByRoute(route.routeConfig.path)) {
          this.voucherService.redirectAccordingToState();
        }
      }),
      catchError(error => {
        switch (error.status) {
          case CONSTANTS_ERRORS.HTTP_404: {
            this.voucherService.setVoucherState(VoucherStates.notFound);
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.NOT_FOUND]);

            return of(null);
          }
          case CONSTANTS_ERRORS.HTTP_500: {
            this.voucherService.setVoucherState(VoucherStates.error);
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);

            return of(null);
          }
          default:
            break;
        }

        this.navigationService.navigateTo$(NavigationPaths.home).subscribe();

        return throwError(error);
      })
    );
  }

  private initialVoucher() {
    if (this.voucherBody) {
      this.setDefaultLanguage();
      this.setAvailableLanguages();
      this.setLayoutCustomSettings();
    }

    this.layoutService.loadedLayoutData$.next(true);
  }

  private setVoucher(voucherBody: IVoucherBody) {
    if (voucherBody) {
      this.voucherBody = voucherBody;
      this.voucherService.setVoucher(voucherBody.voucher);
      this.voucherService.setVoucherStateAccordingToFlag();
    }
  }

  private setLayoutCustomSettings() {
    this.setAppColour();
    this.setVenueLogo();
    this.setTabName();
    this.layoutService.loadedLayoutData$.next(true);
  }

  private setAppColour() {
    const branding: IVoucherBranding = this.voucherBody.branding;

    if (branding) {
      this.layoutService.setLayoutDefaultColor(branding.colorTint);
    }
  }

  private setVenueLogo() {
    const branding: IVoucherBranding = this.voucherBody.branding;

    this.layoutService.setVenueLogo(branding.logoFileUuid);
  }

  private setDefaultLanguage() {
    const languages: IVoucherLanguage[] = this.voucherBody.languages;
    const surveyLang = this.voucherBody.surveyLang;

    if (surveyLang) {
      this.languageService.setAppLanguage(surveyLang);
    } else if (languages) {
      const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(languages);

      if (defaultLanguage) {
        this.languageService.setAppLanguage(defaultLanguage.code);
      }
    }
  }

  private setAvailableLanguages() {
    this.languageService.setAvailableLanguages(this.voucherBody.languages.map(language => language.code));
  }

  private setTabName() {
    const venueName = this.voucherBody.venueName;
    const tabTitle = venueName ? `${venueName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }

  private getVoucherStatusByRoute(path: string): VoucherStates {
    switch (path) {
      case VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.ACTIVE: {
        return VoucherStates.valid;
      }
      case VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.EXPIRED: {
        return VoucherStates.expired;
      }
      case VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.USED: {
        return VoucherStates.used;
      }
      default:
        return null;
    }
  }
}
