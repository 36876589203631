import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomValidators } from '../../../shared/custom-validators';
import { DialogService } from '../../../shared/components/dialog/dialog.service';
import { FormControl, Validators } from '@angular/forms';
import { GdprPrivacyService } from '../../../core/providers/gdpr-privacy/gdpr-privacy.service';
import { ISurveyContactGuestDto } from '../../survey/interfaces/survey-contact-guest-dto.interface';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Subject } from 'rxjs';
import { SurveyService } from '../../survey/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { WheelOfFortuneService } from '../../wheel-of-fortune/wheel-of-fortune.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contact-ask',
  templateUrl: './contact-ask.component.html',
  styleUrls: ['./contact-ask.component.scss'],
})
export class ContactAskComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput')
  emailInputRef: ElementRef;

  readonly emailFormControl: FormControl = new FormControl({ value: '', disabled: true }, [Validators.required, CustomValidators.email]);
  readonly contactMeFormControl: FormControl = new FormControl(false);
  readonly commentFormControl: FormControl = new FormControl({
    value: '',
    disabled: true,
  });

  private onDestroy$ = new Subject<void>();
  private dialogMargin: number;

  constructor(
    private layoutService: LayoutService,
    private wheelOfFortuneService: WheelOfFortuneService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private surveyService: SurveyService,
    private gdprPrivacyService: GdprPrivacyService
  ) {}

  ngOnInit() {
    this.layoutService.loadedLayoutData$.next(true);

    if (this.surveyService.getSurveyDTO()) {
      this.commentFormControl.setValue(this.surveyService.getSurveyDTO().comment);
    }

    this.contactMeFormControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (value) {
        this.emailFormControl.enable();
        this.commentFormControl.enable();
        this.emailInputRef.nativeElement.focus();
      } else {
        this.emailFormControl.disable();
        this.commentFormControl.disable();
      }
    });
  }

  cancel() {
    this.surveyService.redirectAfterSave();
  }

  submit() {
    this.dialogService
      .confirmDialog({
        headerText: this.translateService.instant('VALIDATION_MSG.EMAIL_CORRECT'),
        contentText: this.emailFormControl.value,
        confirmBtnText: this.translateService.instant('BUTTON.YES'),
        cancelBtnText: this.translateService.instant('BUTTON.NO_GO_BACK'),
        topPosition: `${this.dialogMargin}px`,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
          this.sendMessage();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private sendMessage() {
    const payload: ISurveyContactGuestDto = {
      email: this.emailFormControl.value,
      token: this.surveyService.getSurveyId(),
      comment: this.commentFormControl.value,
      isContactAllowed: this.contactMeFormControl.value,
    };

    this.wheelOfFortuneService.setWinnerEmail(this.emailFormControl.value);
    this.surveyService.setSurveyContactGuest(payload);
    this.acceptTerms();
  }

  private acceptTerms() {
    this.gdprPrivacyService.acceptGdpr();
    this.saveSurveyContactGuestAndRedirect(true);
  }

  private saveSurveyContactGuestAndRedirect(isEmail: boolean) {
    const surveyContactGuestDto = this.surveyService.getSurveyContactGuest();

    if (surveyContactGuestDto) {
      surveyContactGuestDto.email = isEmail ? surveyContactGuestDto.email : null;
      this.surveyService.saveSurveyContactGuestAndRedirect(surveyContactGuestDto);
    }
  }
}
