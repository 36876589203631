<app-basic-page headerText="PAGES.VENUE_CLOSED_PAGE.HEADER" message="PAGES.VENUE_CLOSED_PAGE.MESSAGE">
  <div class="mt-20 mb-20 ClosePage_container">
    <svg viewBox="0 0 100 100">
      <rect width="100" height="100" style="fill: rgb(255, 255, 255)" />
    </svg>
    <img
      class="ClosePage_advertisement"
      [ngClass]="{ 'fade-in': isImageLoaded, FinalPage_advertisemen: getUrl() }"
      (load)="onImageLoad()"
      [src]="advUrl"
      (click)="goTotAdvertisementUrl()"
      (error)="onImageError()"
      *ngIf="advUrl !== ''" />
  </div>
</app-basic-page>
