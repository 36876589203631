import { Injectable } from '@angular/core';
import { NavigationPaths } from './navigation-paths.enum';
import { Observable, from } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class NavigationService {
  constructor(private router: Router) {}

  navigateTo$(path: NavigationPaths): Observable<boolean> {
    const url: string = {
      HOME: '',
    }[path];

    return from(this.router.navigateByUrl(url));
  }
}
