import { CoreModule } from '../../core.module';
import { GenericErrorHandlerService } from '../generic-error-handler/generic-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { IHttpOptions } from './http-options.interface';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpService {
  private readonly baseURL = environment.backendURL;

  constructor(private http: HttpClient, private genericErrorHandlerService: GenericErrorHandlerService) {}

  static addHttp(url: string): string {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      return `http://${url}`;
    }

    return url;
  }

  get$(url: string, options?: IHttpOptions): Observable<any> {
    return this.http.get(`${this.baseURL}/${url}`, options).pipe(
      catchError(err => {
        this.genericErrorHandlerService.handleApiError(err);

        return throwError(err);
      })
    );
  }

  post$(url: string, data: any, options?: IHttpOptions): Observable<any> {
    return this.http.post(`${this.baseURL}/${url}`, data, options).pipe(
      catchError(err => {
        this.genericErrorHandlerService.handleApiError(err);

        return throwError(err);
      })
    );
  }

  put$(url: string, data: any, options?: IHttpOptions): Observable<any> {
    return this.http.put(`${this.baseURL}/${url}`, data, options).pipe(
      catchError(err => {
        this.genericErrorHandlerService.handleApiError(err);

        return throwError(err);
      })
    );
  }
}
