<app-basic-page headerText="WHEEL_OF_FORTUNE.PAGES.SPIN.HEADER">
  <div class="WheelOfFortuneSpin">
    <div class="WheelOfFortuneSpin__prize" [style.background-color]="getPrizeBgColor()" [style.border-color]="getPrizeBorderColor()">
      {{ getPrizeText() }}
    </div>

    <div class="WheelOfFortuneSpin__wheel mt-20" (click)="spinByTouch()" (touchmove)="spinByTouch()"></div>
    <app-form-buttons
      submitTranslationKey="WHEEL_OF_FORTUNE.PAGES.SPIN.BUTTONS.SPIN"
      cancelTranslationKey="BUTTON.NO_THANKS"
      [isDisabled]="wheelSpinning"
      (onSubmit)="spin()"
      (onCancel)="cancelWheelOfFortune()"></app-form-buttons>
  </div>
</app-basic-page>
