import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS } from '../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { FingerprintService } from '../../core/providers/fingerprint/fingerprint.service';
import { ISurvey } from './interfaces/survey.interface';
import { Injectable, NgZone } from '@angular/core';
import { NavigationPaths } from '../../core/providers/navigation/navigation-paths.enum';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { Observable, of, throwError } from 'rxjs';
import { SurveyService } from './survey.service';
import { SurveyStates } from './survey-states.enum';
import { TokenService } from '../../core/providers/token/token.service';
import { catchError, first, flatMap, tap } from 'rxjs/operators';

@Injectable()
export class SurveyResolver implements Resolve<ISurvey> {
  constructor(
    private surveyService: SurveyService,
    private router: Router,
    private navigationService: NavigationService,
    private tokenService: TokenService,
    private fingerprintService: FingerprintService,
    private zone: NgZone
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ISurvey> {
    return this.fingerprintService.getFingerprint$().pipe(
      first(),
      flatMap((fingerprint: any) => this.fetchSurvey$(route, fingerprint.visitorId))
    );
  }

  private fetchSurvey$(route: ActivatedRouteSnapshot, visitorId: string): Observable<ISurvey> {
    return this.surveyService.fetchSurvey$(route.params.id, visitorId).pipe(
      tap((res: ISurvey) => {
        this.tokenService.setToken(route.params.id);

        if (res.portalToken) {
          localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.PORTAL_TOKEN, res.portalToken);
        } else if (localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.PORTAL_TOKEN)) {
          localStorage.removeItem(CONSTANTS.LOCALSTORAGE_KEYS.PORTAL_TOKEN);
        }

        // this.surveyService.setSurveyState(SurveyStates.inProgress);
      }),
      catchError((error: any) => {
        switch (error.status) {
          case CONSTANTS_ERRORS.HTTP_404: {
            this.surveyService.setSurveyState(SurveyStates.notFound);
            this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.NOT_FOUND]));

            return of(null);
          }
          case CONSTANTS_ERRORS.HTTP_409: {
            this.surveyService.setSurveyState(SurveyStates.answered);

            return of(error.error);
          }
          case CONSTANTS_ERRORS.HTTP_410: {
            this.surveyService.setSurveyState(SurveyStates.expired);

            return of(error.error);
          }
          case CONSTANTS_ERRORS.HTTP_412:
          case CONSTANTS_ERRORS.HTTP_403: {
            this.surveyService.setSurveyState(SurveyStates.back);

            return of(error.error);
          }
          case CONSTANTS_ERRORS.HTTP_500: {
            this.surveyService.setSurveyState(SurveyStates.error);
            this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);

            return of(null);
          }
          default:
            break;
        }

        this.navigationService.navigateTo$(NavigationPaths.home).subscribe();

        return throwError(error);
      })
    );
  }
}
