import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  isImageLoaded = false;
  logoUrl = '';
  private subscriptions: Subscription = new Subscription();

  constructor(private layoutService: LayoutService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.layoutService.activeLayoutColor$.subscribe(() => {
        this.ref.detectChanges();
      })
    );
    this.subscriptions.add(
      this.layoutService.activeLayoutLogoURL$.subscribe(() => {
        this.logoUrl = this.getLogoUrl();
        this.ref.detectChanges();
      })
    );
  }

  getLayoutColor(): string {
    return this.layoutService.useLayoutColor();
  }

  getLogoUrl(): string {
    return this.layoutService.getLogoLayoutUrl();
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
    this.layoutService.loadedLogo$.next(true);
  }

  onImageError(): void {
    this.logoUrl = this.layoutService.getDefaultLogoUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
