import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FingerprintService } from './providers/fingerprint/fingerprint.service';
import { GdprPrivacyService } from './providers/gdpr-privacy/gdpr-privacy.service';
import { GenericErrorHandlerService } from './providers/generic-error-handler/generic-error-handler.service';
import { GoogleAnalyticsService } from './providers/google-analytics/google-analytics.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LanguageService } from './providers/language/language.service';
import { LayoutService } from './providers/layout/layout.service';
import { NavigationService } from './providers/navigation/navigation.service';
import { NgModule } from '@angular/core';
import { NotificationService } from './providers/notification/notification.service';
import { ServerEnvironmentService } from './providers/server-envionment/server-environment.service';
import { SurveyFormService } from './providers/survey-form/survey-form.service';
import { TokenService } from './providers/token/token.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLoaderFactory } from './providers/translations/app.translations';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    LanguageService,
    NavigationService,
    NotificationService,
    LayoutService,
    ServerEnvironmentService,
    GoogleAnalyticsService,
    TokenService,
    GdprPrivacyService,
    FingerprintService,
    GenericErrorHandlerService,
    SurveyFormService,
  ],
  exports: [HttpClientModule],
})
export class CoreModule {}
