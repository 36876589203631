import { IBranding } from '../../common/interfaces/branding.interface';
import { ILanguage } from '../../common/interfaces/language.interface';

export interface IQrLandingSettingsItem {
  isActive: boolean;
  icon: string;
  value: string;
  name: string;
  internalId: QrLandingOptionsInternalIds;
  desc?: string;
}
export interface IQrLandingSettings {
  isActive: boolean;
  isWheelEnabled: boolean;
  isAllowedToFillSurvey: boolean;
  isPortalClosedWhenVenueClosed: boolean;
  isOpen: boolean;
  rows: IQrLandingSettingsRow[];
  options: IQrLandingOption[];
  branding: IBranding;
  languages: ILanguage[];
  venueName: string;
  venueUuid: string;
}
export interface IQrLandingOption {
  icon: string;
  isEditable: boolean;
  isLocked: boolean;
  name: string;
  position: number;
  section: QrLandingSectionEnum;
  uuid: string;
  internalId: QrLandingOptionsInternalIds;
}
export enum QrLandingSectionEnum {
  main = 'main',
  optional = 'optional',
}
export interface IQrLandingSettingsRow {
  isActive: boolean;
  optionUuid: string;
  value: string;
}
export enum QrLandingOptionsInternalIds {
  checkinInternal = 'check-in-foodback',
  checkinExternal = 'check-in-external',
  order = 'order',
  menu = 'menu',
  feedback = 'feedback',
  covid = 'covid',
  taxi = 'taxi',
}
export interface ICheckInPayload {
  venueUuid: string;
  fullName: string;
  phone: IPhone;
  numberOfChildren: string;
  tableNumber: string;
  token?: string;
}
export interface IPhone {
  prefix: string;
  number: string;
}
