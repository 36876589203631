<aside class="dialog">
  <mat-toolbar *ngIf="dialogTitle.length">
    <h2 class="dialog__title">
      <ng-content select="app-dialog-title"></ng-content>
    </h2>
    <button type="button" class="close" aria-label="Close" matDialogClose>
      <span aria-hidden="true">&times;</span>
    </button>
  </mat-toolbar>
  <mat-dialog-content *ngIf="dialogContent.length">
    <ng-content select="app-dialog-content"></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="dialogActions.length">
    <ng-content select="app-dialog-actions"></ng-content>
  </mat-dialog-actions>
</aside>
