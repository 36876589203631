<app-basic-page [headerText]="'QR_LANDING.CHECK_IN' | translate" [message]="'QR_LANDING.CHECK_IN_MSG' | translate">
  <form class="mt-20" fxLayout="column" fxLayoutGap="20px" [formGroup]="checkInForm" fxFlex="grow">
    <div fxLayout="row" fxLayoutGap="10px" fxFlex="grow">
      <div class="QrLandingCheckIn-formControl" fxLayout="column" fxLayoutAlign="start start" fxFlex="grow">
        <div class="QrLandingCheckIn-formControlHeader QrLandingCheckIn-required">
          <span>{{ "SYSTEM.NAME" | translate }}</span>
        </div>
        <input type="text" [placeholder]="'SYSTEM.NAME_PLACEHOLDER' | translate" formControlName="name" class="inp" fxFlex="grow" />
      </div>
      <div class="QrLandingCheckIn-formControl QrLandingCheckIn-children" fxLayout="column" fxLayoutAlign="start start">
        <div class="QrLandingCheckIn-formControlHeader">
          <span>{{ "SYSTEM.CHILDREN" | translate }}</span>
        </div>
        <label>
          <select required formControlName="children">
            <option *ngFor="let child of children" [value]="child">{{ child }}</option>
          </select>
        </label>
      </div>
    </div>
    <div class="QrLandingCheckIn-formControlHeader QrLandingCheckIn-phoneControl QrLandingCheckIn-required">
      <span>{{ "SYSTEM.MOBILE_PHONE" | translate }}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxFlex="grow">
      <div class="QrLandingCheckIn-formControl" fxLayout="column" fxLayoutAlign="start start">
        <div class="QrLandingCheckIn-codeListContainer">
          <div
            class="QrLandingCheckIn-countryCode QrLandingCheckIn-inputBorder"
            [ngClass]="{ InputSelected: isCountryCodesListVisible }"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            appOutsideClick
            (outsideClick)="closeSelect()"
            (click)="onListClick()">
            <img
              [src]="
                'assets/img/all-flags/' + selectedCountryCode.icon + '.svg'
              "
              class="QrLandingCheckIn-flag" />
            <span class="mr-10">{{ selectedCountryCode.dialCode }}</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
          <div class="QrLandingCheckIn-codeList" *ngIf="isCountryCodesListVisible" #countryCodeListRef>
            <div
              class="QrLandingCheckIn-countryCode"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              *ngFor="
                let countryCode of countryPhoneCodes;
                trackBy: trackByCountryCode
              "
              [ngClass]="{
                'QrLandingCheckIn-countryCodeSelected':
                  countryCode.dialCode === selectedCountryCode.dialCode &&
                  countryCode.icon === selectedCountryCode.icon
              }"
              (click)="selectCountryCode(countryCode)">
              <img [src]="'assets/img/all-flags/' + countryCode.icon + '.svg'" class="QrLandingCheckIn-flag" />
              <span class="mr-10">{{ countryCode.dialCode }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="QrLandingCheckIn-formControl" fxLayout="column" fxLayoutAlign="end start" fxFlex="grow">
        <input type="tel" [placeholder]="'SYSTEM.MOBILE_NUMBER_PLACEHOLDER' | translate" formControlName="mobile" class="inp" />
      </div>
    </div>
    <div class="QrLandingCheckIn-formControl" fxLayout="column" fxLayoutAlign="start start">
      <div class="QrLandingCheckIn-formControlHeader">
        <div class="QrLandingCheckIn-customHeader" [innerHTML]="'SYSTEM.TABLE_NO' | translate"></div>
      </div>
      <input
        type="text"
        [placeholder]="'SYSTEM.TABLE_NO_PLACEHOLDER' | translate"
        formControlName="table"
        inputmode="numeric"
        class="inp"
        fxFlex="grow" />
    </div>
    <span class="QrLandingCheckIn-infoMessage" [innerHTML]="'QR_LANDING.COVID_INFO_GDOR' | translate"></span>
    <mat-checkbox [formControl]="rememberMe">{{ "SYSTEM.REMEMBER_ME" | translate }}</mat-checkbox>
    <app-form-buttons
      submitTranslationKey="SYSTEM.CHECK_IN"
      cancelTranslationKey="BUTTON.NO_GO_BACK"
      [isDisabled]="checkInForm.invalid"
      (onSubmit)="submit(checkInForm.value)"
      (onCancel)="goToPortal()"></app-form-buttons>
  </form>
</app-basic-page>
