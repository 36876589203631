import { ErrorPageComponent } from '../error-pages/components';
import { Routes } from '@angular/router';
import { VOUCHER_ROUTING_CONSTANTS } from './voucher-routing.constant';
import { VoucherComponent } from './_voucher/voucher.component';
import { VoucherExpiredComponent } from './voucher-expired/voucher-expired.component';
import { VoucherResolver } from './voucher.resolver';
import { VoucherUsedComponent } from './voucher-used/voucher-used.component';
import { VoucherValidComponent } from './voucher-valid/voucher-valid.component';

export const VOUCHER_ROUTES: Routes = [
  {
    path: VOUCHER_ROUTING_CONSTANTS.ROUTES.MAIN,
    children: [
      {
        path: '',
        component: VoucherComponent,
        resolve: {
          voucher: VoucherResolver,
        },
        pathMatch: 'full',
        loadChildren: () => import('./voucher.module').then(m => m.VoucherModule),
      },
      {
        path: VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.ACTIVE,
        component: VoucherValidComponent,
        resolve: {
          voucher: VoucherResolver,
        },
        pathMatch: 'full',
        loadChildren: () => import('./voucher.module').then(m => m.VoucherModule),
      },
      {
        path: VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.USED,
        component: VoucherUsedComponent,
        resolve: {
          voucher: VoucherResolver,
        },
        pathMatch: 'full',
        loadChildren: () => import('./voucher.module').then(m => m.VoucherModule),
      },
      {
        path: VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES.EXPIRED,
        component: VoucherExpiredComponent,
        resolve: {
          voucher: VoucherResolver,
        },
        pathMatch: 'full',
        loadChildren: () => import('./voucher.module').then(m => m.VoucherModule),
      },
      {
        path: '**',
        component: ErrorPageComponent,
      },
    ],
  },
];
