import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  backendURL: 'https://api.ate.devfoodback.com/api/v1',
  production: false,
  fingerprintEndpoint: 'https://api.fpjs.io/',
  fingerprintToken: 'AZb649INhm8d7EgjN1ZK',
  translationPath: './assets/i18n',
  buildNumber: 'BUILD_NUMBER',
};
