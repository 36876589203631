import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-add-back-already',
  templateUrl: './ad-back-already.component.html',
})
export class AdBackAlreadyComponent implements OnInit {
  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    this.layoutService.loadedLayoutData$.next(true);
  }
}
