import { CAMPAIGN } from './campaign.constants';
import { CampaignComponent } from './campaign.component';
import { CampaignResolver } from './campaign.resolver';
import { EmptyPageComponent } from '../../shared/components';
import { Routes } from '@angular/router';

export const CAMPAIGN_ROUTES: Routes = [
  {
    path: CAMPAIGN.ROUTES.FORM,
    children: [
      {
        path: '',
        component: CampaignComponent,
        pathMatch: 'prefix',
        loadChildren: () => import('./campaign.module').then(m => m.CampaignModule),
        resolve: {
          response: CampaignResolver,
        },
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
