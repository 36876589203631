<app-dialog>
  <app-dialog-content class="CheckInDialog">
    <ng-container *ngIf="!isLoaded; else isLoadedContent">
      <h2>
        <b>{{ "SYSTEM.IS_EVERYTHING_CORRECT" | translate }}</b>
      </h2>
      <div class="mt-20" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px" *ngIf="data?.payload">
        <div>
          <span class="mr-5"><b>{{ "SYSTEM.NAME" | translate }}:</b></span>
          <span>{{ data.payload.fullName }}</span>
        </div>
        <div>
          <span class="mr-5"><b>{{ "SYSTEM.CHILDREN" | translate }}:</b></span>
          <span>{{ data.payload.numberOfChildren }}</span>
        </div>
        <div>
          <span class="mr-5"><b>{{ "SYSTEM.MOBILE" | translate }}:</b></span>
          <span>{{ data.payload.phone.prefix }} {{ data.payload.phone.number }}</span>
        </div>
        <div>
          <span class="mr-5"><b>{{ "SYSTEM.TABLE" | translate }}:</b></span>
          <span>{{ data.payload.tableNumber }}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #isLoadedContent>
      <div class="CheckInDialog__successMessage" fxLayout="row" fxLayoutAlign="center end">
        <h1>{{ "SYSTEM.SUCCESSFULLY_CHECK_IN" | translate }}</h1>
      </div>
    </ng-template>
  </app-dialog-content>
  <app-dialog-actions>
    <ng-container *ngIf="!isLoaded; else isLoadedCheck">
      <app-form-buttons
        *ngIf="!isLoading; else spinner"
        [submitTranslationKey]="data.confirmBtnText"
        [cancelTranslationKey]="data.cancelBtnText"
        (onSubmit)="exportData()"
        (onCancel)="hideExportModal()"></app-form-buttons>
    </ng-container>
    <ng-template #spinner>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner diameter="50" #spinnerRef></mat-spinner>
      </div>
    </ng-template>
    <ng-template #isLoadedCheck>
      <mat-icon class="CheckInDialog__successIcon">check_circle</mat-icon>
    </ng-template>
  </app-dialog-actions>
</app-dialog>
