<app-basic-page headerText="ROOM.PAGES.GET_NUMBER.HEADER" message="ROOM.PAGES.GET_NUMBER.MESSAGE">
  <div>
    <div class="RoomNumber-number">
      <input
        [formControl]="roomNumberControl"
        [placeholder]="'ROOM.PAGES.GET_NUMBER.PLACEHOLDER' | translate"
        appAutocomplete
        class="inp mt-20" />
    </div>
    <app-button-menu-choice
      (onCancel)="cancel()"
      (onSubmit)="submit()"
      cancelText="BUTTON.SKIP"
      submitText="BUTTON.SEND"
      [submitDisabled]="!roomNumberControl.valid"
      class="mt-20 mb-20"></app-button-menu-choice>
  </div>
</app-basic-page>
