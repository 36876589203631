import { EmptyPageComponent } from '../../shared/components/pages';
import { ErrorPageComponent } from '../error-pages/components';
import { ROOM_ROUTING_CONSTANTS } from './room-routing.constants';
import { RoomAskComponent } from './room-ask/room-ask.component';
import { RoomGuard } from './room.guard';
import { RoomNumberComponent } from './room-number/room-number.component';
import { Routes } from '@angular/router';

export const ROOM_ROUTES: Routes = [
  {
    path: ROOM_ROUTING_CONSTANTS.ROUTES.MAIN,
    children: [
      {
        path: '',
        component: ErrorPageComponent,
        pathMatch: 'full',
        loadChildren: () => import('../error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: ROOM_ROUTING_CONSTANTS.ROUTES.STATUSES.ASK,
        component: RoomAskComponent,
        canActivate: [RoomGuard],
        pathMatch: 'full',
        loadChildren: () => import('./room.module').then(m => m.RoomModule),
      },
      {
        path: ROOM_ROUTING_CONSTANTS.ROUTES.STATUSES.ROOM_NUMBER,
        component: RoomNumberComponent,
        canActivate: [RoomGuard],
        pathMatch: 'full',
        loadChildren: () => import('./room.module').then(m => m.RoomModule),
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
