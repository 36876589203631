import { Component, Input } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent {
  @Input() headerText = 'PAGES.FORBIDDEN_PAGE.HEADER';
  @Input() message = 'PAGES.FORBIDDEN_PAGE.MESSAGE';
  @Input() iconFilePath: string = null;
  @Input() iconMaterial: string = null;

  constructor(public layoutService: LayoutService) {}
}
