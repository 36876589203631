import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements AfterViewInit {
  @ViewChild('spinnerRef')
  spinnerRef: any;

  constructor(private layoutService: LayoutService) {}

  ngAfterViewInit(): void {
    if (this.spinnerRef && this.spinnerRef._elementRef) {
      this.spinnerRef._elementRef.nativeElement.children[0].children[0].style.stroke = this.layoutService.getDefaultColor();
    }
  }
}
