import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ServerEnvironmentService } from '../server-envionment/server-environment.service';
import { Subject } from 'rxjs';
import { VOUCHER_ROUTING_CONSTANTS } from '../../../components/voucher/voucher-routing.constant';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from '../../../components/wheel-of-fortune/wheel-of-fortune-routing.constants';
import { takeUntil } from 'rxjs/operators';

declare let ga: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements OnDestroy {
  private hasGAScriptBeenInjected = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private serverEnvironment: ServerEnvironmentService,
    public router: Router,
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  injectGAScript(token: string): void {
    // eslint-disable-next-line
    if (this.hasGAScriptBeenInjected) {
      this.warnAboutDoubleInjection();
    } else {
      this.serverEnvironment
        .getServerEnvironmentConfig$(token)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(serverEnvironment => {
          this.createAndInjectGAScriptNode(serverEnvironment.google.googleAnalyticsId);
        });
    }
  }

  createAndInjectGAScriptNode(googleAnalyticsId: string): void {
    if (!googleAnalyticsId) {
      // this might happen on dev/QA environments
      return;
    }
    const tagContents = `
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', '${googleAnalyticsId}', 'auto');

    `;
    const tag = document.createElement('script');

    tag.innerHTML = tagContents;
    this.document.getElementsByTagName('script')[0].parentElement.appendChild(tag);
    this.hasGAScriptBeenInjected = true;
    this.sendPageView(this.router.url);
    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sendPageView(event.urlAfterRedirects);
      }
    });
  }

  sendPageView(url: string) {
    ga('set', 'page', this.getUrlWithoutID(url));
    ga('send', 'pageview');
  }

  getUrlWithoutID(url: string): string {
    const splittetUrlLength = 3;
    const pathSplittedLength = 2;
    const splittedUrlBySlash: string[] = url.split('/');

    if (splittedUrlBySlash.length < splittetUrlLength) {
      return url;
    }
    let resultParts: string[] = [];

    const pathsObject = {
      ...VOUCHER_ROUTING_CONSTANTS.ROUTES.STATUSES,
      ...WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES,
    };
    const routingPaths: string[] = Object.values(pathsObject).map(path => {
      const pathAfterSplit = path.split('/');

      return pathAfterSplit.length === pathSplittedLength ? pathAfterSplit[1] : pathAfterSplit[0];
    });

    if (splittedUrlBySlash.find(part => part === VOUCHER_ROUTING_CONSTANTS.ROUTES.VOUCHER)) {
      splittedUrlBySlash.forEach(part => {
        const selectedPath: string = routingPaths.find(routingPath => routingPath === part);

        if (part === '' || part === VOUCHER_ROUTING_CONSTANTS.ROUTES.VOUCHER || selectedPath) {
          resultParts.push(part);
        }
      });
    } else {
      const lastPart: string = splittedUrlBySlash[splittedUrlBySlash.length - 1];
      const selectedPath: string = routingPaths.find(routingPath => routingPath === lastPart);

      resultParts = splittedUrlBySlash.slice(0, splittedUrlBySlash.length - 1);

      if (lastPart === '' || selectedPath) {
        resultParts.push(lastPart);
      }
    }

    return resultParts.join('/');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private warnAboutDoubleInjection(): void {
    console.warn('Analytics requested to be injected more than once');
  }
}
