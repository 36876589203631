<app-button
  (click)="onSubmit.emit(null)"
  [disabled]="submitDisabled"
  [text]="submitText"
  class="submit-btn"
  classes="btn-wide btn-strong mb-10"></app-button>
<div class="MenuChoice-infoContainer mb-30" *ngIf="infoTextHeaderTop && infoTextTop">
  <span>{{ infoTextHeaderTop }}</span>
  <span><b>{{ infoTextTop }}</b></span>
</div>
<app-button-outline
  (click)="onCancel.emit(null)"
  [disabled]="cancelDisabled"
  [text]="cancelText"
  classes="btn-wide mb-10"></app-button-outline>
<div class="MenuChoice-infoContainer mb-10" *ngIf="infoTextBottom">
  <span>{{ infoTextBottom }}</span>
</div>
