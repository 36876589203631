import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IAlertDialog } from './alert-dialog.interface';
import { LayoutService } from '../../../../../core/providers/layout/layout.service';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const ALERT_DIALOG_CONFIG: MatDialogConfig = {
  maxWidth: '90vw',
  disableClose: false,
};
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit, OnDestroy {
  appColor: string;
  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IAlertDialog,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService
  ) {}

  hideModal() {
    this.mdDialogRef.close(false);
  }

  ngOnInit(): void {
    this.layoutService.activeLayoutColor$.pipe(takeUntil(this.onDestroy$)).subscribe(color => {
      this.appColor = color;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
