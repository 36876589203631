import { CONSTANTS_FILES } from './files.constants';
import { Injectable } from '@angular/core';
import { UtilsService } from '../../core/providers/util/utils.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private readonly baseURL: string = environment.backendURL;

  constructor(private utilsService: UtilsService) {}

  getUrl(id: string, width?: number, height?: number, maxWidth?: number, maxHeight?: number): string {
    const params: any = {
      ...(width && { width }),
      ...(height && { height }),
      ...(maxWidth && { maxWidth }),
      ...(maxHeight && { maxHeight }),
    };

    return this.utilsService.getUrlWithQueryParams(params, `${this.baseURL}/${CONSTANTS_FILES.ENDPOINTS.FETCH_FILES}/${id}`);
  }
}
