import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CONSTANTS } from '../../../../common/constants/constants';
import { CONSTANTS_ROUTING } from '../../../../common/constants/routing/routing.constants';
import { CustomValidators } from '../../../../shared/custom-validators';
import { DialogService } from '../../../../shared/components/dialog/dialog.service';
import { FormControl, Validators } from '@angular/forms';
import { GdprPrivacyService } from '../../../../core/providers/gdpr-privacy/gdpr-privacy.service';
import { LanguageService } from '../../../../core/providers/language/language.service';
import { Languages } from 'src/app/common/enums/languages.enum';
import { LayoutService } from '../../../../core/providers/layout/layout.service';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from '../../wheel-of-fortune-routing.constants';
import { WheelOfFortuneService } from '../../wheel-of-fortune.service';
import { WheelOfFortuneStates } from '../../wheel-of-fortune.states';
import { catchError, takeUntil } from 'rxjs/operators';

const bgAlpha = 0.1;
const borderAlpha = 0.4;

@Component({
  selector: 'app-wheel-of-fortune-winner-prize',
  templateUrl: './wheel-of-fortune-winner-prize.component.html',
  styleUrls: ['./wheel-of-fortune-winner-prize.component.scss'],
})
export class WheelOfFortuneWinnerPrizeComponent implements AfterViewChecked, OnInit, OnDestroy {
  @ViewChild('buttonRef') buttonRef;
  readonly emailFormControl: FormControl = new FormControl('', [Validators.required, CustomValidators.email]);
  readonly styles = {
    'background-color': this.layoutService.useLayoutColor(bgAlpha),
    'border-color': this.layoutService.useLayoutColor(borderAlpha),
  };
  private onDestroy$ = new Subject<void>();
  private dialogMargin: number;

  constructor(
    private layoutService: LayoutService,
    private wheelOfFortuneService: WheelOfFortuneService,
    private router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private gdprPrivacyService: GdprPrivacyService,
    private languageService: LanguageService
  ) {}

  ngAfterViewChecked(): void {
    const differenceInMargins = 220;

    this.dialogMargin = this.buttonRef.nativeElement.offsetTop - differenceInMargins;
  }

  ngOnInit(): void {
    if (!this.wheelOfFortuneService.isWheelEnabled) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
    }
    if (this.wheelOfFortuneService.getWinnerEmail() && this.gdprPrivacyService.getGdprStatus()) {
      this.emailFormControl.setValue(this.wheelOfFortuneService.getWinnerEmail());
      this.emailFormControl.disable();
    }
  }

  getPrizeText(): string {
    return this.wheelOfFortuneService.prizeText;
  }

  getValidDay(): number {
    return this.wheelOfFortuneService.getWheelBody().voucher.validDays;
  }

  submitPrize() {
    if (this.wheelOfFortuneService.getWinnerEmail() && this.gdprPrivacyService.getGdprStatus()) {
      this.getPrize();
    } else {
      this.showEmailVerificationDialog();
    }
  }

  onCancel() {
    this.dialogService
      .confirmDialog({
        headerText: this.translateService.instant('WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.DONT_WANT'),
        confirmBtnText: this.translateService.instant('BUTTON.YES'),
        cancelBtnText: this.translateService.instant('BUTTON.NO_GO_BACK'),
        topPosition: `${this.dialogMargin}px`,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
          this.goToThankYouPage();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private showEmailVerificationDialog() {
    this.dialogService
      .confirmDialog({
        headerText: this.translateService.instant('VALIDATION_MSG.EMAIL_CORRECT'),
        contentText: this.emailFormControl.value,
        confirmBtnText: this.translateService.instant('BUTTON.YES'),
        cancelBtnText: this.translateService.instant('BUTTON.NO_GO_BACK'),
        topPosition: `${this.dialogMargin}px`,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
          this.getPrize();
        }
      });
  }

  private getPrize() {
    this.wheelOfFortuneService.setWinnerEmail(this.emailFormControl.value);
    this.languageService
      .getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: Languages) => this.acceptPrize(value));
  }

  private acceptPrize(activeLang: Languages) {
    this.wheelOfFortuneService
      .submitPrize$(activeLang)
      .pipe(
        catchError(error => {
          if (error.status === CONSTANTS.ERRORS.HTTP_403) {
            this.showEmailBlockerDialog();
          }

          return throwError(error);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.wheelOfFortuneService.setWheelOfFortuneState(WheelOfFortuneStates.summary);
        this.router.navigate([
          // eslint-disable-next-line
          `${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN}/${WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.STATUSES.SUMMARY}`,
        ]);
      });
  }

  private showEmailBlockerDialog() {
    this.dialogService
      .alertDialog({
        headerText: this.translateService.instant('SYSTEM.SORRY'),
        contentText: this.translateService.instant('NOT_ALLOW_PRIZE_MSG.DIALOG_INGO'),
        closeBtnText: this.translateService.instant('BUTTON.CLOSE'),
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.goToThankYouPage());
  }

  private goToThankYouPage() {
    this.wheelOfFortuneService.setWheelOfFortuneState(WheelOfFortuneStates.cancelled);
    this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU]);
  }
}
