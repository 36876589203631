import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss'],
})
export class FormButtonsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() isDisabled: boolean;
  @Input() isCancelBtnDisabled: boolean;
  @Input() submitTranslationKey: string;
  @Input() cancelTranslationKey: string;
  @Input() isColumnOrientation = true;
  @Input() isNavigation = false;
  @Output()
  readonly onSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  readonly onCancel: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('cancelBtnRef')
  cancelBtn: any;
  widthSubmitBtn: number;
  appColor: string;

  private onDestroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private layoutService: LayoutService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.layoutService.activeLayoutColor$.pipe(takeUntil(this.onDestroy$)).subscribe(color => {
      this.appColor = color;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewChecked(): void {
    const increasesWidthValue = 20;

    if (this.cancelBtn) {
      this.widthSubmitBtn = this.cancelBtn.nativeElement.offsetWidth + increasesWidthValue;
      this.cdr.detectChanges();
    }
  }

  submit() {
    this.onSubmit.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}
