<app-basic-page>
  <app-loader *ngIf="isLoading"></app-loader>
  <div
    class="Campaign__welcomeScreen pb-20"
    *ngIf="
      !showCampaign ||
      (campaign.questions[currentIndex$ | async]?.isInWelcomeScreen &&
        (currentIndex$ | async) === 0)
    ">
    <img *ngIf="welcomeImageSrc" [src]="welcomeImageSrc" (load)="onImageLoad()" alt="Welcome Screen Image"/>
    <h3 *ngIf="!isLoading">{{ title }}</h3>
    <p class="Campaign__subtitle" *ngIf="!isLoading">{{ subTitle }}</p>
  </div>
  <div class="Campaign__start mt-40 pb-40" *ngIf="!isLoading && !showCampaign">
    <button class="btn" type="button" (click)="showCampaign = true">{{ "CAMPAIGNS.LETS_START" | translate }}</button>
  </div>
  <form *ngIf="!isLoading && showCampaign" class="mt-20" [formGroup]="campaignForm">
    <ng-container formArrayName="questions">
      <ng-container
        *ngFor="
          let question of campaignForm.get('questions')['controls'];
          trackBy: trackItems;
          let group = index
        ">
        <app-campaign-question
          *ngIf="group === (currentIndex$ | async)"
          [formControlName]="group"
          ngDefaultControl
          [isBackBtnDisabled]="
            !(
              (campaign.foodbackPortalToken && (currentIndex$ | async) === 0) ||
              (currentIndex$ | async) > 0
            )
          "
          (onFinishBtnClicked)="onFinishBtnClicked()"
          (onSaveFormData)="onSaveFormData()">
        </app-campaign-question>
      </ng-container>
    </ng-container>
  </form>
</app-basic-page>
