import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { CONSTANTS_VENUES } from './venues.constants';
import { HttpService } from '../../core/providers/http/http.service';
import { IGeneralVenueInfo } from '../../core/interfaces/general.interface';
import { IMainVenue } from './interfaces/venue.interface';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VenuesStates } from './venues.enum';
import { catchError, filter } from 'rxjs/operators';

@Injectable()
export class VenuesService {
  private venuesState$: BehaviorSubject<VenuesStates> = new BehaviorSubject(VenuesStates.inProgress);

  constructor(private httpService: HttpService, private router: Router) {}

  fetchVenues$(id: string): Observable<IMainVenue> {
    return this.httpService.get$(`${CONSTANTS_VENUES.ENDPOINTS.FETCH_VENUES}/${id}`).pipe(
      catchError((error: any) => {
        if (error.status === CONSTANTS_ERRORS.HTTP_404) {
          this.setVenuesState(VenuesStates.notFound);
          this.router.navigate([CONSTANTS_ROUTING.ROUTES.GHOST]);
        }

        return throwError(error);
      })
    );
  }

  fetchVenueWhenClosed$(id: string): Observable<IGeneralVenueInfo> {
    return this.httpService
      .get$(`${CONSTANTS_VENUES.ENDPOINTS.FETCH_VENUE_INFO_WHEN_CLOSED}/${id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getSurveyState$(): Observable<VenuesStates> {
    return this.venuesState$.pipe(filter((state: VenuesStates) => state !== null));
  }

  setVenuesState(state: VenuesStates): void {
    this.venuesState$.next(state);
    window.scrollTo(0, 0);
  }
}
