import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';
import FP, { Agent, ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  private fingerprint$: BehaviorSubject<ExtendedGetResult> = new BehaviorSubject(null);

  setFingerPrint(): void {
    const requestIdleCallback: any = (window as any).requestIdleCallback;

    // eslint-disable-next-line
    if (requestIdleCallback) {
      requestIdleCallback(() => {
        this.getFingerprint();
      });
    } else {
      this.getFingerprint();
    }
  }

  getFingerprint$(): Observable<ExtendedGetResult> {
    return this.fingerprint$.asObservable().pipe(filter((val: ExtendedGetResult) => val !== null));
  }

  private async getFingerprint(): Promise<any> {
    if (!environment.isLocal) {
      const fp: Agent = await FP.load({
        token: environment.fingerprintToken,
        region: 'eu',
        endpoint: environment.fingerprintEndpoint,
      });

      try {
        const fingerprint: ExtendedGetResult = await fp.get({
          extendedResult: true,
        });

        this.fingerprint$.next(fingerprint);
      } catch (error) {
        if (error.message === FP.ERROR_CLIENT_TIMEOUT) {
          console.error('FP Timeout Error');
        }
      }
    } else {
      this.fingerprint$.next({
        visitorId: `testFingerprint ${new Date().getTime()}`,
      } as ExtendedGetResult);
    }
  }

  // private getRegion(): Region {
  //   return moment.tz.guess().includes('Europe') ? 'eu' : 'us';
  // }
}
