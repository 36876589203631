export const WHEEL_OF_FORTUNE_ROUTING_CONSTANTS = {
  ROUTES: {
    HAPPY_WHEEL: {
      MAIN: 'happy-wheel',
      STATUSES: {
        WINNER: 'winner',
        SUMMARY: 'winner/summary',
        BLOCKED: 'only-one-per-day',
      },
    },
  },
};
