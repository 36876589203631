export const CONSTANTS_PHONE_CODES_BY_LANG = {
  britishEnglish: {
    dialCode: '+44',
    icon: 'gb',
  },
  americanEnglish: {
    dialCode: '+1',
    icon: 'us',
  },
  australianEnglish: {
    dialCode: '+61',
    icon: 'au',
  },
  canadianEnglish: {
    dialCode: '+1',
    icon: 'ca',
  },
  norwegian: {
    dialCode: '+47',
    icon: 'no',
  },
  danish: {
    dialCode: '+45',
    icon: 'dk',
  },
  german: {
    dialCode: '+49',
    icon: 'de',
  },
  spanish: {
    dialCode: '+34',
    icon: 'es',
  },
  finnish: {
    dialCode: '+358',
    icon: 'fi',
  },
  french: {
    dialCode: '+33',
    icon: 'fr',
  },
  frenchQuebec: {
    dialCode: '+1',
    icon: 'ca',
  },
  portuguese: {
    dialCode: '+351',
    icon: 'pt',
  },
  portugueseBrazilian: {
    dialCode: '+55',
    icon: 'br',
  },
  swedish: {
    dialCode: '+46',
    icon: 'se',
  },
  chinese: {
    dialCode: '+86',
    icon: 'cn',
  },
  italian: {
    dialCode: '+39',
    icon: 'it',
  },
  dutch: {
    dialCode: '+31',
    icon: 'nl',
  },
};
