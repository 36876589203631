<app-voucher-template [voucher]="voucher">
  <div class="VoucherMessage__head text-big mb-10">
    <div *ngIf="voucherUsed" [style.background-color]="getLayoutColor()" class="VoucherValid--markedAsUsed mb-10">
      <app-svg-foodback-happy fill="white"></app-svg-foodback-happy>
    </div>

    <i *ngIf="!voucherUsed" class="material-icons">info</i>
    {{ translations.header | translate }}
  </div>

  <div [innerHTML]="translations.message | translate" class="VoucherMessage__message text-medium mt-10"></div>

  <div *ngIf="!voucherUsed" class="mt-20">
    <app-button (click)="useVoucher()" classes="btn-wide btn-strong" text="VOUCHER.VALID.BUTTON"></app-button>
  </div>
</app-voucher-template>
