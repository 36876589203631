import { EmptyPageComponent } from '../../shared/components/pages';
import { QR_LANDING_CONSTANTS } from './qr-landing.constants';
import { QrLandingCheckInComponent } from './qr-landing-check-in/qr-landing-check-in.component';
import { QrLandingComponent } from './_qr-landing/qr-landing.component';
import { QrLandingResolver } from './qr-landing.resolver';
import { Routes } from '@angular/router';

export const QR_LANDING_ROUTES: Routes = [
  {
    path: `${QR_LANDING_CONSTANTS.ROUTES.MAIN}/:id`,
    resolve: {
      qrLanding: QrLandingResolver,
    },
    children: [
      {
        path: '',
        component: QrLandingComponent,
        pathMatch: 'full',
        loadChildren: () => import('./qr-landing.module').then(m => m.QrLandingModule),
      },
      {
        path: QR_LANDING_CONSTANTS.ROUTES.FORMS.CHECK_IN,
        component: QrLandingCheckInComponent,
        pathMatch: 'full',
        loadChildren: () => import('./qr-landing.module').then(m => m.QrLandingModule),
      },
      {
        path: '**',
        component: EmptyPageComponent,
      },
    ],
  },
];
