import * as JsBarcode from 'jsbarcode';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IVoucher } from '../voucher.interface';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Observable, Subject } from 'rxjs';
import { VoucherService } from '../voucher.service';
import { VoucherStates } from '../voucher.states.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vou-voucher-body',
  templateUrl: './voucher-body.component.html',
  styleUrls: ['./voucher-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoucherBodyComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() voucher: IVoucher;
  voucherState: VoucherStates;
  readonly voucherStates = VoucherStates;
  private onDestroy$ = new Subject<void>();

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private layoutService: LayoutService,
    private voucherService: VoucherService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getLayoutColor(): string {
    return this.layoutService.useLayoutColor();
  }

  getDate() {
    const date = new Date(this.voucher.expiresAt);
    const index = 10;

    return date.toISOString().slice(0, index).split('-').reverse().join('-');
  }

  ngOnInit() {
    this.getVoucherState$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        this.voucherState = state;
        this.changeDetectionRef.detectChanges();
      });
    this.getDate();
  }

  ngAfterContentChecked(): void {
    if (this.voucher.barcode) {
      this.generateBarCode(this.voucher.barcode);
    }
  }

  private generateBarCode(value: string) {
    JsBarcode('#barcode', value, {
      format: 'ean13',
      flat: true,
      height: 40,
      fontSize: 14,
    });
  }

  private getVoucherState$(): Observable<VoucherStates> {
    return this.voucherService.getVoucherState$();
  }
}
