<div class="LanguageSelector">
  <div class="LanguageSelectorWrapper">
    <div class="LanguageSelector__dropdownRef" #dropreference></div>
    <img [src]="'assets/img/flags/' + activeLanguageIcon" (click)="showDropdown()" class="LanguageSelector__activeLanguage" />
  </div>
  <app-language-dropdown [reference]="dropreference" #dropdownComp>
    <div class="LanguageSelector__dropdown">
      <div class="LanguageSelector__dropdownContainer" #dropdownList>
        <app-language-single
          *ngFor="let language of getAvailableLanguages$() | async"
          (onClick)="activateLanguage(language)"
          [language]="language"
          [languageIcon]="getLanguageIcon(language)"></app-language-single>
      </div>
    </div>
  </app-language-dropdown>
</div>
