<app-basic-page headerText="WHEEL_OF_FORTUNE.PAGES.SPIN.HEADER">
  <div class="WheelOfFortuneBlocked">
    <span>{{ "WHEEL_OF_FORTUNE.PAGES.SPIN.ONE_SPIN" | translate }}</span>
    <img src="assets/img/Blurred_Happy_Wheel.png" alt="blocked" />
    <app-button
      [text]="'WHEEL_OF_FORTUNE.PAGES.SPIN.BUTTONS.FINISH' | translate"
      (click)="cancelWheelOfFortune()"
      classes="spin-now btn-strong"></app-button>
  </div>
</app-basic-page>
