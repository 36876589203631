import { CONSTANTS_ROUTING } from '../../../common/constants/routing/routing.constants';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WheelOfFortuneService } from '../wheel-of-fortune.service';

@Component({
  selector: 'app-wheel-of-fotune-blocked',
  templateUrl: './wheel-of-fotune-blocked.component.html',
  styleUrls: ['./wheel-of-fotune-blocked.component.scss'],
})
export class WheelOfFotuneBlockedComponent implements OnInit {
  constructor(private wheelOfFortuneService: WheelOfFortuneService, private router: Router) {}

  ngOnInit() {
    if (!this.wheelOfFortuneService.isWheelEnabled) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
    }
  }

  cancelWheelOfFortune() {
    this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU]);
  }
}
