import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CampaignResolver } from './campaign/campaign.resolver';
import { ClosedPageResolver } from '../shared/components/pages/closed-page/closed-page.resolver';
import { CoreModule } from '../core/core.module';
import { FilesModule } from './advertisement/files.module';
import { FlexModule } from '@angular/flex-layout';
import { HttpService } from '../core/providers/http/http.service';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from '../material/material.module';
import { NgModule } from '@angular/core';
import { OneMoreQuestionResolver } from './one-more-question/one-more-question.resolver';
import { QrLandingResolver } from './qr-landing/qr-landing.resolver';
import { SurveyResolver } from './survey/survey.resolver';
import { SurveyService } from './survey/survey.service';
import { VenuesResolver } from './venues/venues.resolver';
import { VenuesService } from './venues/venues.service';
import { VoucherResolver } from './voucher/voucher.resolver';
import { VoucherService } from './voucher/voucher.service';
import { WheelOfFortuneService } from './wheel-of-fortune/wheel-of-fortune.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, CoreModule, AppRoutingModule, LayoutModule, FilesModule, BrowserAnimationsModule, FlexModule, MaterialModule],
  providers: [
    ClosedPageResolver,
    SurveyService,
    SurveyResolver,
    WheelOfFortuneService,
    VenuesResolver,
    VoucherResolver,
    VoucherService,
    VenuesService,
    QrLandingResolver,
    HttpService,
    OneMoreQuestionResolver,
    CampaignResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
