import { CONSTANTS_ROUTING } from '../../../common/constants/routing/routing.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IVoucher } from '../voucher.interface';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { VoucherService } from '../voucher.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vou-voucher-valid',
  templateUrl: './voucher-valid.component.html',
  styleUrls: ['./voucher-valid.component.scss'],
})
export class VoucherValidComponent implements OnInit, OnDestroy {
  voucher: IVoucher;
  voucherUsed: boolean;
  readonly translations = {
    header: 'VOUCHER.VALID.HEADER',
    message: 'VOUCHER.VALID.MESSAGE',
  };
  private onDestroy$ = new Subject<void>();

  constructor(private layoutService: LayoutService, private voucherService: VoucherService, private router: Router) {}

  ngOnInit(): void {
    this.voucher = this.voucherService.getVoucher();

    if (!this.voucher) {
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ERROR]);
    }
  }

  getLayoutColor(): string {
    return this.layoutService.useLayoutColor();
  }

  useVoucher() {
    this.voucherService
      .useVoucher$(this.voucher.token)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.voucherUsed = true;
        this.translations.header = 'VOUCHER.VALID.MARKED_AS_USED.HEADER';
        this.translations.message = 'VOUCHER.VALID.MARKED_AS_USED.MESSAGE';
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
