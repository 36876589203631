<ng-container [ngSwitch]="getVenuesState$() | async">
  <ng-container *ngSwitchCase="venuesStates.inProgress">
    <div class="Venues-container">
      <h2>{{ "VENUES.HEADER" | translate }}</h2>
      <div class="Venues-name" *ngIf="mainVenue?.venues?.length > 20">
        <input [formControl]="venueName" type="text" [placeholder]="'INPUT_PLACEHOLDER.FILTER' | translate" class="inp mt-20" />
      </div>
      <div class="Venues-list">
        <ng-container *ngFor="let venue of filteredVenues; trackBy: trackVenue">
          <a [href]="venue.fixedUrlCode" class="Venues-item Venues-cursorIcon">
            <ng-container [ngTemplateOutlet]="venueContent" [ngTemplateOutletContext]="{ venue: venue }"></ng-container>
          </a>
          <!--          TODO Fix and handle isOpen Flag in next release-->
          <!--          <div *ngIf="!venue.isOpen" class="Venues-item Venues-close">-->
          <!--            <ng-container [ngTemplateOutlet]="venueContent" [ngTemplateOutletContext]="{venue: venue}"></ng-container>-->
          <!--          </div>-->
        </ng-container>
      </div>
    </div>
  </ng-container>
  <app-forbidden-page
    *ngSwitchCase="venuesStates.notFound"
    headerText="PAGES.NOT_FOUND_PAGE.HEADER"
    message="PAGES.NOT_FOUND_PAGE.MESSAGE"
    iconFilePath="/assets/img/ghost.svg"></app-forbidden-page>

  <app-forbidden-page
    *ngSwitchCase="venuesStates.error"
    headerText="PAGES.ERROR_PAGE.HEADER"
    message="PAGES.ERROR_PAGE.MESSAGE"
    iconMaterial="error_outline"></app-forbidden-page>
  <ng-template #venueContent let-venue="venue">
    <div class="Venues-item-container">
      <div class="Venues-item-logo">
        <img [src]="venue.branding.logoUrl$ | async" alt="Logo" />
      </div>
      <div class="Venues-item-name">
        <p>{{ venue.brandName }}</p>
      </div>
    </div>
  </ng-template>
</ng-container>
