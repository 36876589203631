import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '../../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../../common/constants/routing/routing.constants';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { FilesService } from '../../advertisement/files.service';
import { FingerprintService } from '../../../core/providers/fingerprint/fingerprint.service';
import { ILanguage } from '../../../common/interfaces/language.interface';
import { IOneMoreQuestionFromBackend } from '../../one-more-question/interfaces/one-more-question.interface';
import { ISurvey } from '../interfaces/survey.interface';
import { LanguageService } from '../../../core/providers/language/language.service';
import { Languages } from '../../../common/enums/languages.enum';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Observable, Subject, of } from 'rxjs';
import { OneMoreQuestionService } from '../../one-more-question/one-more-question.service';
import { SurveyService } from '../survey.service';
import { SurveyStates } from '../survey-states.enum';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WheelOfFortuneService } from '../../wheel-of-fortune/wheel-of-fortune.service';
import { WheelOfFortuneStates } from '../../wheel-of-fortune/wheel-of-fortune.states';
import { catchError, flatMap, map, mergeMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sur-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, OnDestroy {
  readonly survey: ISurvey = this.activatedRoute.snapshot.data.survey;
  wheelOfFortuneAvailable: boolean;
  readonly surveyStates = SurveyStates;
  private onDestroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private surveyService: SurveyService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private filesService: FilesService,
    private titleService: Title,
    private wheelOfFortuneService: WheelOfFortuneService,
    private router: Router,
    private fingerprintService: FingerprintService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private oneMoreQuestionService: OneMoreQuestionService
  ) {}

  getSurveyState$(): Observable<SurveyStates> {
    return this.surveyService.getSurveyState$();
  }

  ngOnInit() {
    if (this.survey) {
      this.setLayoutCustomSettings();

      if (this.survey.statements) {
        this.setSurveyState();
        this.setSurveyId();
        this.setSurvey();
      }

      this.setDefaultLanguage();
      this.setAvailableLanguages();
      this.surveyService
        .getSurveyState$()
        .pipe(take(1), takeUntil(this.onDestroy$))
        .subscribe(state => {
          if (state === SurveyStates.back) {
            this.setSurveyBackStatus();
          }
        });
    }

    this.fetchOneMoreQuestion$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: IOneMoreQuestionFromBackend) => {
        this.surveyService.setAskOneMoreQuestion(true);
        this.oneMoreQuestionService.setOneMoreQuestionResponse(response);
      });
    this.layoutService.loadedLayoutData$.next(true);
    this.getWheelOfFortuneState$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        this.wheelOfFortuneAvailable = this.wheelOfFortuneService.isWheelEnabled && state !== WheelOfFortuneStates.cancelled;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setDefaultLanguage() {
    const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(this.survey.languages);

    if (defaultLanguage) {
      this.languageService.setAppLanguage(defaultLanguage.code);
    }
    if (defaultLanguage) {
      this.translateService.onLangChange.pipe(take(1)).subscribe(() => {
        this.setLanguage(defaultLanguage.code);
        this.languageService.setAppLanguage(defaultLanguage.code);
      });
    }
  }

  private setAvailableLanguages() {
    this.languageService.setAvailableLanguages(this.survey.languages.map(language => language.code));
  }

  private setLayoutCustomSettings() {
    this.setAppColour();
    this.setVenueLogo();
    this.setTabName();
    this.layoutService.loadedLayoutData$.next(true);
  }

  private setAppColour() {
    // eslint-disable-next-line
    if (this.survey.branding) {
      this.layoutService.setLayoutDefaultColor(this.survey.branding.colorTint);
    }
  }

  private setVenueLogo() {
    this.layoutService.setVenueLogo(this.survey.branding.logoFileUuid);
  }

  private setTabName() {
    const tabTitle = this.survey.venueName ? `${this.survey.venueName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }

  // private setSurveyState() {
  //   this.getSurveyState().pipe(take(1)).subscribe(state => {
  //     if (state === SurveyStates.Back) {
  //       if (this.survey.advertisement.advertisementFileUuid) {
  //         this.getAdvertisement().subscribe(response => {
  //           this.layoutService.setAdvertisementURL(response.url, this.survey.advertisement.url);
  //           this.surveyService.setSurveyState(SurveyStates.Back);
  //           this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.BACK]);
  //         });
  //       } else {
  //         this.surveyService.setSurveyState(SurveyStates.Back);
  //         this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.BACK]);
  //       }
  //     }
  //   });
  // }

  private setSurveyState() {
    const isBlocked = !this.surveyService.checkLocalStorage(this.survey.statementSet.venueUuid);

    localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.HP_BLK, isBlocked ? 'true' : 'false');
    this.getFingerPrint$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(fingerprint => {
        this.surveyService
          .sendFingerprintDataAnalytics(this.activatedRoute.snapshot.params.id, fingerprint, isBlocked)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            if (!isBlocked) {
              this.surveyService.setSurveyState(SurveyStates.inProgress);
            } else {
              this.setSurveyBackStatus();
            }
          });
      });
  }

  private setSurveyBackStatus() {
    if (this.survey.advertisement.advertisementFileUuid) {
      this.layoutService.setAdvertisementURL(this.survey.advertisement.advertisementFileUuid, this.survey.advertisement.url);
      this.surveyService.setSurveyState(SurveyStates.back);
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.BACK]);
    } else {
      this.surveyService.setSurveyState(SurveyStates.back);
      this.router.navigate([CONSTANTS_ROUTING.ROUTES.ADD.BACK]);
    }
  }

  private getFingerPrint$(): Observable<ExtendedGetResult> {
    return this.fingerprintService.getFingerprint$();
  }

  private getWheelOfFortuneState$(): Observable<WheelOfFortuneStates> {
    return this.wheelOfFortuneService.getWheelOfFortuneState$();
  }

  private setSurveyId() {
    this.surveyService.setSurveyId(this.activatedRoute.snapshot.params.id);
  }

  private setSurvey() {
    this.surveyService.setSurvey(this.survey);
  }

  private setLanguage(code: Languages) {
    this.translateService.onLangChange.pipe(take(1)).subscribe(() => this.changeDetectorRef.detectChanges());
    this.languageService.setAppLanguage(code);
  }

  private fetchOneMoreQuestion$(): Observable<IOneMoreQuestionFromBackend> {
    return this.fingerprintService
      .getFingerprint$()
      .pipe(
        mergeMap((fingerprint: any) =>
          this.oneMoreQuestionService.fetchQuestion$(this.survey.statementSet.venueUuid, fingerprint.visitorId)
        )
      );
  }
}
