<app-dialog>
  <app-dialog-content>
    <h4>
      <b>{{ data.headerText }}</b>
    </h4>
    <div class="ConfirmationDialog__contentText">{{ data.contentText }}</div>
  </app-dialog-content>
  <app-dialog-actions class="ConfirmationDialog__content">
    <app-form-buttons
      [submitTranslationKey]="data.confirmBtnText"
      [cancelTranslationKey]="data.cancelBtnText"
      (onSubmit)="exportData()"
      (onCancel)="hideExportModal()"></app-form-buttons>
  </app-dialog-actions>
</app-dialog>
