import { ActivatedRoute } from '@angular/router';
import { CONSTANTS_ERRORS } from '../../../common/constants/errors/errors.constants';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ILanguage } from '../../../common/interfaces/language.interface';
import { IMainVenue, IVenue } from '../interfaces/venue.interface';
import { LanguageService } from '../../../core/providers/language/language.service';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { Observable, Subject, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { VenuesService } from '../venues.service';
import { VenuesStates } from '../venues.enum';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VenuesComponent implements OnInit, OnDestroy {
  readonly mainVenue: IMainVenue = this.activatedRoute.snapshot.data.venue;
  readonly venuesStates = VenuesStates;
  filteredVenues: IVenue[] = [];
  venueName: FormControl = new FormControl('');
  venuesState$: Observable<VenuesStates>;
  private onDestroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private venuesService: VenuesService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    if (this.mainVenue) {
      this.filteredVenues = this.mainVenue.venues;
      this.setLayoutCustomSettings();
      this.setAvailableLanguages();
      this.setDefaultLanguage();
      this.setTabName();
    }

    this.layoutService.loadedLayoutData$.next(true);
    this.venueName.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.filterVenues(value));
    this.venuesState$ = this.getVenuesState$();
  }

  trackVenue(index: number, venue: IVenue) {
    return venue.uuid;
  }

  getVenuesState$(): Observable<VenuesStates> {
    return this.venuesService.getSurveyState$();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setDefaultLanguage() {
    const defaultLanguage: ILanguage = this.languageService.getDefaultLanguage(this.mainVenue.languages);

    if (defaultLanguage) {
      this.languageService.setAppLanguage(defaultLanguage.code);
    }
  }

  private setAvailableLanguages() {
    this.languageService.setAvailableLanguages(this.mainVenue.languages.map(language => language.code));
  }

  private filterVenues(value: any) {
    this.filteredVenues = this.mainVenue.venues.filter(venue => venue.brandName.toLowerCase().includes(value.toLowerCase()));
  }

  private setLayoutCustomSettings() {
    this.setAppColour();
    this.setVenueLogo();
    this.layoutService.loadedLayoutData$.next(true);
  }

  private setAppColour() {
    // eslint-disable-next-line
    if (this.mainVenue.branding) {
      this.layoutService.setLayoutDefaultColor(this.mainVenue.branding.colorTint);
    }
  }

  private setVenueLogo() {
    this.layoutService.setVenueLogo(this.mainVenue.branding.logoFileUuid);
  }

  private setTabName() {
    const brandName: string = this.mainVenue.accountBrandName;
    const tabTitle = brandName ? `${brandName} - Foodback` : 'Foodback';

    this.titleService.setTitle(tabTitle);
  }
}
