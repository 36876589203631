import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { DialogActionsDirective, DialogContentDirective, DialogTitleDirective } from '../../directives';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() disableClose = false;
  @Input() color: 'primary' | 'accent' | 'warn' | null = 'primary';

  @ContentChildren(DialogTitleDirective)
  dialogTitle: QueryList<DialogTitleDirective>;

  @ContentChildren(DialogContentDirective)
  dialogContent: QueryList<DialogContentDirective>;

  @ContentChildren(DialogActionsDirective)
  dialogActions: QueryList<DialogActionsDirective>;
}
