import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-sur-survey-comment',
  templateUrl: './survey-comment.component.html',
  styleUrls: ['./survey-comment.component.scss'],
})
export class SurveyCommentComponent {
  @Input() control: FormControl;
}
