import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { CONSTANTS_ERRORS } from '../../../common/constants/errors/errors.constants';

@Directive({
  selector: '[appAutocomplete]',
})
export class AutocompleteDirective implements AfterContentInit {
  @Input() appAutoFocus: boolean;

  constructor(private el: ElementRef) {}

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, CONSTANTS_ERRORS.HTTP_500);
  }
}
