export const CAMPAIGN: any = {
  ENDPOINTS: {
    DEMOGRAPHICS: 'surveys/takeaway/campaign/demographics',
    FETCH: 'surveys/takeaway/campaign/venues',
    SEND: 'surveys/takeaway/campaign/response',
  },
  ROUTES: {
    MAIN: 'campaign',
    FORM: 'campaign/:venueId',
  },
};
