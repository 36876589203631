import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { CustomValidators } from '../../../shared/custom-validators';
import { IOneMoreQuestionFromBackend } from '../interfaces/one-more-question.interface';
import { IQuestionPayload, IQuestionTranslation, QuestionFormGroup, QuestionType } from '../../../core/interfaces/general.interface';
import { LanguageService } from '../../../core/providers/language/language.service';
import { Languages } from '../../../common/enums/languages.enum';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { OneMoreQuestionService } from '../one-more-question.service';
import { Subject, of } from 'rxjs';
import { SurveyFormService } from '../../../core/providers/survey-form/survey-form.service';
import { SurveyService } from '../../survey/survey.service';
import { mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-one-more-question-form',
  templateUrl: './one-more-question-form.component.html',
  styleUrls: ['./one-more-question-form.component.scss'],
})
export class OneMoreQuestionFormComponent implements OnDestroy {
  @Input() set question(question: IOneMoreQuestionFromBackend) {
    if (question) {
      this.setQuestion(question);
    }
  }

  questionForm: FormGroup;
  generalQuestionText: string;
  generalQuestionOptionsTexts: string[];
  followQuestionText: string;
  followQuestionOptionsTexts: string[];
  questionValue: IOneMoreQuestionFromBackend;
  isFollowQuestionActive = false;
  isSubmitButtonDisabled = false;
  mainQuestionStatementControl: FormControl = new FormControl();
  followQuestionStatementControl: FormControl = new FormControl();
  readonly questionType: any = QuestionType;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private oneMoreQuestionService: OneMoreQuestionService,
    private surveyFormService: SurveyFormService,
    private surveyService: SurveyService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSelectRadioOption(selectedIndex: number, options: FormArray): void {
    options.setValue(
      Array.from(Array(options.controls.length).keys()).map(() => false),
      { emitEvent: false }
    );
    options.controls[selectedIndex].setValue(true);
  }

  onSelectCheckboxOption(selectedIndex: number, options: FormArray): void {
    options.controls[selectedIndex].setValue(!options.controls[selectedIndex].value);
    this.cdf.detectChanges();
  }

  submit(): void {
    this.isSubmitButtonDisabled = true;
    this.oneMoreQuestionService
      .sendQuestion(this.getPayload(this.questionForm.controls.questionFormatForm as QuestionFormGroup))
      .pipe(
        takeUntil(this.onDestroy$),
        mergeMap((res: boolean) =>
          this.questionForm.controls.followQuestionForm
            ? this.oneMoreQuestionService.sendQuestion(this.getPayload(this.questionForm.controls.followQuestionForm as QuestionFormGroup))
            : of(res)
        )
      )
      .subscribe(() => this.surveyService.navigateToPermissionToContact());
  }

  cancel(): void {
    this.surveyService.navigateToPermissionToContact();
  }

  private setQuestion(question: IOneMoreQuestionFromBackend): void {
    this.questionValue = question;
    this.questionForm = this.getForm(question);
    this.handleFormChanges();
    this.languageService
      .getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((language: Languages) => this.setTranslation(language, question));

    if (this.questionValue.type === QuestionType.SCALE) {
      this.setStatementQuestion();
    }
    if (this.questionValue.followUpQuestions.length > 0 && this.questionValue.followUpQuestions[0].type === QuestionType.SCALE) {
      this.setStatementFollowQuestion();
    }
  }

  private setStatementFollowQuestion(): void {
    const followUpQuestion: IOneMoreQuestionFromBackend = this.questionValue.followUpQuestions[0];

    this.setStatement(
      followUpQuestion,
      this.followQuestionStatementControl,
      this.questionForm.controls.followQuestionForm as QuestionFormGroup
    );
  }

  private setStatementQuestion(): void {
    this.setStatement(
      this.questionValue,
      this.mainQuestionStatementControl,
      this.questionForm.controls.questionFormatForm as QuestionFormGroup
    );
  }

  private setStatement(question: IOneMoreQuestionFromBackend, statementControl: FormControl, formGroup: QuestionFormGroup): void {
    question.statement = this.surveyFormService.getStatement(question.uuid, question.translations, 'omq');
    statementControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value: number) => {
      formGroup.setValue({
        options: this.surveyFormService.getScaleAnswer(value - 1),
      });
    });
  }

  private getForm(question: IOneMoreQuestionFromBackend): FormGroup {
    const questionFormatForm: QuestionFormGroup = this.surveyFormService.getQuestionForm(question, true);
    const followQuestionForm: QuestionFormGroup =
      question.followUpQuestions && question.followUpQuestions.length > 0
        ? this.surveyFormService.getQuestionForm(question.followUpQuestions[0], false)
        : null;

    return this.formBuilder.group(
      question.followUpQuestions && question.followUpQuestions.length > 0
        ? {
            questionFormatForm,
            followQuestionForm,
          }
        : { questionFormatForm }
    );
  }

  private setTranslation(language: Languages, question: IOneMoreQuestionFromBackend): void {
    const questionTranslations: IQuestionTranslation = this.surveyFormService.getQuestionTranslation(language, question);

    this.generalQuestionText = questionTranslations.questionText;
    this.generalQuestionOptionsTexts = questionTranslations.optionsTexts;

    if (question.followUpQuestions && question.followUpQuestions.length > 0) {
      const followTranslations: IQuestionTranslation = this.surveyFormService.getQuestionTranslation(
        language,
        question.followUpQuestions[0]
      );

      this.followQuestionText = followTranslations.questionText;
      this.followQuestionOptionsTexts = followTranslations.optionsTexts;
    }
  }

  private handleFormChanges(): void {
    this.questionForm.controls.questionFormatForm.valueChanges.subscribe(() => this.triggerFollowQuestion());
  }

  private triggerFollowQuestion(): void {
    if (this.questionValue.type === QuestionType.FREE_TEXT) {
      return;
    }
    if (this.oneMoreQuestionService.isTriggeredAlways(this.questionValue.options)) {
      this.isFollowQuestionActive = true;
      this.updateFollowQuestionFormValidity();
    } else {
      switch (this.questionValue.type) {
        case QuestionType.RADIO_BUTTON:
        case QuestionType.CHECKBOX:
        case QuestionType.YES_NO:
          this.checkOptionsAndTrigger();
          break;
        case QuestionType.SCALE:
          this.checkOptionsAndStaticTrigger();
          break;
        default:
          break;
      }
    }
  }

  private checkOptionsAndTrigger(): void {
    const optionsForm: boolean[] = (this.questionForm.controls.questionFormatForm as FormGroup).controls.options.value;

    this.isFollowQuestionActive = this.oneMoreQuestionService.isFollowQuestionActive(optionsForm, this.questionValue.options);
    this.updateFollowQuestionFormValidity();
  }

  private checkOptionsAndStaticTrigger(): void {
    const optionsForm: boolean[] = (this.questionForm.controls.questionFormatForm as FormGroup).controls.options.value;
    let selectedIndex = 0;

    for (let i = 0; i < optionsForm.length; i++) {
      if (optionsForm[i]) {
        selectedIndex = i;
        break;
      }
    }

    this.isFollowQuestionActive = selectedIndex === this.oneMoreQuestionService.getTriggerStaticValues(this.questionValue.options);
    this.updateFollowQuestionFormValidity();
  }

  private updateFollowQuestionFormValidity(): void {
    if (!this.questionForm.controls.followQuestionForm) {
      return;
    }
    const optionsForm: AbstractControl = (this.questionForm.controls.followQuestionForm as FormGroup).controls.options;

    if (optionsForm) {
      optionsForm.setValidators(this.isFollowQuestionActive ? CustomValidators.optionsSelected : null);
      optionsForm.updateValueAndValidity();
    } else {
      this.updateFollowQuestionFormAnswerValidity();
    }
  }

  private updateFollowQuestionFormAnswerValidity(): void {
    const answerControl: AbstractControl = (this.questionForm.controls.followQuestionForm as FormGroup).controls.answer;

    if (answerControl) {
      answerControl.setValidators(this.isFollowQuestionActive ? Validators.required : null);
      answerControl.updateValueAndValidity();
    }
  }

  private getPayload(questionForm: QuestionFormGroup): IQuestionPayload {
    return this.surveyFormService.getPayload(questionForm, this.surveyService.getSurveyId());
  }
}
