<app-basic-page headerText="ROOM.CONTACT_GUEST.PAGES.ASK.HEADER" message="ROOM.CONTACT_GUEST.PAGES.ASK.MESSAGE">
  <div class="ContactGuest mt-20">
    <div>
      <div class="ContactGuest-contact-me">
        <mat-checkbox [formControl]="contactMeFormControl" ngDefaultControl
          >{{ "ROOM.CONTACT_GUEST.PAGES.ASK.YES_CONTACT_ME" | translate }}</mat-checkbox
        >
      </div>
    </div>

    <div class="ContactGuest-email">
      <input
        [formControl]="emailFormControl"
        ngDefaultControl
        [placeholder]="
          'WHEEL_OF_FORTUNE.PAGES.WINNER.PAGES.PRIZE.PRIZE.EMAIL_PLACEHOLDER'
            | translate
        "
        #emailInput
        type="email"
        class="inp mt-20" />
    </div>
    <div class="ContactGuest-comment mt-20">
      <textarea
        [formControl]="commentFormControl"
        ngDefaultControl
        [placeholder]="
          'ROOM.CONTACT_GUEST.PAGES.ASK.COMMENT_PLACEHOLDER' | translate
        "></textarea>
    </div>
    <div>
      <p class="gdpr-info" [innerHTML]="'ROOM.CONTACT_GUEST.PAGES.ASK.EMAIL_INFO' | translate"></p>
    </div>
    <app-form-buttons
      class="mt-20"
      submitTranslationKey="BUTTON.SEND"
      cancelTranslationKey="BUTTON.NO_THANKS"
      [isDisabled]="!emailFormControl.valid"
      (onSubmit)="submit()"
      (onCancel)="cancel()"></app-form-buttons>
  </div>
</app-basic-page>
