<app-basic-page headerText="ROOM.PAGES.ASK.HEADER">
  <div>
    <app-button-menu-choice
      (onCancel)="cancel()"
      (onSubmit)="submit()"
      cancelText="BUTTON.NO"
      submitText="BUTTON.YES"
      class="mt-20 mb-20"></app-button-menu-choice>
  </div>
</app-basic-page>
