import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  getNewDateAfterHours(hoursNumber: number): string {
    const expiresDate: Date = new Date();
    const expiresHour: number = expiresDate.getHours() + hoursNumber;

    expiresDate.setHours(expiresHour);

    return expiresDate.toISOString();
  }

  getUrlWithQueryParams(params: any, path: string): string {
    const esc: any = encodeURIComponent;
    const queryParams: string = Object.keys(params)
      .map(k => {
        if (Array.isArray(params[k])) {
          return params[k].map((item: any) => `${esc(k)}=${esc(item)}`).join('&');
        }

        return `${esc(k)}=${esc(params[k])}`;
      })
      .join('&');

    return queryParams ? `${path}?${queryParams}` : path;
  }

  getHashCode(value: string): number {
    if (!value) {
      console.error('Wrong data format!');

      return null;
    }

    return value.split('').reduce((a, b) => {
      // eslint-disable-next-line no-bitwise, no-magic-numbers
      a = (a << 5) - a + b.charCodeAt(0);

      // eslint-disable-next-line no-bitwise
      return a & a;
    }, 0);
  }
}
