import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-menu-choice',
  templateUrl: './button-menu-choice.component.html',
  styleUrls: ['./button-menu-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuChoiceComponent {
  @Input() cancelText: string;
  @Input() submitText: string;
  @Input() cancelDisabled: boolean;
  @Input() submitDisabled: boolean;
  @Input() infoTextHeaderTop: string;
  @Input() infoTextTop: string;
  @Input() infoTextBottom: string;
  @Output()
  readonly onCancel: EventEmitter<null> = new EventEmitter();
  @Output()
  readonly onSubmit: EventEmitter<null> = new EventEmitter();
}
