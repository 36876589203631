<form class="OneMoreQuestionForm" [formGroup]="questionForm" *ngIf="questionValue">
  <div class="OneMoreQuestionForm-formGroup mb-20">
    <ng-container
      *ngTemplateOutlet="
        questionTmpl;
        context: {
          questionText: generalQuestionText,
          formatType: questionValue.type,
          questionFormGroup: questionForm.controls.questionFormatForm,
          isMainQuestion: true,
          statement: questionValue.statement,
          scaleControl: mainQuestionStatementControl
        }
      "></ng-container>
  </div>
  <div
    class="OneMoreQuestionForm-formGroup"
    *ngIf="
      questionValue.followUpQuestions &&
      questionValue.followUpQuestions.length > 0 &&
      isFollowQuestionActive
    ">
    <ng-container
      *ngTemplateOutlet="
        questionTmpl;
        context: {
          questionText: followQuestionText,
          formatType: questionValue.followUpQuestions[0].type,
          questionFormGroup: questionForm.controls.followQuestionForm,
          isMainQuestion: false,
          statement: questionValue.followUpQuestions[0].statement,
          scaleControl: followQuestionStatementControl
        }
      "></ng-container>
  </div>
  <app-form-buttons
    submitTranslationKey="BUTTON.SEND"
    cancelTranslationKey="BUTTON.SKIP"
    [isDisabled]="!questionForm.valid || isSubmitButtonDisabled"
    (onSubmit)="submit()"
    (onCancel)="cancel()"></app-form-buttons>
</form>

<ng-template
  #questionTmpl
  let-questionText="questionText"
  let-formatType="formatType"
  let-questionFormGroup="questionFormGroup"
  let-isMainQuestion="isMainQuestion"
  let-scaleControl="scaleControl"
  let-statement="statement">
  <span class="OneMoreQuestionForm-question" [ngClass]="{ 'mb-10': formatType !== questionType.SCALE }"
    >{{ isMainQuestion ? questionText : followQuestionText }}</span
  >
  <ng-container [ngSwitch]="formatType">
    <ng-container *ngSwitchCase="questionType.YES_NO">
      <ng-template
        *ngTemplateOutlet="
          radioButtonFormat;
          context: {
            questionFormGroup: questionFormGroup,
            isMainQuestion: isMainQuestion
          }
        "></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="questionType.SCALE">
      <app-sur-survey-statement [control]="scaleControl" [statement]="statement" [isSkippingMode]="true"></app-sur-survey-statement>
    </ng-container>
    <ng-container *ngSwitchCase="questionType.RADIO_BUTTON">
      <ng-template
        *ngTemplateOutlet="
          radioButtonFormat;
          context: {
            questionFormGroup: questionFormGroup,
            isMainQuestion: isMainQuestion
          }
        "></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="questionType.CHECKBOX">
      <ng-template
        *ngTemplateOutlet="
          checkboxFormat;
          context: {
            questionFormGroup: questionFormGroup,
            isMainQuestion: isMainQuestion
          }
        "></ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="OneMoreQuestionForm-answer" [formGroup]="questionFormGroup">
        <textarea formControlName="answer" ngDefaultControl [placeholder]="'MORE_QUESTION.PLEASE_STATE_ANSWER' | translate"></textarea>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #radioButtonFormat let-questionFormGroup="questionFormGroup" let-isMainQuestion="isMainQuestion" let-isNumeric="isNumeric">
  <div [formGroup]="questionFormGroup">
    <mat-radio-group formArrayName="options">
      <mat-chip-list aria-label="age" selectable="true">
        <mat-chip
          *ngFor="
            let option of questionFormGroup.controls.options.controls;
            let i = index
          "
          selectable="true"
          [selected]="option.value"
          (click)="onSelectRadioOption(i, questionFormGroup.controls.options)">
          <mat-radio-button [checked]="option.value" [value]="i">
            <span>{{ isNumeric ? i + 1 : isMainQuestion ? generalQuestionOptionsTexts[i] : followQuestionOptionsTexts[i] }}</span>
          </mat-radio-button>
        </mat-chip>
      </mat-chip-list>
    </mat-radio-group>
  </div>
</ng-template>
<ng-template #checkboxFormat let-questionFormGroup="questionFormGroup" let-isMainQuestion="isMainQuestion">
  <div [formGroup]="questionFormGroup">
    <mat-chip-list aria-label="age" selectable="true" multiple formArrayName="options">
      <mat-chip
        *ngFor="
          let option of questionFormGroup.controls.options.controls;
          let i = index
        "
        selectable="true"
        [selected]="option.value"
        (click)="onSelectCheckboxOption(i, questionFormGroup.controls.options)">
        <div>
          <mat-icon>close</mat-icon>
        </div>
        <span>{{ isMainQuestion ? generalQuestionOptionsTexts[i] : followQuestionOptionsTexts[i] }}</span>
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-template>
