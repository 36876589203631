<div
  [ngClass]="{ FormButtons__navButtons: isNavigation }"
  [fxLayout]="isColumnOrientation ? 'column' : 'row'"
  fxLayoutGap="10px"
  fxLayoutAlign="center center">
  <button
    fxLayout="row"
    [fxLayoutAlign]="isNavigation ? 'space-between center' : 'center'"
    [fxFlexOrder]="isColumnOrientation ? 1 : 2"
    type="button"
    class="btn"
    [ngClass]="{ 'mb-10': isColumnOrientation }"
    [attr.aria-label]="submitTranslationKey | translate"
    [disabled]="isDisabled"
    (click)="submit()"
    [style.background-color]="appColor"
    [style.width.px]="isColumnOrientation ? widthSubmitBtn : ''">
    <span *ngIf="isNavigation" class="FormButtons__emptySpan"></span>
    <span>{{ submitTranslationKey | translate }}</span>
    <mat-icon *ngIf="isNavigation">navigate_next</mat-icon>
  </button>
  <button
    fxLayout="row"
    [fxLayoutAlign]="isNavigation ? 'space-between center' : 'center'"
    type="button"
    class="btn btn-outline pull-right text-uppercase FromButton__cancelBtn"
    [attr.aria-label]="cancelTranslationKey | translate"
    [style.border-color]="appColor"
    [style.color]="appColor"
    [disabled]="isCancelBtnDisabled"
    (click)="cancel()"
    #cancelBtnRef>
    <mat-icon *ngIf="isNavigation">chevron_left</mat-icon>
    <span>{{ cancelTranslationKey | translate }}</span>
    <span *ngIf="isNavigation" class="FormButtons__emptySpan"></span>
  </button>
</div>
