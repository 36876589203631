import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sur-survey-header',
  templateUrl: './survey-header.component.html',
  styleUrls: ['./survey-header.component.scss'],
})
export class SurveyHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
